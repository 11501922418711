<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr42_hero.png" alt="ADDITION OF MALIN ERIKSSON TO WEBBANK BOARD OF DIRECTORS">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr42_small.png" alt="ADDITION OF MALIN ERIKSSON TO WEBBANK BOARD OF DIRECTORS">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">WEBBANK ANNOUNCES THE ADDITION OF MALIN ERIKSSON TO ITS BOARD OF DIRECTORS</h2>
                        <br>
                        <p><em><small>June 4, 2024</small></em></p>
                        <br>
                        <p>SALT LAKE CITY - WebBank is pleased to announce the appointment of Malin Eriksson to its Board of Directors. With an extensive background in leading high-growth financial companies, Ms. Eriksson brings a wealth of experience and a fresh perspective to her role as a board member.</p>
                        <br>
                        <p>“We are thrilled to welcome Ms. Eriksson to our Board of Directors,” said Jason Lloyd, President & CEO of WebBank. “With a distinguished background in financial services and a proven track record of leadership, Ms. Eriksson’s insights and experience will be extremely valuable as we continue to grow and innovate.”</p>
                        <br>
                        <p>Ms. Eriksson is a seasoned financial services executive. She is the Chief Commercial Officer of the Swedish start-up Gilion (formerly Ark Kapital), a company focused on AI driven precision finance to fast growing, early-stage companies. Prior to that she was Chief Operating Officer at Earnest Partners, an Atlanta-based asset manager with over $25 Billion in AUM. Before joining Earnest Partners, Ms. Eriksson served as the interim U.S. CEO of Klarna, a fintech company that provides online payment solutions and is one of WebBank’s partners. Previous to that, she co-founded and served as the Chief Investment Officer of Credigy, an Atlanta based specialty finance company dedicated to investing in and financing credit-related assets.</p>
                        <br>
                        <p>Ms. Eriksson received a Bachelor of Science in Business with a minor in Economics from Ithaca College and she completed the Advanced Management Program at Harvard Business School.</p>
                        <br>
                        <p>“I am honored and delighted to have the opportunity to serve WebBank,” said Malin Eriksson. “The firm has continued to transform itself through an evolving complex financial landscape with its industry-leading team while growing responsibly and collaborating closely with partners and regulators. I am looking forward to joining my fellow board members in supporting the WebBank team!”</p>
                        <br>
                        <p><strong>About WebBank</strong></p>
                        <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its inception in 1997, WebBank has originated and funded over $190 billion in consumer and commercial credit products.  As “The Bank Behind the Brand®”, WebBank is a national issuer of consumer and small business credit products through Strategic Partner (Brand) platforms, which include retailers, manufacturers, finance companies, software as a service (SaaS) and financial technology (FinTech) companies.  The Bank is a leading player in the digital lending space, driving innovation in financial products through embedded finance with Strategic Partner platforms.  WebBank engages in a full range of banking activities including consumer and commercial loan products, revolving lines of credit, credit cards, private-label card issuance, auto-refinancing and more. The Bank provides capital in the form of asset-based lending and other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a targeted focus on specialty finance assets. The Bank is also a leading provider of commercial insurance premium finance products through its wholly owned subsidiary National Partners. For more information, please visit www.webbank.com.</p>
                        <br>
                        <p><strong>Contact:</strong></p>
                        <p>Shannon Hutchings<br>
                            <a href="mailto:shannon.hutchings@webbank.com">shannon.hutchings@webbank.com</a><br>
                        </p>
                    </div>

                    <div class=" modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website
                                    and does not represent either the linked website or you, should you enter into a
                                    transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked
                                    site. We encourage you to review their privacy and security policies which may differ
                                    from WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "ADDITION OF MALIN ERIKSSON TOS WEBBANK BOARD OF DIRECTORS";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
