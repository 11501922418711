<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/yamaha.png" alt="Yamaha Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/yamaha.png" alt="Yamaha Logo">
          </div>
            <br>
            <p>In partnership with Yamaha Motor Finance Corporation, U.S.A. (“YMFUS”), WebBank is the issuer and
                lender of open-end revolving credit card accounts for consumers for the purchase of Powersports
                products nationwide. If you have any questions about your account, please call 877-327-7310. Or visit
                <a href="#" title="Your account at Yamaha" @click="$emit('link-click', 'https://www.yamaha-motor-finance.com/about-us/#ContactUs')">here</a>.</p>
            <br>
            <p><a href="#" title="Yamaha Cardholder Agreement" @click="$emit('link-click', 'https://yamahamotorfinanceusa.com/docs/CardHolderAgreement.pdf')">Yamaha Cardholder Agreement</a></p>
            <br>
            <p><a href="#" title="Yamaha Privacy Policy" @click="$emit('link-click', 'https://yamaha-motor.com/privacy-policy')">Yamaha Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Yamaha Privacy Notice and WebBank Privacy Notice" @click="$emit('link-click', 'https://www.yamaha-motor-finance.com/globalassets/finance/privacynotices.pdf')">Yamaha Privacy Notice and WebBank Privacy Notice</a></p>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
