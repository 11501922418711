<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/onemain.png" alt="OneMain Financial Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/onemain.png" alt="OneMain Financial Logo">
          </div>
            <br>
            <p>In partnership with OneMain Financial, WebBank is the issuer and lender of the OneMain Financial
                Brightway credit card. For questions about your OneMain Financial Brightway credit card please
                call 1-866-207-9130 between the hours of 8 am and 11 pm ET any day of the week or send an email
                to <a href="mailto:customerservice@omf.com">customerservice@omf.com</a>. More information about the card can be found
                <a href="#" title="More information at OneMain Financial" @click="$emit('link-click', 'https://www.onemainfinancial.com/credit-cards')">here</a>.</p>
            <br>
            <p><a href="#" title="One Main Cardholder Agreement" @click="$emit('link-click', 'https://www.onemainfinancial.com/pdf/BrightWay_Cardholder_Agreement.pdf')">One Main Cardholder Agreement</a></p>
            <br>
            <p><a href="#" title="One Main Privacy Policy" @click="$emit('link-click', 'https://www.onemainfinancial.com/legal/privacy-policy')">One Main Privacy Policy</a></p>
            <br>
            <p><a href="#" title="One Main Privacy Notice" @click="$emit('link-click', 'https://www.onemainfinancial.com/pdf/2021_Online_Privacy_Notice.pdf')">One Main Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://www.onemainfinancial.com/pdf/2020_Online_Privacy_Notice_WebBank.pdf')">WebBank Privacy Notice</a></p>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
