<template>
    <section class="section">
        <div class="container">
            <br>
            <h3 class="is-size-1 has-text-primary section-title">The WebBank Difference</h3>
            <br>
            <div class="columns">
                <div class="column is-half">
                    <br>
                    <br>
                    <div class="content">
                        <p class="">In WebBank's decades long role as "The Bank Behind the Brand<sup>&reg;</sup>, we
                            have worked with premier non-bank
                            financial technology companies, many of which are now household names, to provide unique
                            specialty finance business models
                            and solutions. Our decades of experience and unparalleled access to leading fintech
                            companies enables us to provide creative
                            solutions to bespoke platforms ranging from VC-backed startups to Fortune 50 companies.
                            Working with WebBank, fintech and
                            specialty finance companies can attain bank-level pricing for structured credit facilities
                            that traditionally have only been
                            provided by much higher cost credit funds and non-bank direct lenders.</p>
                        <br>
                        <p>Given our work with diverse specialty finance platforms, we are experts in a wide variety of
                            consumer and SMB asset classes,
                            including equipment-backed finance, unsecured consumer loans (including Buy Now Pay Later),
                            MCA, credit card receivables,
                            supply chain finance, installment loans, and dealer floorplan lines, just to name a few.
                            More importantly, our approach and
                            mindset allow us to tailor customized, asset-based solutions for newer platforms with
                            specialty assets seeking lower cost options
                            as portfolios season.</p>
                        <br>
                        <p>Whether you're an entrepreneur with a specialty finance business seeking growth capital
                            greater than $10 million, a broker-dealer
                            advising specialty finance companies on funding options for their receivables, or a credit
                            fund portfolio manager seeking leverage
                            for your existing, asset-based investments, contact us and let's discuss how we can work
                            together.</p>
                    </div>
                    <br>
                </div>
                <div class="column">
                    <br>
                    <br>
                    <img src="https://assets.webbank.com/R5_C1616.png" class="body-image"
                        alt="Asset Finance at WebBank" />
                </div>
            </div>

            <hr>
            <br>
            <br>

            <section class="section" v-if="success">
                <div class="container">
                    <div class="columns">
                        <div class="column is-8 is-offset-2 has-text-centered">
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                            <span class="icon has-text-success"><i class="fa-solid fa-badge-check fa-10x"></i></span>
                            <br>
                            <br>
                            <br>
                            <br>
                            <p class="has-text-success is-size-3">Thank you! Your message has been sent!</p>
                            <br>
                            <br>
                            <br>
                            <br>
                        </div>
                    </div>
                </div>
            </section>

            <div class="columns" v-else>

                <div class="column is-8 is-offset-2">
                    <div class="has-text-centered">
                        <h3 class="is-size-1 has-text-primary section-title">Asset Finance</h3>
                        <br>
                        <p>Asset Finance from WebBank helps customers get the capital they need to finance business
                            growth.</p>
                    </div>
                    <br>
                    <br>

                    <div class="notification is-danger" v-if="captcha_error">
                        Captcha verification failed. Please try again.
                    </div>

                    <div class="notification is-danger" v-if="errors && errors.fields">
                        Please check your input and try again.
                    </div>

                    <Form @submit="submitForm" :validation-schema="yupSchema">
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">First Name</label>
                                    <div class="control">
                                        <Field class="input" type="text" placeholder="John" name="first_name" />
                                    </div>
                                    <ErrorMessage name="first_name" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('first_name')"
                                        class="help has-text-danger">
                                        {{ errors.messages.first_name.join(', ') }}
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Last Name</label>
                                    <div class="control">
                                        <Field class="input" type="text" placeholder="Doe" name="last_name" />
                                    </div>
                                    <ErrorMessage name="last_name" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('last_name')"
                                        class="help has-text-danger">
                                        {{ errors.messages.last_name.join(', ') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Email</label>
                                    <div class="control has-icons-left has-icons-right">
                                        <Field class="input" type="email" name="email" placeholder="me@mycompany.com" />
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <ErrorMessage name="email" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('email')"
                                        class="help has-text-danger">
                                        {{ errors.messages.email.join(', ') }}
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Phone</label>
                                    <div class="control">
                                        <Field class="input" v-maska:[options] type="tel" name="phone"
                                            placeholder="xxx-xxx-xxxx" />
                                    </div>
                                    <ErrorMessage name="phone" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('phone')"
                                        class="help has-text-danger">
                                        {{ errors.messages.phone.join(', ') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Company</label>
                                    <div class="control">
                                        <Field class="input" type="text" name="brand_partner"
                                            placeholder="My Company" />
                                    </div>
                                    <ErrorMessage name="brand_partner" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('brand_partner')"
                                        class="help has-text-danger">
                                        {{ errors.messages.brand_partner.join(', ') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Description of Opportunity</label>
                            <div class="control">
                                <Field class="textarea" as="textarea" name="description"
                                    placeholder="Additional Details">
                                </Field>
                            </div>
                            <ErrorMessage name="description" class="help has-text-danger" />
                            <p v-if="errors && errors.fields && errors.fields.includes('description')"
                                class="help has-text-danger">
                                {{ errors.messages.description.join(', ') }}
                            </p>
                        </div>

                        <div class="field is-grouped">
                            <div class="control">
                                <button class="button is-primary" type="submit"
                                    :class="{ 'is-loading': sending }">Submit</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

        </div>
    </section>

</template>

<script>
import axios from 'axios';
import { vMaska } from "maska"
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
    directives: { maska: vMaska },
    components: {
        Form,
        Field,
        ErrorMessage,
    },

    setup() {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const yupSchema = yup.object().shape({
            first_name: yup.string().required().min(3).max(100).label('First Name'),
            last_name: yup.string().required().min(3).max(100).label('Last Name'),
            email: yup.string().required().matches(emailRegex, 'Invalid email format').label('Email'),
            phone: yup.string().required().min(12).max(12).label('Phone'),
            brand_partner: yup.string().required().min(3).max(100).label('Company'),
            description: yup.string().required().min(3).max(1000).label('Message')
        });
        return { yupSchema };
    },

    created() {
        document.title = "Asset Finance - WebBank";
    },

    data: function () {
        return {
            sending: false,
            success: false,
            errors: [],
            captcha_error: null,
            options: {
                mask: "###-###-####",
                eager: false,
            },
        }
    },

    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    },

    methods: {
        submitForm: function (values) {
            this.errors = [];
            this.sending = true;

            this.$recaptcha('specialty_finance_form')
                .then(token => {
                    // You can now use this token to verify the captcha response on your server
                    // console.log(token);
                    values['form_name'] = 'specialty_finance';
                    values['g-recaptcha-response'] = token;
                    console.log('posting values: ' + JSON.stringify(values));
                    axios.post(process.env.VUE_APP_MAIL_ENDPOINT, values)
                        .then(result => {
                            console.log('result: ' + JSON.stringify(result));
                            this.success = true;
                        }).catch(error => {
                            console.log('error: ' + JSON.stringify(error));
                            if (error.response.status === 403) {
                                this.captcha_error = error.message;
                            } else {
                                // can I still reach the "body" of the response?
                                console.log('error.body: ' + JSON.stringify(error.response.data));
                                this.errors = this.processErrors(error.response.data);
                                // console.log('ERRORS: ' + JSON.stringify(this.errors));
                            }
                        }).finally(() => this.sending = false);
                });
        },
        processErrors: function (apiResponse) {
            const errors = {
                fields: Object.keys(apiResponse), // Array of field names with errors
                messages: apiResponse // Keep the original structure for message access
            };
            return errors;
        }

    }
}
</script>
