<template>
    <section class="hero privacy-hero is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                </div>
            </section>
        </div>
    </section>

    <section class="is-hidden-widescreen">
        <img src="https://assets.webbank.com/718.png">
    </section>

    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">
                    <div class="content">
                        <span id="start">&nbsp;</span>

                        <a name="top" id="top">&nbsp;</a>
                        <br>
                        <h2 class="is-size-1 hero-title has-text-primary">Privacy</h2>
                        <br>
                        <p>The types of personal information collected about you, why such information was collected,
                            any
                            third parties to whom such information was disclosed, shared, or sold and any privacy rights
                            you
                            may have depends on your particular relationship with WebBank and, if applicable, our Brand
                            Partner programs. Such privacy practices, and any privacy rights, are described in the
                            privacy
                            notices and/or policies applicable to your circumstances.</p>
                        <br>
                        <p>To learn more, click on the link below that best relates to you.</p>
                        <ul>
                            <li><router-link :to="{ name: 'PrivacyPage', hash: '#brand-partners' }">Brand Partner
                                    Programs Privacy Policies</router-link></li>
                            <li><router-link :to="{ name: 'PrivacyPage', hash: '#online-deposit-policy' }">WebBank
                                    Online Deposit Privacy Policy</router-link></li>
                            <li><router-link :to="{ name: 'PrivacyPage', hash: '#visitors-policy' }">WebBank Website
                                    Visitors Privacy Policy</router-link></li>
                            <li><router-link :to="{ name: 'PrivacyPage', hash: '#other-business-policy' }">WebBank
                                    Individuals at Other Businesses Privacy Policy</router-link></li>
                            <li><router-link
                                    :to="{ name: 'PrivacyPage', hash: '#california-individuals-policy' }">WebBank
                                    California Individuals at Other Businesses Privacy Policy</router-link></li>
                            <li><router-link :to="{ name: 'PrivacyPage', hash: '#california-employee-policy' }">WebBank
                                    California Employee Privacy Policy</router-link></li>
                        </ul>
                        <br>
                        <PrivacyBrandPartners />

                        <PrivacyOnlineDeposits />

                        <PrivacyVisitors />

                        <PrivacyOtherBusiness />

                        <PrivacyCaliforniaIndividuals />

                        <PrivacyCaliforniaEmployees />

                        <p><em>Last Updated: April 2024</em></p>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
import PrivacyOnlineDeposits from '@/components/PrivacyOnlineDeposits.vue';
import PrivacyVisitors from '@/components/PrivacyVisitors.vue';
import PrivacyOtherBusiness from '@/components/PrivacyOtherBusiness.vue';
import PrivacyBrandPartners from '@/components/PrivacyBrandPartners.vue';
import PrivacyCaliforniaIndividuals from '@/components/PrivacyCaliforniaIndividuals.vue';
import PrivacyCaliforniaEmployees from '@/components/PrivacyCaliforniaEmployees.vue';
export default {
    components: {
        PrivacyOtherBusiness,
        PrivacyOnlineDeposits,
        PrivacyVisitors,
        PrivacyBrandPartners,
        PrivacyCaliforniaIndividuals,
        PrivacyCaliforniaEmployees
    },
    created() {
        document.title = "Privacy - WebBank";
    },

    methods: {
        scrollToSection: function (section) {
            let e = document.getElementById(section);
            e.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
                inline: 'start'
            });
        },
    }

}
</script>

<style scoped>
.pp-title {
    font-size: 1.5rem;
    border-bottom: 1px solid black;
    /* margin-bottom: 25px;
    padding-bottom: 10px; */
    font-weight: bold;
}
.pp-section-title {
    font-size: 1.25rem;
}
</style>
