<template>
<div>

    <div class="box partner-box" style="display: grid; place-items: center;" @click="partner_modal = true">
        <img src="https://assets.webbank.com/BILL-logo.png" style="max-width: 75%;" alt="BILL Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div style="display: grid; place-items: center;">
            <img src="https://assets.webbank.com/BILL-logo.png" style="max-width: 200px;" alt="BILL Logo">
          </div>
            <br>
            <p>In partnership with BILL, WebBank is the issuer and lender of the BILL Invoice Financing Loan.
                    If you have questions about your account, please visit the <a href="#" title="BILL Help" @click="$emit('link-click', 'https://help.bill.com/hc/en-us?s=1')">Help Center</a>.</p>
            <br>
            <br>
            <p><a href="#" title="BILL Privacy Policy" @click="$emit('link-click', 'https://www.bill.com/privacy')">BILL Privacy Policy</a></p>
            <br>
            <!-- <p><a href="#" title="BILL Privacy Notice" @click="$emit('link-click', 'https://www.bill.com/privacy/consumer')">BILL Privacy Notice</a></p>
            <br> -->

            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
