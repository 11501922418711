<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr32-hero.png" alt="Utah Bankers Association">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr32-small.png" alt="Utah Bankers Association">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">WebBank Announces Jason Lloyd's Appointment to the Utah
                                    Bankers Association Board of Directors</h2>
                        <br>
                        <p>SALT LAKE CITY — WebBank announced today that its President &amp; CEO, Jason Lloyd, has
                        joined the Utah Bankers Association (“UBA”) Board of Directors. WebBank is a national lender
                        in providing consumer and small business credit products through Strategic Partner platforms.
                        WebBank is also actively involved in educating legislators and regulators about issues and
                        opportunities related to providing credit products through financial technology companies.</p>
                        <br>
                        <p>“I appreciate being selected to join the UBA Board of Directors,” said Jason Lloyd, President &amp;
                        CEO. “It is a tremendous honor to work with the UBA and other board members to address the
                        challenging issues banks face today and I look forward to contributing to the important work of
                        the Association.”</p>
                        <br>
                        <p>“We are excited to have Jason join the UBA Board of Directors,” said Howard Headlee,
                        President &amp; CEO of the Utah Bankers Association. “His years of experience and strategic vision
                        will be invaluable to the Association and the banking industry as we navigate upcoming
                        opportunities and challenges.”</p>
                        <br>
                        <p><strong>About WebBank</strong></p>
                        <p><a href="https://webbank.com">WebBank</a> is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its
                            inception in 1997, WebBank has originated and funded over $175 billion in consumer and
                            commercial credit products. As “The Bank Behind the Brand<sup>&reg;</sup>”, WebBank is a national issuer of
                            consumer and small business credit products through Strategic Partner (Brand) platforms,
                            which include retailers, manufacturers, finance companies, software as a service (SaaS) and
                            financial technology (FinTech) companies. The Bank is a leading player in the digital lending
                            space, driving innovation in financial products through embedded finance with Strategic Partner
                            platforms. WebBank engages in a full range of banking activities including consumer and
                            commercial loan products, revolving lines of credit, credit cards, private-label card issuance,
                            auto-refinancing and more. The Bank provides capital in the form of asset-based lending and
                            other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a
                            targeted focus on specialty finance assets. The Bank is also a leading provider of commercial
                            insurance premium finance products through its wholly owned subsidiary National Partners. For
                            more information, please visit <a href="https://webbank.com">www.webbank.com</a>.</p>
                        <br>
                    <p><strong>Media Contacts:</strong><br>
                    WebBank<br>
                    Shannon Hutchings<br>
                    <a href="mailto:shannon.hutchings@webbank.com">Shannon.hutchings@webbank.com</a></p>
                    </div>

                    <div class="modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website and does not
                                    represent either the linked website or you, should you enter into a transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked site. We
                                    encourage you to review their privacy and security policies which may differ from
                                    WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "Utah Banker Association - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
