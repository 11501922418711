import "./sass/style.scss";

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'

import moment from 'moment';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
    apiKey: '240ffc71edf5269acccea01e93210c3e',
    plugins: [new BugsnagPluginVue()]
});
const bugsnagVue = Bugsnag.getPlugin('vue')

import router from './router'

import { VueReCaptcha } from 'vue-recaptcha-v3';

const pinia = createPinia()
createApp(App)
    .use(router)
    .use(moment)
    .use(pinia)
    .use(bugsnagVue)
    .use(VueReCaptcha, { siteKey: '6Ld1lGwpAAAAAH-JzzkAXSlNv1VCVV6ZmWTbN0HC' })
    .mount('#app')
