import { defineStore } from 'pinia'

export const useNavbarStore = defineStore('navbar', {
    state: () => ({
        personalMenu: false,
        businessMenu: false,
        aboutMenu: false,
        mobileMenu: false,
    }),
    actions: {
        hideMobileMenu () {
            this.mobileMenu = false;
        },
        hidePersonalMenu () {
            this.personalMenu = false;
        },
        hideBusinessMenu() {
            this.businessMenu = false;
        },
        hideAboutMenu() {
            this.aboutMenu = false;
        },
        togglePersonalMenu () {
            this.personalMenu = !this.personalMenu;
        },
        toggleBusinessMenu() {
            this.businessMenu = !this.businessMenu;
        },
        toggleAboutMenu() {
            this.aboutMenu = !this.aboutMenu;
        },
    }
})
