<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/Cleo.png" alt="Cleo Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/Cleo.png" alt="Cleo Logo">
          </div>
            <br>
            <p>In partnership with Cleo, WebBank is the issuer and lender of the fully secured Cleo Credit Builder Card.
                If you have questions about your account, please contact Cleo at <a href="mailto:cleobuilder@meetcleo.com">cleobuilder@meetcleo.com</a>.
                If you are interested in applying for the Cleo Credit Builder Card, click
                <a href="#" title="Visit Cleo" @click="$emit('link-click', 'https://web.meetcleo.com/build-credit')">here</a>.</p>
            <br>
            <p><a href="#" title="Cleo Cardholder Agreement" @click="$emit('link-click', 'https://www.meetcleo.com/cardholder-agreement')">Cleo Credit Builder Cardholder Agreement</a></p>
            <br>
            <p><a href="#" title="Cleo Privacy Policy" @click="$emit('link-click', 'https://web.meetcleo.com/page/privacy-policy')">Cleo Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Cleo Privacy Notice" @click="$emit('link-click', 'https://web.meetcleo.com/page/privacy-notice')">Cleo Privacy Notice</a></p>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
