<template>
    <footer class="blue-bg">
        <div class="container footer">
            <div class="columns">
                <div class="column is-4">
                    <p class="is-size-1 section-title has-text-white">WebBank</p>
                    <br>
                    <p><a href="#" @click="clickOutWarning('https://fdic.gov')">Member FDIC</a> |
                        <a href="#" @click="clickOutWarning('https://hud.gov')">
                            <span>Equal Housing Opportunity</span>&nbsp;&nbsp;
                            <span><img src="https://assets.webbank.com/equal_housing.png" class="equal-housing-icon"></span>
                        </a>
                    </p>
                    <br>
                    <p><a href="tel:18449942265" class="has-text-white">1-844-994-BANK (2265)</a></p>
                    <br>
                    <p><a href="#" title="WebBank on LinkedIn" @click="clickOutWarning('https://www.linkedin.com/company/webbank')">
                            <span class="icon"><i alt="LinkedIn Logo" class="fa-brands fa-linkedin-in fa-xl"></i></span>
                        </a></p>
                </div>
                <div class="column">
                    <div class="columns">
                        <div class="column">
                            <p class="is-size-4">Personal</p>
                            <p class="footer-link">
                                <router-link to="/brand-partners">Brand Partners</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/deposit-products">Deposit Products</router-link>
                            </p>
                        </div>
                        <div class="column">
                            <p class="is-size-4">Business</p>
                            <p class="footer-link">
                                <router-link to="/business-brand-partners">Brand Partners</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/asset-finance">Asset Finance</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/commercial-insurance">Commercial Insurance Premium Finance
                                </router-link>
                            </p>
                        </div>
                        <div class="column">
                            <p class="is-size-4">WebBank</p>
                            <p class="footer-link">
                                <router-link to="/about" title="About Us">About Us</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/our-people" title="Our People">Leadership Team</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/working-at-webbank" title="Work With Us!">Working at WebBank</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/business-associations" title="Business Associations">Business Associations and Memberships</router-link>
                            </p>
                            <p class="footer-link">
                                <a href="#" title="Financial Statement" @click="clickOutWarning('https://banks.data.fdic.gov/bankfind-suite/FinancialReporting/details/34404?establishedEndRange=4%2F3%2F2023&establishedStartRange=01%2F01%2F1792&inactiveEndRange=4%2F3%2F2023&inactiveStartRange=01%2F01%2F1970&incomeBasis=YTD&institutionType=banks&limitEstablishedDate=false&limitInactiveDate=false&reportPeriod=20221231&unitType=%24')">
                                    Financial Statement Link
                                </a>
                            </p>
                        </div>
                        <div class="column">
                            <p class="is-size-4">Policies</p>
                            <p class="footer-link">
                                <router-link to="/uigea" title="UIGEA">UIGEA</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/us-patriot-act" title="Patriot Act">USA PATRIOT Act</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/privacy" title="Privacy">Privacy</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/terms" title="Terms of Use">Terms of Use</router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br>
            <div class="field is-grouped">
                <div class="control">
                    <button class="button is-link" @click="doClickout">Proceed</button>
                </div>
                <div class="control">
                    <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
                </div>
            </div>
      </div>
  </div>
</div>

    </footer>
</template>

<script>
export default {

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },

        closeAllPartnerModals: function () {
            this.partner_modal = false;
        }
    }
}
</script>
