<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/oportun_logo2.png" alt="Oportun Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/oportun_logo2.png" alt="Oportun Logo">
          </div>
            <br>
            <p>In partnership with Oportun, Inc., WebBank is the issuer and lender of the Oportun<sup>&reg;</sup> Visa<sup>&reg;</sup> Credit Card.
                For questions about your Oportun<sup>&reg;</sup> Visa<sup>&reg;</sup> Credit Card account, please call 833-676-7886. If you are interested
                in learning more about applying for credit, click
                    <a href="#" title="Apply at Oportun" @click="$emit('link-click', 'https://oportun.com/whatweoffer/creditcards/')">here</a>.</p>
            <br>
            <p><a href="#" title="Oportun Cardholder Agreement" @click="$emit('link-click', 'https://oportun.com/wp-content/uploads/2020/02/oportun-cardholderagreement-en.pdf')">Oportun Cardholder Agreement</a></p>
            <br>
            <p><a href="#" title="Oportun Privacy Policy" @click="$emit('link-click', 'https://oportun.com/privacy/')">Oportun Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Oportun Privacy Notice" @click="$emit('link-click', 'https://oportun.com/wp-content/uploads/2021/07/oportun_privacy_notice-2021-07-EN.pdf')">Oportun Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://oportun.com/wp-content/uploads/2021/07/oportun_privacy_notice-2021-07-EN.pdf')">WebBank Privacy Notice</a></p>
            <br>
            <br>
            <p>En asociación con Oportun, Inc., WebBank es el emisor de la Tarjeta de Crédito Oportun<sup>&reg;</sup> Visa<sup>&reg;</sup>. Para preguntas
                sobre la cuenta de su Tarjeta de Crédito Oportun<sup>&reg;</sup> Visa, por favor llame al 833-676-7886. Si está interesado en obtener más
                información sobre cómo solicitar crédito, haga clic
            <a href="#" title="Apply at Oportun" @click="$emit('link-click', 'https://oportun.com/whatweoffer/creditcards/')">aqui</a>.</p>
            <br>
            <p><a href="#" title="Oportun Cardholder Agreement" @click="$emit('link-click', 'https://oportun.com/wp-content/uploads/2020/02/oportun-cardholderagreement-es.pdf')">Acuerdo del titular de la Tarjeta de Crédito Oportun<sup>&reg;</sup> Visa<sup>&reg;</sup></a></p>
            <br>
            <p><a href="#" title="Oportun Privacy Policy" @click="$emit('link-click', 'https://oportun.com/es/privacy/oportun-privacy-policy/')">Politica de Privacidad de Oportun</a></p>
            <br>
            <p><a href="#" title="Oportun Privacy Notice" @click="$emit('link-click', 'https://oportun.com/wp-content/uploads/2021/07/oportun_privacy_notice-2021-07-ES.pdf')">Aviso de Privacidad de Oportun</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://oportun.com/wp-content/uploads/2021/07/webbank-privacy_notice-2021-07-ES.pdf')">Aviso de Privacidad de WebBank</a></p>

            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
