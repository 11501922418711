<template>
<div>

    <div class="box partner-box" style="display: grid; place-items: center;" @click="partner_modal = true">
        <img src="https://assets.webbank.com/Collective-logo.jpg" style="max-width: 75%;" alt="Collective Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div style="display: grid; place-items: center;">
            <img src="https://assets.webbank.com/Collective-logo.jpg" style="max-width: 200px;" alt="Collective Logo">
          </div>
            <br>
            <p>In partnership with Collective Liquidity, WebBank is the lender of Exchange Installment Loans.
                If you are interested in applying, click <a href="#" title="Collective Apply" @click="$emit('link-click', 'https://www.collectiveliquidity.com/onboarding/basic-info')">here</a>.
                To speak with someone about your Exchange Installment Loan, please call Collective Liquidity at <a href="tel:+18889775228">(888) 977-5228</a>,
                or you may contact Collective Liquidity via email at <a href="mailto:support@collectiveliquidity.com">support@collectiveliquidity.com</a>.</p>
            <br>
            <br>
            <p><a href="#" title="Collective Liquidity Privacy Policy" @click="$emit('link-click', 'https://www.collectiveliquidity.com/privacy-policies')">Collective Liquidity Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Collective Liquidity Privacy Notice and WebBank Privacy Notice" @click="$emit('link-click', 'https://www.collectiveliquidity.com/privacy-notices')">Collective Liquidity Privacy Notice and WebBank Privacy Notice</a></p>
            <br>

            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
