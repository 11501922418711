<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-hardy2.png" class="is-rounded" alt="Kelsey Hardy">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Kelsey Hardy</h2>
            <h3 class="people-title">SVP Business Development</h3>
            <p class="people-bio">
                Kelsey oversees Business Development for new Strategic Partners as well as new
                product development for existing Strategic Partners. She has 15+ years of experience
                in various aspects of banking, partnerships, contract negotiations and financial
                services. Additionally, prior to joining Business Development at WebBank she held
                various positions in Compliance and Due Diligence &amp; Implementation at WebBank.
                Prior to joining the Bank in 2015, Kelsey worked for the Department of Defense in
                defense contract pricing, negotiations, and oversight. Kelsey received a B.S. from Westminster College and M.B.A. from Utah State
    University.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
