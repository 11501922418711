<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/cap_on_tap2.png" alt="Capital On Tap Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/cap_on_tap2.png" alt="Capital On Tap Logo" style="max-width: 70%;">
          </div>
            <br>
            <p>In partnership with Capital on Tap, WebBank is the issuer and lender of the Capital on Tap Business Credit Card
                program for small and medium sized businesses. For more information about the Capital on Tap Business Credit Card
                or to apply for it, click <a href="#" title="More information at Capital On Tap" @click="$emit('link-click', 'https://www.capitalontap.com/us/')">here</a>.
                If you have questions about your account, please call 1-833-972-1323.</p>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
