<template>
<div>

    <div class="box partner-box" style="display: grid; place-items: center;" @click="partner_modal = true">
        <img src="https://assets.webbank.com/reprise.png" style="max-width: 75%;" alt="Reprise Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div style="display: grid; place-items: center;">
            <img src="https://assets.webbank.com/reprise.png" style="max-width: 200px;" alt="Reprise Logo">
          </div>
            <br>
            <p>In partnership with Reprise Financial, WebBank is the lender of secured and unsecured personal
                loans that can be used for refinancing, debt consolidation, unexpected expenses and more. If
                you are interested in applying for a loan
                <a @click="$emit('link-click', 'https://www.reprisefinancial.com/')">click here</a>.
                To speak with someone regarding your Reprise Financial account, please call 877-505-6780.</p>

            <br>
            <p><a href="#" title="Privacy Policy" @click="$emit('link-click', 'https://www.reprisefinancial.com/privacy-policies/')">Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Privacy Notice" @click="$emit('link-click', 'https://www.reprisefinancial.com/privacy-notice/')">Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://www.reprisefinancial.com/webbank-privacy-notice/')">WebBank Privacy Notice</a></p>
            <br>

            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
