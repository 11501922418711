<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr31-hero.png" alt="Financial Technology Association">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr31-small.png" alt="Financial Technology Association">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">WebBank Joins the Financial Technology Association</h2>
                        <p><em>Preeminent bank partner for the nation’s leading fintechs adds to FTA’s growing community of
                                affiliate members.</em></p>
                        <p><em><small>July 25th, 2023</small></em></p>
                        <br>
                        <p><strong>WASHINGTON, D.C.</strong> - Today, the
                            <a @click="clickOutWarning('https://www.ftassociation.org/');">Financial Technology Association (FTA)</a> announced
                            that <a href="https://webbank.com">WebBank</a> has joined the association as an Affiliate Member and will serve as a representative
                            on FTA’s Board of Directors. WebBank is one of the nation’s leading chartered banks partnering
                            with financial technology companies to provide innovative financial services to American
                            consumers and small businesses.</p>
                        <br>
                        <p>“We are excited to have WebBank join FTA and welcome Parris Sanz to our Board of Directors.
                            FTA will benefit from WebBank’s expertise as a trusted bank partner for the nation’s leading
                            fintech companies and from Parris’ years of experience as a fintech executive,” said <strong>Penny
                            Lee, President and Chief Executive Officer of the Financial Technology Association</strong>.
                            “Fintech industry partners are a critical part of the ecosystem that helps ensure millions of
                            consumers and small businesses can take advantage of the best financial services for their
                            needs.”</p>
                        <br>
                        <p>“We are thrilled to join the nation’s premier fintech trade association and work together to
                            shape
                            the future of finance,” said <strong>Parris Sanz, Executive Vice President, General Counsel, and
                            Head of Policy and Government Relations at WebBank</strong>. “WebBank empowers the fintechs
                            driving choice and competition in the economy, and we support FTA’s mission of modernizing
                            financial services policies and regulations to reflect this innovation in the marketplace.”
                            FTA partners with leading companies like WebBank to build a community of expertise, allow for
                            broader collaboration in the financial services industry, and inform policy and advocacy
                            positions to support fintech industry growth. FTA member companies will benefit from
                            WebBank’s experience advising leading brands and its track record of developing best-in-class
                            compliance and risk management programs.</p>
                        <br>
                        <p>To learn more about the contribution of FTA’s Affiliate Members,
                            <a @click="clickOutWarning('https://www.ftassociation.org/members/explore');">
                                explore our membership page here</a>.</p>
                        <br>
                        <p><strong>About FTA</strong></p>
                        <p>The Financial Technology Association (FTA) is a Washington, DC-based trade association
                            representing industry leaders shaping the future of finance. We champion the power of
                            technology-centered financial services and advocate for the modernization of financial
                            regulation to support inclusion and responsible innovation.</p>
                        <br>
                        <p><strong>About WebBank</strong></p>
                        <p><a href="https://webbank.com">WebBank</a> is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its
                            inception in 1997, WebBank has originated and funded over $175 billion in consumer and
                            commercial credit products. As “The Bank Behind the Brand<sup>&reg;</sup>”, WebBank is a national issuer of
                            consumer and small business credit products through Strategic Partner (Brand) platforms,
                            which include retailers, manufacturers, finance companies, software as a service (SaaS) and
                            financial technology (FinTech) companies. The Bank is a leading player in the digital lending
                            space, driving innovation in financial products through embedded finance with Strategic Partner
                            platforms. WebBank engages in a full range of banking activities including consumer and
                            commercial loan products, revolving lines of credit, credit cards, private-label card issuance,
                            auto-refinancing and more. The Bank provides capital in the form of asset-based lending and
                            other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a
                            targeted focus on specialty finance assets. The Bank is also a leading provider of commercial
                            insurance premium finance products through its wholly owned subsidiary National Partners. For
                            more information, please visit <a href="https://webbank.com">www.webbank.com</a>.</p>
                        <br>
                        <br>
                        <p>Contact:<br>
                            <a href="mailto:media@ftassociation.org">Media@ftassociation.org</a></p>
                    </div>

                    <div class="modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website and does not
                                    represent either the linked website or you, should you enter into a transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked site. We
                                    encourage you to review their privacy and security policies which may differ from
                                    WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "Financial Technology Association - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
