<template>
<div>

    <div class="box partner-box" style="display: grid; place-items: center;" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/aqua.png" style="max-width: 75%;" alt="Aqua Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div style="display: grid; place-items: center;">
            <img src="https://assets.webbank.com/partners/aqua.png" style="max-width: 200px;" alt="Aqua Logo">
          </div>
            <br>
            <p>In partnership with Aqua Finance, Inc., WebBank is the lender for Aqua’s closed-end, consumer home improvement loans.
                If you have questions about your loan, please call <a href="tel:+18777630522">877-763-0522</a>
                or send an email to <a href="mailto:info@aquafinance.com">info@aquafinance.com</a>.</p>
            <br>
            <p><a href="#" title="Aqua Finance Privacy Policy" @click="$emit('link-click', 'https://www.aquafinance.com/privacy-policy')">Aqua Finance Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Aqua Finance Privacy Notice" @click="$emit('link-click', 'https://www.aquafinance.com/wp-content/uploads/Aquaprivacynotice.pdf')">Aqua Finance Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://www.aquafinance.com/wp-content/uploads/2024/05/WebBank-Privacy-Notice.pdf')">WebBank Privacy Notice</a></p>
            <br>

            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
