<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr20-hero.png"
                            alt="Kelsey Hardy Promotion">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr20-small.png"
                            alt="Kelsey Hardy Promotion">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">WebBank Announces Kelsey Hardy Named SVP - Business Development</h2>
            <br>
            <h4 class="news-date">January 20th, 2023</h4>
            <br>
            <p>SALT LAKE CITY - WebBank is pleased to announce the appointment of Kelsey Hardy as the Senior Vice President - Business Development.</p>
            <br>
            <p>Kelsey joined WebBank in December 2015, bringing more than ten years of business development,
contract structuring, negotiating and oversight experience. During her tenure at the Bank, Kelsey has held
various positions in Compliance, Due Diligence & Implementation and most recently, as Vice President -
Business Development.</p>
            <br>
            <p>In her new role, Kelsey is responsible for leading the Business Development team in sourcing and
structuring new Strategic Partner programs, as well as driving strategy associated with new products
through existing Strategic Partner platforms and expansion into new target market verticals.</p>
            <br>
            <p>"Business Development is a critical component of the Bank's long-term strategic goals, and Kelsey has
the experience and industry knowledge to lead this team in sourcing and structuring programs and
products with both new and existing Strategic Partner platforms, as well as expanding the Bank's efforts
into new verticals" said Jason Lloyd, President &amp; CEO. "Kelsey's ability to vet, negotiate and structure
new products and programs with Strategic Partner platforms is well suited for this role."</p>
            <br>
            <p class="news-section-title">About WebBank</p>

            <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its inception in
1997, WebBank has originated and funded over $160 billion in consumer and commercial credit products.
As "The Bank Behind the Brand<sup>&reg;</sup>", WebBank is a national issuer of consumer and small business credit
products through Strategic Partner (Brand) platforms, which include retailers, manufacturers, finance
companies, software as a service (SaaS) and financial technology (FinTech) companies. The Bank is a
leading player in the digital lending space, driving innovation in financial products through embedded
finance with Strategic Partner platforms. WebBank engages in a full range of banking activities including
consumer and commercial loan products, revolving lines of credit, credit cards, private-label card
issuance, auto-refinancing and more. The Bank provides capital in the form of asset-based lending and
other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a targeted focus
on specialty finance assets. The Bank is also a leading provider of commercial insurance premium finance
products through its wholly owned subsidiary National Partners. For more information, please visit
www.webbank.com.</p>
<br>
            <p class="news-section-title">Contact:</p>
            <p>Shannon Hutchings<br>
                <a href="mailto:shannon.hutchings@webbank.com">shannon.hutchings@webbank.com</a>
            </p>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "Kelsey Hardy - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
