<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr4-hero.png" alt="WebBank and Klarna">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr4-small.png" alt="WebBank and Klarna">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">Klarna Card launches in the US alongside new money management tools, empowering consumers to take control of their finances</h2>
            <ul>
                <li>With over 1 million waitlisted consumers, the highly anticipated Klarna Card is now available in the US enabling consumers to "Pay in 4" everywhere with zero interest</li>
                <li>New Financial Overview dashboard in the Klarna App features budgeting tools, spending insights, and more</li>
            </ul>
            <br>
            <h4 class="news-date">June 1st, 2022</h4>
            <br>
            <p>Klarna, a leading global retail bank, payments, and shopping service, that helps consumers save time and money,
                be informed and in control, today announced the launch of the
                <a href="#" @click="clickOutWarning('https://www.klarna.com/us/klarna-card/')">Klarna Card</a> in the US, bringing the company's popular
                "Pay in 4" service to a physical VISA card form. The Klarna Card, issued by Webbank, empowers consumers to pay
                over time in four, interest-free payments with no down payment for any store or online purchase, offering a welcome
                and sustainable alternative to high-cost credit cards (watch video
                <a href="#" @click="clickOutWarning('https://www.youtube.com/watch?v=v7sjAAqZuXQ')">here</a>). In tandem with the launch of the card, a
                new service "Financial Overview" is now available in the Klarna App, featuring a host of powerful money management
                tools to help consumers stay on top of their Klarna purchases and keep full control of their finances at all times.</p>
            <br>
            <p>"The fact that over 1 million US consumers signed up to our waitlist in a matter of weeks demonstrates the incredible
                demand for a fair and transparent alternative to conventional credit cards," said Sebastian Siemiatkowski, Co-Founder
                and CEO, Klarna. "By placing the power of our 'Pay in 4' service in consumers' pockets, the Klarna Card lets them
                experience the convenience, flexibility and control of interest-free shopping wherever they go. In combination with
                our new, intuitive Financial Overview dashboard in the Klarna App, we make it easier than ever for consumers to
                understand their spending habits and manage their budget, placing them in the driver's seat of their financial future."</p>
            <br>
            <p>The Klarna Card does not charge any interest whatsoever, unlike conventional credit cards that charge US consumers
                <a href="#" @click="clickOutWarning('https://www.forbes.com/advisor/credit-cards/average-credit-card-interest-rate/#:~:text=The%20Federal%20Reserve%20keeps%20tabs,that%20assessed%20interest%20was%2016.45%25.')">16.45%
                APR on average</a>. Instead, Klarna offers a fair and transparent model: the Klarna Card is available for $3.99 a month, and
                is entirely free for the first 12 months after activation, enabling consumers to try it out at no cost for a whole year.</p>
            <br>
            <p>As with Klarna's other payment services, consumers are rewarded for paying with the Klarna Card. Members of the Klarna Rewards
                Club using the Klarna Card can earn points by completing "Missions" - small, engaging tasks aimed at encouraging consumers
                to discover different features in the Klarna App and take control of their finances. Points can then be redeemed in the Klarna
                App for rewards at world-class brands in the form of gift cards or vouchers.</p>
            <br>
            <p>Consumers pay for their card purchases via the Klarna App with transparent, bi-weekly statements that make it effortless
                to manage and keep track of all outstanding payments. In the app, consumers can choose to pay their statement early or
                enable "Autopay" to pay automatically on the due date. The Klarna App also notifies consumers in real-time of all card
                transactions and sends alerts for upcoming payments. In combination with the spending insights in the new "Financial
                Overview" dashboard in the Klarna App, Klarna Card users stay informed on their spending at all times.</p>
            <br>
            <p>The new "Financial Overview" dashboard features a comprehensive suite of money management tools designed to give users
                full transparency into their spending and enable them to take active control of their finances. According to new findings
                in <a href="#" @click="clickOutWarning('https://insights.klarna.com/money-management-pulse/')">Klarna's Money Management Pulse</a>,
                only 46% of US consumers say they are organized with their money and nearly a quarter
                (22%) do not save any of their income. In order to close this gap, Klarna's Financial Overview dashboard tracks all payments
                made with Klarna and automatically categorizes them, whether consumers shop with the Klarna Card, in the Klarna App or with
                Klarna at an online checkout.</p>
            <br>
            <br>
            <p class="news-section-title">Klarna Card features include:</p>
            <ul>
                <li>Physical VISA card with a choice of designs in either black or pink</li>
                <li>Unique payment experience that lets consumers split any purchase into four equal, interest free payments with no down payment</li>
                <li>Rewards from top brands redeemable in the Klarna App for completing "Missions" with the card</li>
                <li>Clear, bi-weekly statements for smooth and transparent repayments with option to pay automatically on the due date</li>
                <li>Simple, smooth sign-up process with no impact on credit score</li>
                <li>No foreign exchange fee/markup from Klarna</li>
                <li>In-store contactless payment</li>
                <li>Instant push notifications for all transactions, even if a payment is declined</li>
                <li>Overview of all purchases in the app with spending insights and automatic categorization</li>
                <li>Fast and easy contactless payments via mobile or wearable device with Apple Pay or Google Pay</li>
                <li>Fast and easy contactless payments via mobile or wearable device with Apple Pay or Google Pay</li>
                <li>Short-term card freezing quickly and easily via the app</li>
                <li>Customer service is available 24/7 in the app</li>
            </ul>
            <br>
            <p class="news-section-title">Financial Overview features include:</p>
            <ul>
                <li><strong>Budgeting:</strong> Consumers can set a monthly allowance for themselves to control how much they spend with Klarna. The new, in-app bar indicator visually shows how much from each consumers' set limit has been spent using all of Klarna's products, helping users budget moreconfidently.</li>
                <li><strong>Categorization:</strong> All purchases made with Klarna are automatically organized into product categories, from Clothing and Home, to Electronics. This new categorization feature allows consumers to understand exactly where their money goes, helping them to make more conscious spending decisions.</li>
                <li><strong>Monthly spending breakdown:</strong> Customers can track their spending levels month over month and compare them to their average spending via an intuitive and interactive bar chart. Each chart is color-coded to reflect what proportion is spent on each category, making it easy to see how spending patterns change over time.</li>
            </ul>
            <br>
            <p>Over the past year, Klarna's US customer base has grown by over 65%, reaching over 25 million consumers. The launches of
                the Klarna Card and Financial Overview dashboard are the latest in a burst of additions to Klarna's consumer offering in
                the US. In May, Klarna introduced its new
                        <a href="#" @click="clickOutWarning('https://c212.net/c/link/?t=0&l=en&o=3554823-1&h=3785502435&u=https%3A%2F%2Fwww.klarna.com%2Finternational%2Fpress%2Fklarna-launches-virtual-shopping-bringing-the-in-store-experience-to-online-shoppers%2F&a=virtual+shopping+feature')">virtual shopping feature</a>, enabling
                consumers to browse and buy online with confidence by connecting them directly with in-store experts to receive product
                advice and inspiration through live chats and video calls.</p>
            <br>
            <p class="news-section-title">About Klarna</p>
            <p>Since 2005 Klarna has been on a mission to revolutionize the retail banking industry. With over 147 million global active
                users and 2 million transactions per day, Klarna is meeting the changing demands of consumers by saving them time and
                money while helping them be informed and in control. Over 400,000 global retail partners, including H&amp;M, Saks, Sephora,
                Macys, IKEA, Expedia Group, and Nike have integrated Klarna's innovative technology to deliver a seamless shopping
                experience online and in-store. With over 5,000 employees, Klarna is active in 45 markets and is one of the most
                highly-valued private fintechs globally, with a valuation of $45.6 billion. For more information, visit
                <a href="#" @click="clickOutWarning('https://www.klarna.com')">Klarna.com</a></p>
            <br>
            <p class="news-section-title">For additional information, please contact:</p>
            <p>Shira Rimini<br>
                press.us@klarna.com<br>
                (614) 962-4605</p>

                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "Klarna Card launches in the US - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
