<template>
    <section class="hero deposits-hero is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-8">
                        <div class="deposit-products-box">
                            <p class="title hero-title">
                                Deposit Products
                            </p>
                            <p class="subtitle hero-subtitle">
                               Help your savings work harder and grow faster with savings products from WebBank.
                            </p>
                        </div>
                    </div>
                    </div>

                </div>
            </section>
        </div>
    </section>

    <div class="blue-bg is-hidden-widescreen">
                        <img src="https://assets.webbank.com/wb-deposits-hero3.png" alt="WebBank Deposit Products">
        <section class="section">
            <div class="container">
                            <p class="title hero-title-mobile">
                                Deposit Products
                            </p>
                            <p class="subtitle hero-subtitle-mobile">
                               Help your savings work harder and grow faster with savings products from WebBank.
                            </p>
                            <br>
            </div>
        </section>
    </div>
    <div class="grey-bg">
        <section class="section">
            <div class="container">
                <holiday-notification></holiday-notification>
                <br>
<div class="tile is-ancestor">
  <div class="tile is-vertical">
    <div class="tile">
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
                            <br>
                            <span class="icon diff-icon"><i class="fa-light fa-badge-dollar fa-4x"></i></span>
                            <br>
                            <br>
                            <h4 class="box-title">Certificates of Deposit</h4>
                            <p class="">A certificate of deposit (CD) from WebBank is a great way to set money aside, let it grow with interest earned, and then cash it out at maturity.</p>
                            <br>
                            <router-link to="/time-deposits" class="button is-primary" title="Learn More About CD's">Learn More</router-link>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
                            <br>
                            <span class="icon diff-icon"><i class="fa-light fa-sack-dollar fa-4x"></i></span>
                            <br>
                            <br>
                            <h4 class="box-title">Savings Accounts</h4>
                            <p class="">WebBank savings accounts feature no monthly fee and competitive interest rates to help your money grow faster!</p>
                            <br>
                            <br>
                            <router-link to="/savings" class="button is-primary" title="Learn More about Savings">Learn More</router-link>
        </article>
      </div>
    </div>
  </div>
</div>

<div class="tile is-ancestor">
  <div class="tile is-vertical">
    <div class="tile">
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
            <br>
            <span class="icon diff-icon"><i class="fa-light fa-coins fa-4x"></i></span>
            <br>
            <br>
            <h4 class="box-title">Rates and Fees</h4>
            <p class="">Our accounts feature competitive rates and no monthly fees. Check out our rates and fees and see how much more you can save.</p>
            <br>
            <router-link to="/rates-fees" class="button is-primary" title="Learn More about our Rates and Fees">Learn More</router-link>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
            <br>
            <span class="icon diff-icon"><i class="fa-light fa-envelope-open-text fa-4x"></i></span>
            <br>
            <br>
            <h4 class="box-title">Forms</h4>
            <p class="">Get access to various forms that simplify managing your deposit account.</p>
            <br>
            <br>
            <router-link to="/forms" class="button is-primary" title="Access Forms">Learn More</router-link>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
            <br>
            <span class="icon diff-icon"><i class="fa-light fa-building-lock fa-4x"></i></span>
            <br>
            <br>
            <h4 class="box-title">Banking Security</h4>
            <p class="">Protecting your money is paramount. See how WebBank keeps your money safe,
                and get tips on the best banking practices you can use to stay secure.</p>
            <br>
            <router-link to="/security" class="button is-primary" title="Learn more about our Banking Security">Learn More</router-link>
        </article>
      </div>
    </div>
  </div>
</div>


            </div>
        </section>
        <br><br>
    </div>

    <careers-hero parent="deposits"></careers-hero>
</template>

<script>
import CareersHero from '@/components/CareersHero.vue';
import HolidayNotification from '../components/HolidayNotification.vue';

export default {
    components: { HolidayNotification, CareersHero },

    created() {
        document.title = "Deposit Products - WebBank";
    },

}
</script>
