<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr10-hero.png" alt="WebBank and Lane Health">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr10-small.png" alt="WebBank and Lane Health">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">CGI Business Solutions announces partnership with Lane Health to offer innovative spending account solutions</h2>
            <h4 class="news-page-title">CGI Business Solutions announced they have entered into a partnership with Lane Health to offer access to an Advance line of credit, issued by WebBank, for all their HSA account clients.</h4>
            <br>
            <h4 class="news-date">July 20th, 2022</h4>
            <br>
            <p>CGI Business Solutions announced they have entered into a partnership with Lane Health to offer access to an Advance line of credit, issued by WebBank, for all their HSA account clients. CGI Business Solutions will continue to provide high-quality spending account administration services to their existing and new customers. The Advance line of credit will be powered through Lane Health.</p>
            <br>
            <p>“CGI Business Solutions is energized and thrilled to add Lane Health and the Advance line of credit to its HSA solution so that our customers can provide additional financial protection to employees who might struggle to pay for a large or unexpected healthcare expense,” says Dan Cronin, President of CGI Business Solutions. “Lane Health helps solve a major problem with the financial burden of healthcare, and their experienced leadership and service teams make this a perfect partnership.”</p>
            <br>
            <p>“The Advance line of credit is a true differentiator”, says Brad Gambill, Co-founder & CEO of Lane Health. “By providing on-demand funds to employees, CGI's clients can give their employees greater peace of mind, knowing they'll have help paying for healthcare expenses when they need it, even employees who make no contribution precommitment. And, they'll have 12 months to repay the Advance and associated fees. The employer pays no fees, has no funding obligation, and is not at risk for any uncollectible Advances, making this a very easy and valuable benefit to offer its employees.”</p>
            <br>
            <p>For 25 years, CGI Business Solutions, who is the largest employee benefits agency in New Hampshire, and one of the largest independent firms in New England, has provided a wide array of unmatched solutions to their clients. CGI Business Solutions has experienced growth year over year and has added services to include everything from employee benefits, benefits administration, HR support, business insurance, payroll services, and retirement planning. All these services are provided in-house by an award-winning, local team.</p>
            <br>
            <p>Through Lane Health, access to tax effective healthcare lending is offered for employees with Health Savings Accounts. The program allows employees to pay for care over 12 months, while reducing taxes and overall cash outlay. Employers bear no risk and pay no lending fees. For more information, visit <a href="#" @click="clickOutWarning('https://lanehealth.com/')">LaneHealth.com</a>.</p>
            <br>

            <p class="news-section-title">Contact Information</p>
            <p>James Denison<br>Lane Health<br>http://lanehealth.com<br>804-218-6912</p>
            <br>
                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "Lane Health - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
