<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr2-hero.png" alt="WebBank and PayPal">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr2-small.png" alt="WebBank and PayPal">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">PayPal Introduces 'Pay Monthly' to Give Consumers More Choice at Checkout</h2>
            <br>
            <h4 class="news-page-subtitle">Expansion of 'Buy Now Pay Later' product suite offers new flexible monthly payments with no late fees in the U.S.</h4>
            <br>
            <h3 class="news-date">June 15th, 2022</h3>
            <br>
            <p>PayPal Holdings, Inc. (NASDAQ:PYPL) today announced PayPal Pay Monthly<sup>1</sup>, its newest buy now, pay later offering,
                issued by WebBank, which gives U.S. consumers the ability to spread payments out over longer periods of time.
                With the addition of Pay Monthly, customers will have access to a new offering through PayPal that gives them greater
                flexibility and even more choices to pay for the items they want and need.</p>
            <br>
            <p>A recent study found that 65% of Americans are saving for a big purchase, and 79% are looking to create and maintain a
                budget<sup>2</sup>, which has created a growing need for shoppers to buy the items they want in manageable payments.
                Pay Monthly is a new way for customers to make large purchases between $199-$10,000 and lets them break the total
                cost into monthly payments over a 6-24-month period, with the first payment due one month after purchase.
                Once a customer selects Pay Monthly, they will complete an application at checkout and, if approved, will be presented
                with up to three different plans of varying lengths with risk-based APR ranging from 0%-29.99%. Customers can then
                compare and select the option that best suits their budget.</p>
            <br>
            <p>"How consumers look to pay for larger purchases is evolving and there is a growing demand for flexible payment options
                with 22 million PayPal customers using our pay later offering this past year," said Greg Lisiewski, Vice President
                of Shopping and Pay Later at PayPal. "Pay Monthly builds on our commitment to deliver leading payment solutions that
                offer customers choice to ensure checkout matches their needs and budgeting preferences."</p>
            <br>
            <p>The pay later products through
                PayPal, including Pay Monthly and PayPal Pay in 4, have no late fees and allow consumers to choose their repayment method
                and select automatic payments up front when completing their transaction. With Pay Monthly, customers can select between
                using their debit card or bank account for repayment and seamlessly manage and track payments through the PayPal app
                and online. Plus, consumers have the peace-of-mind that comes with knowing their purchases are backed by PayPal
                Purchase Protection. Customers will have the option to select Pay Monthly for their shopping needs at millions of
                retailers including brands like Outdoorsy, Samsonite, Fossil, and Advance Auto in time for their summer purchases
                and activities.</p>
            <br>
            <p>Since 2004, PayPal has partnered with banks to provide industry leading credit products, allowing millions of consumers
                the ability to spread out payments over time. Pay Monthly continues a tradition of transparent point-of-sale lending
                products and leverages over a decade of experience working with bank partners who underwrite loans to improve the
                checkout experience and best match loan approvals with customer purchases through various macroeconomic conditions.</p>
            <br>
            <p>Pay Monthly will be automatically available for merchants at no additional cost or risk and requires no complex back-office
                integration to add as a payment option. Similar to Pay in 4, merchants can also add dynamic messaging to deliver relevant
                Pay Later options that are presented early in the customer's shopping journey to let them know they will have the option
                to spread out payments at checkout.</p>
            <br>
            <ol class="news-footnote">
                <li>Pay Monthly is subject to credit approval. The APR is 0-29.99% based on consumer credit eligibility.
                    Terms and availability may vary based on the merchant and purchase amount and may not be available in all states.
                    PayPal, Inc.: RI Loan Broker Licensee. The lender for Pay Monthly is WebBank.</li>
                <li>Morning Consult State of Banking Q1 2022 (2200 US Adults)</li>
            </ol>
            <br>
            <p class="news-section-title">About PayPal</p>
            <p>PayPal has remained at the forefront of the digital payment revolution for more than 20 years. By leveraging technology
                to make financial services and commerce more convenient, affordable, and secure, the PayPal platform is empowering
                more than 429 million consumers and merchants in more than 200 markets to join and thrive in the global economy.
                For more information, visit paypal.com.</p>
                        </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "Capital On Tap Celebrates 10 Years - WebBank";
    },

}
</script>
