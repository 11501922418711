<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr41_hero.png" alt="Ken Laudano Named SVP – Head of Credit Structuring &amp; Counterparty Risk">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr41_small.png" alt="Ken Laudano Named SVP – Head of Credit Structuring &amp; Counterparty Risk">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">WebBank Announces Ken Laudano Named SVP – Head of Credit Structuring &amp; Counterparty Risk</h2>
                        <br>
                        <p><em><small>May 22, 2024</small></em></p>
                        <br>
                        <p>SALT LAKE CITY - WebBank today announced the appointment of Ken Laudano as the Senior Vice President - Head of Credit Structuring & Counterparty Risk.</p>
                        <br>
                        <p>Ken joined WebBank in May 2024, and in this newly created role he will lead the team responsible for managing the credit risk of WebBank’s asset finance lending portfolio. This includes review and approval of new counterparties and deal structures, effective challenge of the underwriting and cash flow modeling process, as well as proactive risk management of all asset finance credit exposures. Ken and his team will also work closely with the Asset Finance team on the due diligence of new deals, the ongoing monitoring and surveillance of existing deals, and the build-out of a robust asset finance lending infrastructure.  He will also play a significant role in developing the future strategy of the Asset Finance business at WebBank.</p>
                        <br>
                        <p>Ken brings more than 20 years of asset finance industry experience to the role, including extensive credit structuring, cash flow modeling, and portfolio management expertise. Before joining WebBank, Ken worked at East West Bank where he served as SVP / Deputy Chief Credit Officer for Structured Finance and Private Equity. Ken started his career in the Asset Finance Group at Moody’s Investors Service and has held senior positions at several leading asset finance institutions including Morgan Stanley, BNY Mellon and Credit Suisse.</p>
                        <br>
                        <p>Ken received an M.S. in Statistics and Operations Research from New York University, and a B.A. in Economics from the University of Connecticut.  He also holds the Chartered Alternative Investment Analyst (CAIA) and Financial Data Professional (FDP) designations.</p>
                        <br>
                        <p>“Credit Structuring and counterparty risk management are important components of the Bank’s long-term strategic goals, and Ken has a wealth of expertise and experience in navigating these crucial components of our business,” said Jason Lloyd, President & CEO. “Ken’s proven track record and credit expertise make him a valuable addition to our team, and I look forward to the substantial contributions he will bring in his new role.”</p>
                        <br>
                        <p><strong>About WebBank</strong></p>
                        <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its inception in 1997, WebBank has originated and funded over $190 billion in consumer and commercial credit products.  As “The Bank Behind the Brand®”, WebBank is a national issuer of consumer and small business credit products through Strategic Partner (Brand) platforms, which include retailers, manufacturers, finance companies, software as a service (SaaS) and financial technology (FinTech) companies.  The Bank is a leading player in the digital lending space, driving innovation in financial products through embedded finance with Strategic Partner platforms.  WebBank engages in a full range of banking activities including consumer and commercial loan products, revolving lines of credit, credit cards, private-label card issuance, auto-refinancing and more. The Bank provides capital in the form of asset-based lending and other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a targeted focus on specialty finance assets. The Bank is also a leading provider of commercial insurance premium finance products through its wholly owned subsidiary National Partners. For more information, please visit www.webbank.com.</p>
                        <br>
                        <p><strong>Contact:</strong></p>
                        <p>Shannon Hutchings<br>
                            <a href="mailto:shannon.hutchings@webbank.com">shannon.hutchings@webbank.com</a><br>
                        </p>
                    </div>

                    <div class=" modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website
                                    and does not represent either the linked website or you, should you enter into a
                                    transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked
                                    site. We encourage you to review their privacy and security policies which may differ
                                    from WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "Ken Laudano Named SVP – Head of Credit Structuring &amp; Counterparty Risk";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
