<template>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-5 is-offset-1">
                    <div class="columns">
                        <div class="column is-10">
                            <br>
                            <br>
                            <br>
                            <br>
                    <h3 class="is-size-1 has-text-primary section-title">WebBank Brand Partners </h3>
                    <br>
                    <p class="">WebBank is a national issuer of credit products to consumer borrowers through its Brand platforms.</p>
                    <br>
                    <p class="">Click on the logos below to learn more about each consumer credit product we offer.
                        To learn more about business credit products we offer, <router-link to="/business-brand-partners">click here</router-link>.
                    </p>
                        </div>
                    </div>
                    <br>
                </div>
                <div class="column is-5">
                    <br>
                    <img src="https://assets.webbank.com/R5_C1529.png" class="body-image" alt="WebBank Brand Partners" />
                </div>
            </div>
        </div>
    </section>
            <br>
    <section class="section grey-section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">
                    <div class="has-text-centered">
                    <h3 class="is-size-1 has-text-white section-title">Our Partners</h3>
                    <br>

                    <div class="columns">
                        <div class="column">
                            <partner-atlanticus @link-click="clickOutWarning"></partner-atlanticus>
                        </div>
                        <div class="column">
                            <partner-aqua @link-click="clickOutWarning"></partner-aqua>
                        </div>
                        <div class="column">
                            <partner-avant @link-click="clickOutWarning"></partner-avant>
                        </div>
                        <div class="column">
                            <partner-bmg @link-click="clickOutWarning"></partner-bmg>
                        </div>
                    </div>

                    <div class="columns">
                        <!-- <div class="column">
                            <partner-build @link-click="clickOutWarning"></partner-build>
                        </div> -->
                        <div class="column">
                            <partner-cleo @link-click="clickOutWarning"></partner-cleo>
                        </div>
                        <div class="column">
                            <partner-collective @link-click="clickOutWarning"></partner-collective>
                        </div>
                        <!-- <div class="column">
                            <partner-dell @link-click="clickOutWarning"></partner-dell>
                        </div> -->
                        <div class="column">
                            <partner-dignifi @link-click="clickOutWarning"></partner-dignifi>
                        </div>
                        <div class="column">
                            <partner-fingerhut @link-click="clickOutWarning"></partner-fingerhut>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <partner-gemini @link-click="clickOutWarning"></partner-gemini>
                        </div>
                        <div class="column">
                            <partner-icredit @link-click="clickOutWarning"></partner-icredit>
                        </div>
                        <!-- <div class="column">
                            <partner-jasper @link-click="clickOutWarning"></partner-jasper>
                        </div> -->
                        <div class="column">
                            <partner-klarna @link-click="clickOutWarning"></partner-klarna>
                        </div>
                        <div class="column">
                            <partner-lane-health @link-click="clickOutWarning"></partner-lane-health>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <partner-mosaic @link-click="clickOutWarning"></partner-mosaic>
                        </div>
                        <div class="column">
                            <partner-one-main @link-click="clickOutWarning"></partner-one-main>
                        </div>
                        <div class="column">
                            <partner-oportun @link-click="clickOutWarning"></partner-oportun>
                        </div>
                        <div class="column">
                            <partner-pay-pal-pay-monthly @link-click="clickOutWarning"></partner-pay-pal-pay-monthly>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <partner-petal @link-click="clickOutWarning"></partner-petal>
                        </div>
                        <div class="column">
                            <partner-prosper @link-click="clickOutWarning"></partner-prosper>
                        </div>
                        <div class="column">
                            <partner-reprise @link-click="clickOutWarning"></partner-reprise>
                        </div>
                        <div class="column">
                            <partner-scratch-pay @link-click="clickOutWarning"></partner-scratch-pay>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <partner-sezzle @link-click="clickOutWarning"></partner-sezzle>
                        </div>
                        <div class="column">
                            <partner-yamaha @link-click="clickOutWarning"></partner-yamaha>
                        </div>
                        <div class="column">
                            <partner-zip @link-click="clickOutWarning"></partner-zip>
                        </div>
                        <div class="column is-3">&nbsp;</div>
                    </div>

                    </div>
                    <br>
                    <br>
                </div>
            </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
</div>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="clickout_modal = false"></button>
</div>

        </div>
    </section>

</template>

<script>
import PartnerAvant from '@/components/PartnerAvant.vue'
import PartnerAqua from '@/components/PartnerAqua.vue'
// import PartnerBuild from '@/components/PartnerBuild.vue'
import PartnerBmg from '@/components/PartnerBmg.vue'
// import PartnerDell from '@/components/PartnerDell.vue';
import PartnerCleo from '@/components/PartnerCleo.vue';
import PartnerDignifi from '@/components/PartnerDignifi.vue';
import PartnerIcredit from '@/components/PartnerIcredit.vue';
// import PartnerJasper from '@/components/PartnerJasper.vue';
import PartnerLaneHealth from '@/components/PartnerLaneHealth.vue';
import PartnerKlarna from '@/components/PartnerKlarna.vue';
import PartnerMosaic from '@/components/PartnerMosaic.vue';
import PartnerOportun from '@/components/PartnerOportun.vue';
import PartnerPetal from '@/components/PartnerPetal.vue';
import PartnerProsper from '@/components/PartnerProsper.vue';
import PartnerReprise from '@/components/PartnerReprise.vue';
import PartnerYamaha from '@/components/PartnerYamaha.vue';
import PartnerFingerhut from '@/components/PartnerFingerhut.vue';
import PartnerGemini from '@/components/PartnerGemini.vue';
import PartnerOneMain from '@/components/PartnerOneMain.vue';
import PartnerZip from '@/components/PartnerZip.vue';
import PartnerPayPalPayMonthly from '@/components/PartnerPayPalPayMonthly.vue';
// import PartnerDomain from '@/components/PartnerDomain.vue';
import PartnerScratchPay from '@/components/PartnerScratchPay.vue';
import PartnerCollective from '@/components/PartnerCollective.vue';
import PartnerAtlanticus from '@/components/PartnerAtlanticus.vue';
import PartnerSezzle from '@/components/PartnerSezzle.vue';

export default {
    components: {
        PartnerAvant,
        PartnerAqua,
        // PartnerBuild,
        PartnerBmg,
        // PartnerDell,
        PartnerCleo,
        PartnerDignifi,
        PartnerIcredit,
        // PartnerJasper,
        PartnerLaneHealth,
        PartnerKlarna,
        PartnerMosaic,
        PartnerOportun,
        PartnerPetal,
        PartnerProsper,
        PartnerReprise,
        PartnerYamaha,
        PartnerFingerhut,
        PartnerGemini,
        PartnerOneMain,
        PartnerZip,
        PartnerPayPalPayMonthly,
        // PartnerDomain,
        PartnerScratchPay,
        PartnerCollective,
        PartnerAtlanticus,
        PartnerSezzle,
    },

    created() {
        document.title = "Brand Partners - WebBank";
    },

    data: function () {
        return {
            avant_modal: false,
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.closeAllPartnerModals();
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },

        closeAllPartnerModals: function () {
            this.partner_modal = false;
        }
    }
}
</script>
