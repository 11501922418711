<template>
<div>

    <div class="box partner-box" @click="shopify_modal = true">
        <img src="https://assets.webbank.com/partners/shopify.png" alt="Shopify Logo">
    </div>

<div class="modal" :class="{'is-active': shopify_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/shopify.png" alt="Shopify Logo">
          </div>
            <br>
            <p>In partnership with Shopify, WebBank is the lender of Shopify Capital in the United States.
                For questions about your loan, please send an email to: <a href="mailto:capital-support@shopify.com">capital-support@shopify.com</a>. If you are
                interested in learning more about Shopify Capital,
                click <a href="#" title="Learn more at Shopify" @click="$emit('link-click', 'https://www.shopify.com/capital')">here</a>.</p>
            <br>
            <button class="button is-primary is-light" @click="shopify_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="shopify_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            shopify_modal: false,
        }
    }
}
</script>
