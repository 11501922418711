<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr26_hero.png"
        alt="OneMain Financial Partners with FinMkt">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr26_small.png"
        alt="OneMain Financial Partners with FinMkt">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">OneMain Financial Partners with FinMkt to Offer Access to Point of Sale Home
                        Improvement Loans</h2>
            <br>
            <h4 class="news-date">May 2nd, 2023</h4>
            <br>
            <p>NEW YORK – May 2, 2023 – <a href="#" @click="clickOutWarning('https://onemainfinancial.com')">OneMain Financial</a>, the leader in offering nonprime customers
    responsible access to credit, has chosen <a href="#" @click="clickOutWarning('https://finmkt.io')">FinMkt</a>, a provider of embedded SaaS lending and
    payments solutions, to expand in the competitive home improvement point of sale financing
    market, with loans originated by WebBank. By combining OneMain’s leading nonprime
    underwriting capabilities and FinMkt's purpose-built technology, customers will be able to
    access personalized, responsible credit solutions from FinMkt’s award-winning platform and
    network of contractors through a streamlined loan experience at the point of sale.</p>
    <br>
    <p>With a single online application completed in minutes, home improvement contractors using
    FinMkt’s point of sale financing platform provide homeowners access to financing options from
    lenders included in FinMkt’s lender waterfall. In addition, contractors can use the platform to
    manage all their financing accounts with one user-friendly interface, eliminating the need to
    employ multiple single-lender solutions and manual application routing.</p>
    <br>
    <p>“As we advance our mission to improve the well-being of hardworking Americans, we are
    excited to partner with FinMkt’s impressive technology platform to help us serve more
    customers,” said Jenny Osterhout, OneMain’s Chief Strategy Officer.
    "We are excited to announce our partnership with OneMain,” said Luan Cox, CEO of FinMkt.
    “By integrating OneMain into our platform, we are able to provide nonprime customers access to
    OneMain’s borrowing solutions and exemplary customer service.”</p>
    <br>
            <p class="news-section-title">About OneMain Financial</p>

            <p>OneMain Financial (NYSE: OMF) is the leader in offering nonprime customers responsible
    access to credit and is dedicated to improving the financial well-being of hardworking
    Americans. We empower our customers to solve today’s problems and reach a better financial
    future through personalized solutions available online and in 1,400 locations across 44 states.
    OneMain is committed to making a positive impact on the people and the communities we serve.</p>
    <p>For additional information, please visit <a href="#" @click="clickOutWarning('https://onemainfinancial.com')">OneMainFinancial.com</a>.</p>
<br>
                <p class="news-section-title">About FinMkt</p>

                <p>FinMkt is revolutionizing digital lending and payments technology with a fully customizable,
    multi-lender, full credit spectrum, embedded SaaS solution. FinMkt's award-winning, API-driven
    platform offers both end-to-end and modularized consumer financing, loan origination and
    payments technology that empowers lenders, merchants, and enterprise partners to control their
    destiny with a customized solution tailored to their unique brand. FinMkt's proprietary, omni-
    channel and product agnostic SaaS platform is optimized for home improvement, healthcare, and
    retail.</p>
    <p>To learn more about FinMkt please visit <a href="#" @click="clickOutWarning('https://finmkt.io')">FinMkt.io</a> or reach us by email at info@finmkt.io.</p>
    <br>
<br>
            <p>Media Contact:<br>
                Kelly Ogburn<br>
                410-537-9028<br>
                <a href="mailto:kelly.ogburn@omf.com">kelly.ogburn@omf.com</a>
            </p>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "OneMain Financial Partners with FinMkt - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
