<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr18-hero.png"
                            alt="Collective Liquidity">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr18-small.png"
                            alt="Collective Liquidity">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">Collective Liquidity Launches Wealth Management Platform for Unicorn Shareholders</h2>
            <br>
            <p><em>New platform provides instant valuation, diversification and liquidity for unicorn shareholders.</em></p>
            <br>
            <h4 class="news-date">November 15th, 2022</h4>
            <br>
            <p>Collective Liquidity, the first wealth management tech platform for unicorn shareholders, announced today the launch of its innovative liquidity and risk management platform and closing of its Series A Preferred financing.  With the closing of the financing, the company has raised approximately $100 million in aggregate debt and equity financing to date.</p>
            <br>
            <p>The platform's unique products finally solve the three biggest problems in the private market today: (1) pricing opacity, (2) over-concentration and (3) illiquidity. Collective transactions can be initiated online in 5 minutes or less.</p>
            <br>
            <p>Collective Liquidity's revolutionary Private Market Valuation Algorithm analyzes thousands of pricing signals and company valuation data inputs to dynamically track the value of hundreds of unicorns.  Employees and other shareholders can log into Collective and receive instant valuations on their shares with no obligation.  Shareholders know the prices are 'real' because Collective will transact at the prices shown.</p>
            <br>
            <p>Unicorn shareholders can reduce the financial risk posed by over concentration with the Collective Exchange Fund.  Though exchange funds are well established for public market shareholders, Collective is the first to bring the concept to the private market.  The fund enables users to exchange their unicorn shares tax-free for an interest of equal value in a diversified portfolio of the world's top unicorns.  After the first year, limited partnership interests can be redeemed for cash at the end of any quarter.</p>
            <br>
            <p>Those that need immediate liquidity can borrow up to 60% of the value of their Exchange Fund interest at any time.  Funded by WebBank, Exchange Loans are secured only by the borrower's Exchange Fund interest and are non-recourse to the borrower. So, no matter what happens to the value of the Exchange Fund, Collective's customers always have the option to forfeit their limited partnership interest instead of coming out of pocket to repay their Exchange Loans.</p>
            <br>
            <p>Because both the Exchange and the Exchange Loan are tax-free and have no broker fees, many shareholders find that Exchange Loans generate greater immediate after-tax cash than a simple stock sale while still preserving long term investment upside in the Exchange Fund. Exchange Loan financing costs are typically a fraction of what other private market lenders charge.</p>
            <br>
            <p>"We founded Collective Liquidity because we recognized unicorn shareholders still lacked easy access to liquidity and other basic financial solutions," said Collective CEO and Co-founder Greg Brogger. "We felt there had to be a much more efficient way to connect shareholders with capital and investors with exposure to the unicorn asset class."</p>
            <br>
            <p>Phil Trahanas of Lampros Capital said, “the Collective Exchange Fund will function as a kind of 'liquidity utility' for the growth equity ecosystem by matching employees in need of immediate, tax-advantaged liquidity and risk reduction with investors looking to allocate to a transparent, liquid, diversified portfolio of unicorns.”</p>
            <br>
            <p>“With the launch of the Exchange Fund and Exchange Loans, Collective has developed a better way to offer unicorn shareholders access to capital. At the macro level, we're excited to play a central role in injecting liquidity into this critical sector of the innovation ecosystem,” said Jason Lloyd, President &amp; CEO of WebBank. “We are also very pleased to be enabling shareholders to finally unlock the value of their shares with a fully online transaction model.”</p>
            <br>
            <p>Greg Richter, CEO &amp; Partner of Medalist Partners, said “We see the liquidity demands of this private asset class continuing to grow.  Medalist is excited to partner with the talented team at Collective on their unique solution for increasing liquidity in the market for private company shares.”</p>
            <br>
            <p>The equity portion of Collective's Series A financing was led by Lampros Capital, an early stage fintech investor.  Debt financing is provided by WebBank, a leading lending partner to innovative fintech platforms and acapital provider in the form of asset-backed lending and other credit facilities to strategic partner platforms, credit funds, and other lenders, and Medalist Partners, an alternative investment management firm focused on structured credit and asset-based private credit strategies.</p>
            <br>
            <br>
            <p class="news-section-title">ABOUT COLLECTIVE LIQUIDITY</p>
            <p>Collective Liquidity is dedicated to providing unicorn shareholders with the financial tools and products they need to effectively manage their wealth. Collective's initial solutions enable transparency, diversification and liquidity, generating dramatically better financial outcomes for unicorn shareholders.</p>
            <br>
            <br>
            <p class="news-section-title">PRESS CONTACT:</p>
            <p>Evan Owens<br>
                <a href="mailto:evan.owens@fullyvested.com">evan.owens@fullyvested.com</a><br>
                <a href="tel:+17039647064">(703) 964-7064</a></p>
                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "Zip Card - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
