<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
                <img src="https://assets.webbank.com/people-cannon.png" class="is-rounded" alt="Erik Cannon">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Erik Cannon</h2>
            <h3 class="people-title">SVP Chief Operations Officer</h3>
            <p class="people-bio">
                Erik has worked in the fintech, banking, and credit card industries for over 18 years and with a
                particular emphasis in B2B financial products. He joined WebBank in 2016 and directs the integration of
                new partner relationships for the Bank. His experience includes organizations such as CAN Capital,
                American Express, and the Los Alamos National Laboratory. Erik has extensive experience in international
                operations, strategy development, relationship management, product development, and design,
                optimization, and implementation of complex business systems and processes. Erik received a Bachelor of
                Science in Systems Science and Mathematics with
                minors in Computer Science, Mathematics, and Classical Greek, and a Master of
                Business Administration from Washington University, as well as a Master of
                Engineering in Operations Research and Industrial Engineering from Cornell
                University with a Certificate in Financial Engineering.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
