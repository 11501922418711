<template>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                    <div class="content">
                    <br>
                        <br>
                        <br>
                        <br>
                    <h3 class="is-size-1 has-text-primary section-title">Business and Community Associations and Memberships</h3>
                        <p class="">At WebBank, our corporate and community partnerships represent our commitment to strengthen not just our industry but also the communities where we work and live.</p>
                    </div>
                    <br>
                </div>
                <div class="column">
                    <br>
                    <br>
                    <br>
                    <br>
                    <img src="https://assets.webbank.com/associations3.png" class="body-image" />
                </div>
            </div>

        </div>
    </section>

    <div class="grey-bg">
        <section class="section">
            <div class="container">
                <div class="has-text-centered">
                    <h3 class="is-size-1 has-text-white section-title">Organizations We're Proud to Support</h3>
                    <br>
                    <div class="content has-text-left">
                    <div class="columns">
                        <div class="column">
                            <ul>
                                <li>American Bankers Association (ABA)</li>
                                <li>Boys and Girls Club of Greater Salt Lake</li>
                                <li>Commercial Finance Association (CFA)</li>
                                <li>Economic Development Corporation of Utah</li>
                                <li>Financial Technology Association</li>
                                <li>Gettysburg College </li>
                            </ul>
                        </div>
                        <div class="column">
                            <ul>
                                <li>Habitat for Humanity</li>
                                <li>Junior Achievement of Utah</li>
                                <li>National Association of Industrial Banks (NAIB)</li>
                                <li>NeighborWorks Salt Lake</li>
                                <li>Salt Lake Valley Habitat for Humanity</li>
                            </ul>
                        </div>
                        <div class="column">
                            <ul>
                                <li>University of Utah/Opportunity Scholars Program</li>
                                <li>Utah Association of CPAs</li>
                                <li>Utah Association of Financial Services (UAFS)</li>
                                <li>Utah Bankers Association (UBA)</li>
                                <li>Utah Community Reinvestment Corporation</li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
                <br>
            </div>
        </section>
    </div>

    <careers-hero parent="business"></careers-hero>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
</div>

      </div>
  </div>
</div>
</template>

<script>
import CareersHero from '@/components/CareersHero.vue';
export default {
    components: {
        CareersHero
    },
    created() {
        document.title = "Business Associations and Memberships - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },

        closeAllPartnerModals: function () {
            this.avant_modal = false;
        }
    }
}
</script>
