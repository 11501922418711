<template>
    <nav class="navbar has-shadow is-fixed-top" id="navbar" role="navigation" aria-label="main navigation">
        <div class="container">
            <div class="navbar-brand">

            <div class="is-flex is-flex-direction-column m-4">
                <router-link class="navbar-item" to="/" @click="clickLogo" title="Home Page">
                        <img src="https://assets.webbank.com/webbank-logo.png" alt="WebBank Logo">
                </router-link>
            </div>

                <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false"
                    data-target="navbarBasicExample"
                     :class="{'is-active': mobile_menu}"
                      @click="mobile_menu = !mobile_menu">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbarBasicExample" class="navbar-menu" :class="{'is-active': mobile_menu}">
                <div class="navbar-start">
                </div>

                <div class="navbar-end">
                    <div class="navbar-item has-dropdown" :class="{'is-active': personal_menu}">
                        <a class="navbar-link" @click="personalDropdownClick">
                            Personal
                        </a>

                        <div class="navbar-dropdown is-right">
                            <router-link to="/brand-partners" replace class="navbar-item" @click="personalMenuItemClick('/brand-partners')">
                                Brand Partners
                            </router-link>
                            <router-link to="/deposit-products" class="navbar-item" @click="personalMenuItemClick('/deposit-products')">
                                Deposit Products
                            </router-link>
                        </div>
                    </div>

                    <div class="navbar-item has-dropdown" :class="{'is-active': business_menu}">
                        <a class="navbar-link" @click="businessDropdownClick">
                            Business
                        </a>

                        <div class="navbar-dropdown is-right">
                            <router-link to="/business-brand-partners" class="navbar-item" @click="businessMenuItemClick('/business-brand-partners')">
                                Brand Partners
                            </router-link>
                            <router-link to="/asset-finance" class="navbar-item" @click="businessMenuItemClick('/asset-finance')">
                                Asset Finance
                            </router-link>
                            <router-link to="/commercial-insurance" class="navbar-item" @click="businessMenuItemClick('/commercial-insurance')">
                                Commercial Insurance Premium Finance
                            </router-link>
                        </div>
                    </div>

                    <router-link to="/become-partner" class="navbar-item" @click="brandPartnerDropdownClick">
                        Become A Partner
                    </router-link>

                    <div class="navbar-item has-dropdown" :class="{'is-active': store.aboutMenu}">
                        <a class="navbar-link" @click="aboutDropdownClick">
                            About Us
                        </a>

                        <div class="navbar-dropdown is-right">
                            <router-link to="/about" class="navbar-item" @click="aboutMenuItemClick('/about')">
                                About WebBank
                            </router-link>
                            <router-link to="/our-people" class="navbar-item" @click="aboutMenuItemClick('/our-people')">
                                Leadership Team
                            </router-link>
                            <router-link to="/working-at-webbank" class="navbar-item" @click="aboutMenuItemClick('/working-at-webbank')">
                                Working at WebBank
                            </router-link>
                            <router-link to="/contact-us" class="navbar-item" @click="aboutMenuItemClick('/contact-us')">
                                Contact Us
                            </router-link>
                            <router-link to="/newsroom" class="navbar-item" @click="aboutMenuItemClick('/newsroom')">
                                Newsroom
                            </router-link>
                            <a href="#" @click="clickOutWarning('https://banks.data.fdic.gov/bankfind-suite/FinancialReporting/details/34404?establishedEndRange=4%2F3%2F2023&establishedStartRange=01%2F01%2F1792&inactiveEndRange=4%2F3%2F2023&inactiveStartRange=01%2F01%2F1970&incomeBasis=YTD&institutionType=banks&limitEstablishedDate=false&limitInactiveDate=false&reportPeriod=20221231&unitType=%24')" class="navbar-item">
                                Financial Statement Link
                            </a>
                            <router-link to="/privacy" class="navbar-item" @click="aboutMenuItemClick('/privacy')">
                                Privacy
                            </router-link>
                            <router-link to="/business-associations" class="navbar-item" @click="aboutMenuItemClick('/business-associations')">
                                Business Associations and Memberships
                            </router-link>
                        </div>
                    </div>

                    <div class="navbar-item" v-if="show_login && bank_link">
                        <div class="buttons">
                            <a :href="bank_link" target="_blank" class=" button is-primary" @click="hideAllMenus">
                                <strong>Online Banking</strong>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br>
            <div class="field is-grouped">
                <div class="control">
                    <button class="button is-link" @click="doClickout">Proceed</button>
                </div>
                <div class="control">
                    <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
                </div>
            </div>
      </div>
  </div>
</div>

    </nav>
</template>

<script>
import { useNavbarStore } from '@/stores/navbar';
import { storeToRefs } from "pinia";
export default {
    setup() {
        const store = useNavbarStore();
        const navbar = storeToRefs(store);
        return { store, navbar };
    },

    data: function () {
        return {
            // bank_link: 'https://www.netteller.com/webbank',
            bank_link: 'https://my.webbank.com/',
            // bank_link: false,
            clickout_modal: false,
            clickout_url: null,
            mobile_menu: false,
            personal_menu: false,
            business_menu: false,
            about_menu: false,
            bizpartner_menu: false,
        }
    },

    computed: {
        show_login() {
            return ['DepositProductsPage', 'TimeDepositsPage', 'SavingsPage', 'BankingSecurityPage', 'FormsPage', 'RatesPage', 'HomePage'].includes(this.$route.name);
        }
    },

    mounted() {
        this.bizpartner_menu = this.navbar.bizpartnerMenu;
        this.mobile_menu = this.navbar.mobileMenu;
        this.personal_menu = this.navbar.personalMenu;
        this.business_menu = this.navbar.businessMenu;
        this.about_menu = this.navbar.aboutMenu;
    },

    methods: {
        brandPartnerDropdownClick: function () {
            this.store.hideAboutMenu();
            this.store.hideBusinessMenu();
            this.store.hidePersonalMenu();
            this.store.hideMobileMenu();
        },

        personalDropdownClick: function () {
            console.log('user clicked a dropdown!');
            this.store.hideAboutMenu();
            this.store.hideBusinessMenu();
            this.store.togglePersonalMenu();
        },

        businessDropdownClick: function () {
            console.log('user clicked a dropdown!');
            this.store.hideAboutMenu();
            this.store.hidePersonalMenu();
            this.store.toggleBusinessMenu();
        },

        aboutDropdownClick: function () {
            console.log('user clicked a dropdown!');
            this.store.hideBusinessMenu();
            this.store.hidePersonalMenu();
            this.store.toggleAboutMenu();
        },

        personalMenuItemClick: function (to) {
            console.log('user clicked personal menu item: ' + to);
            this.store.hidePersonalMenu();
            this.store.hideMobileMenu();
            this.$router.push(to);
        },

        businessMenuItemClick: function (to) {
            console.log('user clicked business menu item: ' + to);
            this.store.hideBusinessMenu();
            this.store.hideMobileMenu();
            this.$router.push(to);
        },

        aboutMenuItemClick: function (to) {
            console.log('user clicked about menu item: ' + to);
            this.store.hideAboutMenu();
            this.store.hideMobileMenu();
            this.$router.push(to);
        },

        clickLogo: function () {
            this.hideAllMenus();
        },

        hideAllMenus: function () {
            this.store.hidePersonalMenu();
            this.store.hideBusinessMenu();
            this.store.hideAboutMenu();
            this.store.hideMobileMenu();
        },

        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },

        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },

        closeAllPartnerModals: function () {
            this.partner_modal = false;
        },

    }
};
</script>

<style scoped lang="scss">
.navbar .navbar-brand {
  padding: 0;
}
</style>
