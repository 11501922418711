<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr7-hero.png" alt="WebBank and iCreditWorks">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr7-small.png" alt="WebBank and iCreditWorks">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">iCreditWorks Announces the Addition of the TrueZERO% Installment Loan to Its Broad Point-Of-Sale Product Suite</h2>
            <h4 class="news-page-title">The TrueZERO% installment loan offered through the iCreditWorks<sup>*</sup> Platform expands its broad offering of loan products to include a 12-month, true 0% APR program.</h4>
            <br>
            <h4 class="news-date">April 13th, 2022</h4>
            <br>
            <p><a href="#" @click="clickOutWarning('https://www.icreditworks.com/')">iCreditWorks</a>, one of the fastest-growing FinTech
                platforms in the United States, announced today the addition of TrueZERO% to its broad Point-Of-Sale suite of financing
                products. This installment loan product has a 0% annual interest rate; the principal loan balance is divided into 12 monthly
                installment payments. The TrueZERO% installment loan product is available to qualified applicants through the iCreditWorks
                native mobile App and in-office iCreditWorksPRO Tablet.</p>
            <br>
            <p>"Today's consumers demand simple, responsible, and transparent loan products," says Stephen E. Sweeney, Chairman and
                Founder of iCreditWorks. "Many deferred interest products are misunderstood by consumers and we're determined to provide
                a better financing experience. The TrueZERO% is a transparent, 0% interest installment product that sets the new standard
                in consumer lending."</p>
            <br>
            <p>Providing consumers with a clear path to repayment, the TrueZERO% installment loan is unique in a marketplace where deferred
                interest loan products and revolvers are prevalent. By selecting a TrueZERO% installment loan, a consumer's principal loan
                balance is simply divided into 12 equal monthly installment payments with no interest accrual.</p>
            <br>
            <p>"TrueZERO% represents the continued expansion of Point-Of-Sale products offered through the iCreditWorks Platform," says
                Cristian Mandachescu, Chief Risk Officer. "We are committed to empowering consumers to say 'YES' to their treatment,
                offering access to a suite of installment loan products that helps each find an ideal, affordable financing option."</p>
            <br>
            <p>The TrueZERO% installment loan product will be offered through all healthcare providers participating in the iCreditWorks
                Credit Program. TrueZERO% represents the newest addition to the loan products offered through iCreditWorks, which also
                includes Standard Installment Loans and "No-Interest" Incentive Loans up to $20,000. iCreditWorks believes in responsible
                lending and only offering access to affordable financing options that allow consumers to pay for the care they deserve.</p>
            <br>
            <div class="news-footnote">
                <p><sup>*</sup> All loans issued by WebBank, Member FDIC.</p>
            </div>
            <br>
            <p class="news-section-title">About iCreditWorks</p>
            <p>iCreditWorks leverages innovative technology and mobility to deliver an omnichannel platform experience that provides access
                to a broad suite of "Point-Of-Sale" (POS) financing products, empowering consumers to take control of their financing. The
                first-of-its-kind iCreditWorks native mobile App delivers a simple, seamless, and secure financing experience that redefines
                the consumer journey.</p>
            <br>
            <p>To learn more about the Company, please visit <a href="#" @click="clickOutWarning('https://www.icreditworks.com/')">www.icreditworks.com</a>.</p>
            <br>
            <p class="news-section-title">iCreditWorks</p>
            <p>Rich Groves<br>
                SVP - Professional Affairs<br>
                (201) 450-4268<br>
                rich.groves@icreditworks.com</p>
            <br>
                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "iCreditWorks TrueZERO% Installment Loan - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
