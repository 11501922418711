<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-lloyd.png" class="is-rounded" alt="Jason Lloyd">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Jason Lloyd</h2>
            <h3 class="people-title">President &amp; CEO</h3>
            <p class="people-bio">
Jason is the President &amp; CEO of WebBank and responsible for the overall strategic and operational performance of the Bank.
He leads the Bank's vision and strategy, and is accountable for the overall direction and administration of programs, products,
and services provided by the Bank, including the Bank's financial performance, credit quality, business development, operations,
regulatory compliance, and risk management. Jason has over 25 years of banking and financial services industry experience with
companies such as CIT Group (CIT Bank) and Mitsubishi Corporation. He joined WebBank in 2008 and was responsible for the build
out and growth of the Bank's Strategic Partnership business unit. Prior to his role as President &amp; CEO, Jason led the strategy,
business development and revenue production side of the Bank as EVP of Strategy &amp; Business Development. Jason has extensive
experience in new business and strategic development, strategic and tactical business planning, deal structure and implementation,
major account development and management, strategic alliances and partnerships, and pricing strategies and product introduction.
    Jason received a B.A. from Gettysburg College.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
