<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-peterson.png" class="is-rounded" alt="James Peterson">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">James Peterson</h2>
            <h3 class="people-title">SVP Chief Credit Officer</h3>
            <p class="people-bio">
James has over 19 years of experience in various risk management roles within the financial services industry, with a focus in credit risk management.  James' executive responsibilities include leading credit risk oversight of the Bank's strategic partner programs, counterparty risk management, credit administration, model risk management, and analytics and reporting.  Prior to joining the Bank in 2015, James worked in the Auto Finance space with TD Bank, Chrysler Financial, and DaimlerChrysler Financial, most recently serving as the Vice President of Credit Strategies at TD Auto Finance. Mr. Petersen has extensive experience in banking, Fintech, strategic partner relationships, and the management of credit risk for a variety of consumer and commercial loan products. James received a B.S. in Finance from Weber State University and an M.B.A.
    from Thunderbird School of Global Management.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
