<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr44_hero.png" alt="BEST PLACES TO WORK IN FINANCIAL TECHNOLOGY 2024">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr44_small.png" alt="BEST PLACES TO WORK IN FINANCIAL TECHNOLOGY 2024">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">BMG Money and WebBank Join Forces to Expand Financial Inclusion Nationwide</h2>
                        <br>
                        <p><em><small>August 6, 2024</small></em></p>
                        <br>
                        <p><a @click="clickOutWarning('https://www.bmgmoney.com');">BMG Money</a> and <a href="https://webbank.com">WebBank</a> announces its strategic partnership, to provide access to significantly lower cost loan solutions to underserved communities across the United States.</p>
                        <p>This partnership marks a pivotal step toward enhancing financial inclusion for employees and retirees with limited access to socially responsible loan programs. WebBank has originated and funded over $195 billion in consumer and commercial credit products since its inception, helping millions of people and small businesses obtain affordable credit products.</p>
                        <p>BMG Money, an established financial institution trusted by more than 100 employers and local, county and state government bodies, empowers consumers by providing access to a suite of financial wellness solutions including employment-based loans, credit monitoring, and credit education programs. Committed to the public good and raising the underserved to be part of the mainstream American economy, BMG Money has offered access to flexible loan options totaling to more than $3.9 Billion.</p>
                        <p>The newly introduced LoansAtWork, issued by WebBank, makes flexible, employment-based loan options available to customers irrespective of their credit scores, which sometimes may fail to accurately assess a person’s credit worthiness. Loans typically range from $500 – $10,000 and are deposited directly into customers’ bank accounts. The repayments are scheduled around the customer’s pay schedule and are collected automatically to avoid customer missed payments. Payment amounts are designed to be affordable and manageable, with no prepayment penalties. Most significantly, to help keep customers on sound financial footing in the future, the loan performance will be reported to the credit bureaus.</p>
                        <p>Kieran Noonan, President & CEO at BMG Money, expressed enthusiasm about the partnership. “As BMG Money strives to level the playing field for the financially underserved, our alignment with WebBank’s incredible team extends holistic services to more customers in need,” he said. “This partnership offers tremendous value for thousands of individuals, and we look forward to the nationwide impact it will have.”</p>
                        <p>Experian estimates that nearly 106 million U.S. adults do not have access to credit, either because of a low score or no score. Meanwhile, nearly half of Americans (47%) say 2024 has been the most stressful year of their lives financially. This has a tremendous impact in the workplace. Morgan Stanley reported that one in five employees admits that productivity at work has been impacted by financial worries and 49% say they spend three or more hours each week distracted by financial issues.</p>
                        <p>WebBank, a trailblazer with 25 years of experience in partnering with innovative fintechs and fostering financial inclusion, shares BMG Money’s commitment to aiding the underserved and solving this challenge. Through this collaboration, BMG Money aims not only to increase access to financial services and affordable financing but also to break down stigmas associated with financial emergencies and raise awareness of responsible credit options.</p>
                        <p>Jason Lloyd, President & CEO of WebBank, said, “WebBank’s partnership with BMG Money is a testament to our commitment to support underserved individuals and communities. We believe that everyone deserves access to financial services and the opportunity to achieve economic stability.”</p>
                        <p>For more information on LoansAtWork, visit <a @click="clickOutWarning('https://www.bmgmoney.com/loansatwork');">www.bmgmoney.com/loansatwork</a>.</p>
                        <br>
                        <p><strong>About BMG Money</strong></p>
                        <p>Headquartered in Miami, FL, BMG Money has been safeguarding employees and retirees from predatory loans since 2011 and currently operates in 42 states plus the District of Columbia. BMG Money's mission is to enhance the financial wellness of underserved borrowers through a combination of financial education, credit monitoring solutions, and emergency loans, providing the tools necessary for a secure financial future. For more information, please visit <a @click="clickOutWarning('https://www.bmgmoney.com');">www.bmgmoney.com</a>.</p>
                        <br>
                        <p><strong>About WebBank</strong></p>
                        <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its inception in 1997, WebBank has originated and funded over $195 billion in consumer and commercial credit products. As “The Bank Behind the Brand®”, WebBank is a national issuer of consumer and small business credit products through Strategic Partner (Brand) platforms, which include retailers, manufacturers, finance companies, software as a service (SaaS) and financial technology (FinTech) companies. The Bank is a leading player in the digital lending space, driving innovation in financial products through embedded finance with Strategic Partner platforms. WebBank engages in a full range of banking activities including consumer and commercial loan products, revolving lines of credit, credit cards, private-label card issuance, auto-refinancing and more. The Bank provides capital in the form of asset-based lending and other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a targeted focus on specialty finance assets. The Bank is also a leading provider of commercial insurance premium finance products through its wholly owned subsidiary National Partners. For more information, please visit <a href="https://webbank.com">webbank.com</a>.</p>
                        <br>
                    </div>

                    <div class=" modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website
                                    and does not represent either the linked website or you, should you enter into a
                                    transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked
                                    site. We encourage you to review their privacy and security policies which may differ
                                    from WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "ADDITION OF MALIN ERIKSSON TOS WEBBANK BOARD OF DIRECTORS";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
