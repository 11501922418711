<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr38_hero.png" alt="Erik Cannon named SVP – Chief Operations Officer">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr38_small.png" alt="Erik Cannon named SVP – Chief Operations Officer">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">WebBank Announces Erik Cannon named SVP – Chief Operations Officer
                        </h2>
                        <br>
                        <p>SALT LAKE CITY – WebBank is pleased to announce the appointment of Erik Cannon as the Senior
                            Vice President – Chief Operations Officer.</p>
                        <br>
                        <p>Erik joined WebBank in September 2016 as the Vice President of Due Diligence and
                            Implementation bringing many years of risk management, business development, operations,
                            relationship management and leadership experience. During his tenure at the Bank, Erik has
                            held positions with increasing levels of responsibility most recently as Senior Vice
                            President of Due Diligence and Implementation and General Manager of the Bank’s Insurance
                            Premium Finance subsidiary, National Partners.</p>
                        <br>
                        <p>In his new role, Erik will continue to be responsible for Due Diligence & Implementation and
                            National Partners. In addition, Erik will assume leadership responsibility for the Bank’s
                            Strategic Partners Oversight (“SP Oversight”) department. Jim Jackson will continue in his
                            role as Senior Vice President – SP Oversight and will report to Erik. Under Erik’s
                            leadership the SP Oversight team will focus on developing efficiencies within the
                            organization which includes even closer collaboration between implementing and managing
                            Strategic Partner programs and products.</p>
                        <br>
                        <p>“During his time at the Bank, Erik has proven to be an invaluable member of the executive
                            team by consistently demonstrating his exceptional leadership, focus on process improvements
                            and his unwavering commitment to excellence,” said Jason Lloyd, President & CEO. “In Erik’s
                            new role, I’m confident that he will drive operational efficiency and innovation and I look
                            forward to witnessing his continued success.”</p>
                        <br>
                        <br>
                        <p><strong>About WebBank</strong></p>
                        <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its
                            inception in 1997, WebBank has originated and funded over $185 billion in consumer and
                            commercial credit products. As “The Bank Behind the Brand®”, WebBank is a national issuer of
                            consumer and small business credit products through Strategic Partner (Brand) platforms,
                            which include retailers, manufacturers, finance companies, software as a service (SaaS) and
                            financial technology (FinTech) companies. The Bank is a leading player in the digital
                            lending space, driving innovation in financial products through embedded finance with
                            Strategic Partner platforms. WebBank engages in a full range of banking activities including
                            consumer and commercial loan products, revolving lines of credit, credit cards,
                            private-label card issuance, auto-refinancing and more. The Bank provides capital in the
                            form of asset-based lending and other credit facilities to Strategic Partner platforms,
                            credit funds, and other lenders with a targeted focus on specialty finance assets. The Bank
                            is also a leading provider of commercial insurance premium finance products through its
                            wholly owned subsidiary National Partners. For more information, please visit
                            www.webbank.com.</p>
                        <br>
                        <p><strong>Contact:</strong></p>
                        <p>Shannon Hutchings<br>
                            <a href="mailto:shannon.hutchings@webbank.com">shannon.hutchings@webbank.com</a>
                        </p>
                    </div>

                    <div class="modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website and does
                                    not
                                    represent either the linked website or you, should you enter into a transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked site. We
                                    encourage you to review their privacy and security policies which may differ from
                                    WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "Erik Cannon named SVP – Chief Operations Officer";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
