<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr6-hero.png" alt="WebBank and Gemini">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr6-small.png" alt="WebBank and Gemini">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">The Gemini Crypto Rewards Credit Card is Now Available in the United States</h2>
            <h4 class="news-page-title">The first instant<sup>*</sup> crypto rewards credit card provides a seamless way for consumers to acquire up to 3%+ back from 60+ cryptocurrencies supported on Gemini</h4>
            <br>
            <h4 class="news-date">April 14th, 2022</h4>
            <br>
            <p><a href="#" @click="clickOutWarning('https://www.gemini.com')">Gemini</a>, a cryptocurrency platform to buy, sell, earn, and store crypto, today announced the
                <a href="#" @click="clickOutWarning('https://www.gemini.com/credit-card')">Gemini Credit Card</a><sup>&trade;</sup>
                is now available in all 50 U.S. states. Cardholders can earn upto 3% crypto back on dining<sup>+</sup>, 2% crypto back on groceries,
                and 1% crypto back on all other purchases, with rewards automatically deposited into their Gemini account. The Gemini
                Credit Card is issued by WebBank and features Mastercard as the exclusive card network.</p>
            <br>
            <p>Since launching the waitlist, the Gemini Credit Card has amassed more than 500,000 sign-ups. Cardholders will be able to
                use the Gemini Credit Card anywhere Mastercard is accepted and can choose from among 60+ types of cryptocurrencies currently
                supported for rewards on Gemini's exchange platform, including bitcoin, ether, dogecoin and other tokens.</p>
            <br>
            <p>When a cardholder makes a purchase, Gemini automatically converts the USD value of the reward into the selected cryptocurrency
                and deposits it into the cardholder's Gemini account. This process happens as soon as the transaction is approved for most
                merchant categories<sup>*</sup>. Cardholders can change their selected crypto reward as often as they like which allows
                them to earn a wide variety of cryptocurrencies over the course of each month.</p>
            <br>
            <p class="news-section-title">Additional features of the Gemini Credit Card include:</p>
            <ul>
                <li><strong>No annual fees:</strong> The Gemini Credit Card has no annual fee and no foreign transaction fees<sup>1</sup>.
                    There are no exchange fees to receive crypto rewards<sup>2</sup>.</li>
                <li><strong>Instant access:</strong> After approval, customers can instantly access a digital version of their Gemini Credit
                    Card on the Gemini mobile or web application. In addition, consumers can add the card to their mobile wallet and begin
                    making purchases online, in-app, and at the point of sale.</li>
                <li><strong>Security-first design:</strong> Sensitive information, such as the 16-digit card number, is removed from the
                    physical card and only accessible to cardholders via the Gemini mobile or web application.</li>
                <li><strong>Stainless steel:</strong> The Gemini Credit Card's sleek, stainless steel card is made from 75% recycled material
                    and is available in three color options including silver, rose gold, and black.</li>
                <li><strong>World Mastercard<sup>&reg;</sup> Benefits:</strong> Customers can receive access to exclusive offers with select
                    merchants such as DoorDash, HelloFresh, Lyft and ShopRunner, as well as Mastercard's
                    <a href="#" @click="clickOutWarning('https://www.priceless.com/')">Priceless<sup>&reg;</sup> Experiences</a>.
                    The Gemini Credit Card will include advanced security features including Mastercard ID Theft Protection<sup>&trade;</sup>,
                    Zero Liability and Price Protection.</li>
                <li><strong>24/7 live customer support</strong></li>
            </ul>
            <br>

            <p>"Mastercard and Gemini share in the belief that providing relevant and innovative crypto rewards experiences will not only
                empower consumers, but also unlock access to the digital currencies ecosystem," said <strong>Sherri Haymond, executive vice president,
                Digital Partnerships at Mastercard</strong>. "We're honored to work hand in hand with Gemini to deliver this one-of-a-kind rewards
                offering and make it even easier for consumers to experience crypto."</p>
            <br>
            <p>"WebBank is thrilled to leverage its expertise in financial product innovation through its partnership with Gemini and Mastercard
                to launch the leading-edge Gemini Credit Card that provides consumers with an opportunity to earn real-time crypto rewards that
                reside on Gemini's trusted crypto platform," said <strong>Jason Lloyd, President &amp; CEO of WebBank</strong>.</p>
            <br>
            <p>"Last year, the crypto industry had its breakout moment with 44% of crypto owners in the U.S.<sup>3</sup> reported first
                buying crypto in the last year. Gemini is committed to helping drive more education and offer innovations that remove
                barriers of entry for consumers who want access to crypto such as bitcoin," said <strong>Pravjit Tiwana, CTO of Gemini</strong>. "In partnership
                with Mastercard and WebBank, we developed the Gemini Credit Card to offer a simplified way to invest in crypto without asking
                consumers to change their daily behavior. With the Gemini Credit Card, users also have access to a single trusted platform
                to buy, sell, store, and earn real interest in crypto, and we couldn't be more excited to make it widely available across
                the United States."</p>
            <br>
            <p>For customers based inthe United Statesand interested in applying for the Gemini Credit Card, visit:
                <a href="#" @click="clickOutWarning('https://www.gemini.com/credit-card')">https://www.gemini.com/credit-card</a>.</p>
            <br>
            <p class="news-section-title">Additional Resources:</p>
            <ul>
                <li><strong>Report:</strong> <a href="#" @click="clickOutWarning('https://www.gemini.com/state-of-us-crypto')">Gemini State of Crypto</a></li>
                <li><strong>FAQ:</strong> <a href="#" @click="clickOutWarning('https://support.gemini.com/hc/en-us/articles/4405135674779-How-do-I-earn-credit-card-crypto-rewards-')">How do I earn credit card crypto rewards?</a></li>
            </ul>
            <br>
            <p class="news-section-title">About Gemini</p>
            <p>Gemini is a platform that allows customers to buy, sell, store, and earn cryptocurrencies like bitcoin, ether, and DeFi tokens.
                Gemini's simple, innovative, and secure products are built to empower the individual. Gemini was founded in 2014 by twin
                brothers Cameron and Tyler Winklevoss.</p>
            <br>
            <p class="news-section-title">Contacts</p>
            <p>Raquel Prieto<br>
                press@gemini.com</p>
            <br>
            <div class="news-footnote">
                <p><sup>*</sup> Some exclusions apply in which rewards are deposited when the transaction posts</p>
                <p><sup>+</sup> 3% back on dining (up to$6,000in annual spend, then 1%)</p>
                <p><sup>1</sup> Variable APRs: Purchase 13.24% - 24.24%, Cash advance 26.24%, Penalty 30.24%. Cash advance fee $10 or 3% whichever is greater. (As of April 14, 2022)</p>
                <p><sup>2</sup> Fees may be incurred for selling or converting your crypto rewards</p>
                <p><sup>3</sup> 2022 Gemini State of Crypto Report</p>
            </div>
            <br>
                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "The Gemini Crypto Rewards Credit Card - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
