<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr35_hero.png" alt="Glassdoor Best Place to Work">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr35_small.png" alt="Glassdoor Best Place to Work">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">WEBBANK, A STEEL PARTNERS COMPANY, HONORED AS ONE OF THE BEST PLACES TO WORK IN 2024</h2>
                        <h3 class="news-page-subtitle">A GLASSDOOR EMPLOYEES' CHOICE AWARD WINNER</h3>
                        <br>
                            <p><em><small>January 29th, 2024</small></em></p>
                            <br>
                        <p>Salt Lake City, UT — WebBank, a wholly owned subsidiary of Steel Partners Holdings (NYSE:
    SPLP), has been named a winner of Glassdoor's 16th annual Employees' Choice Awards, honoring
    the <a @click="clickOutWarning('https://www.glassdoor.com/Award/Best-Places-to-Work-LST_KQ0,19.htm');">Best Places to Work 2024</a>. This is the second year in a row WebBank has been named a winner,
    and this year, it is ranked second on the list of 50 small to medium sized businesses that received
    this distinction. Unlike other workplace awards, the Glassdoor Employees' Choice Awards are based
    on the input of employees who voluntarily provide anonymous feedback on Glassdoor by completing
    a <a @click="clickOutWarning('https://www.glassdoor.com/mz-survey/start_input.htm?cr=&c=PR&showSurvey=Reviews');">company review</a> about their job, work environment and employer over the past year.</p>
                        <br>
                        <p>“Receiving Glassdoor’s Best Place to Work award is a testament to WebBank’s unwavering
    commitment to fostering a culture where every individual is valued,” said Jason Lloyd, WebBank
    President &amp; CEO. “This accolade is a reflection of our team’s passion and dedication to creating an
    inclusive, supportive environment where everyone can thrive.”</p>
                        <br>
                        <p>“I am incredibly proud of the WebBank team for the work they have done to receive this award,” said
    Warren Lichtenstein, Executive Chairman of Steel Partners Holdings. “This recognition is a reflection
    not only of the team’s accomplishments but also of our shared values of Teamwork, Respect,
    Integrity and Commitment.”</p>
                        <br>
                        <p>Some of the anonymous reviews WebBank employees provided on Glassdoor.com include:
                            <ul>
    <li>"This company cares about their people and has strong people and brand, and HR support
    to drive change and promote a positive culture of inclusion and belonging. Overall, this has
    been the best company I have worked for."</li>
    <li>"The culture at WebBank is great. The senior management team goes to great lengths to
    support the staff and employees at the Bank."</li>
    <li>Amazing culture throughout the bank across all levels, and a constant focus for the
    leadership team and CEO to continue improving further."</li>
                            </ul>
                        </p>
                        <br>
                        <p>“Work life is rapidly changing, and professionals are turning to Glassdoor to share their experiences
    about working for some of the most competitive employers in the world,” said Christian Sutherland-
    Wong, Glassdoor Chief Executive Officer. “The Best Places to Work award is a stark reminder of the
    power of Glassdoor, from arming professionals with career insights and real-time conversations
    about all things work and life to providing employers with feedback to improve. Glassdoor has
    evolved to make sure that transparency isn’t a fad, but rather, a foundational component in the future
    of work.”</p>
                        <br>
                        <p>When employees <a @click="clickOutWarning('https://www.glassdoor.com/mz-survey/start_input.htm?cr=&c=PR&showSurvey=Reviews');">submit reviews</a>
                            about their employer on Glassdoor, they are asked to share their
    opinions on some of the best reasons to work for their employer (pros), any downsides (cons) and
    are encouraged to provide advice to management. In addition, employees are asked to rate how
    satisfied they are with their employer overall and rate their CEO and key workplace attributes,
    including career opportunities, compensation and benefits, culture and values, diversity and
    inclusion, senior management, and work-life balance. Employees are also asked whether they would
    recommend their employer to a friend and whether they believe their employer’s six-month business
    outlook is positive, negative or if they have no opinion.</p>
                        <br>
                        <p>Employees’ Choice Award winners for the Best Places to Work 2024 are determined using
    Glassdoor’s proprietary awards algorithm, and each employer’s rating is determined based on the
    quantity, quality and consistency of Glassdoor-approved company reviews submitted by U.S.-based
    employees between October 18, 2022 and October 16, 2023. To be considered for the U.S. small
    and medium company list, a company must have had fewer than 1,000 employees and received at
    least 30 ratings across each of Glassdoor’s nine workplace attributes (overall company rating, career
    opportunities, compensation and benefits, culture and values, diversity and inclusion, senior
    management, work-life balance, recommend to a friend and six-month business outlook).</p>
                            <br>
                            <p>For the complete list of the Glassdoor Best Places to Work winners in 2024, please visit:
                            <a @click="clickOutWarning('https://www.glassdoor.com/Award/Best-Places-to-Work-LST_KQ0,19.htm');">gldr.co/BPTWSMB</a>.</p>
                            <br>
                            <br>
                                <p><strong>About WebBank</strong></p>
                        <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its
    inception in 1997, WebBank has originated and funded over $185 billion in consumer and
    commercial credit products. As “The Bank Behind the Brand<sup>&reg;</sup>”, WebBank is a national issuer of
    consumer and small business credit products through Strategic Partner (Brand) platforms, which
    include retailers, manufacturers, finance companies, software as a service (SaaS) and financial
    technology (FinTech) companies. The Bank is a leading player in the digital lending space, driving
    innovation in financial products through embedded finance with Strategic Partner platforms.
    WebBank engages in a full range of banking activities including consumer and commercial loan
    products, revolving lines of credit, credit cards, private-label card issuance, auto-refinancing and
    more. The Bank provides capital in the form of asset-based lending and other credit facilities to
    Strategic Partner platforms, credit funds, and other lenders with a targeted focus on specialty finance
    assets. The Bank is also a leading provider of commercial insurance premium finance products
    through its wholly owned subsidiary National Partners. For more information, please visit
    <a href="https://webbank.com">www.webbank.com</a>.</p>
                        <br>
                    <p><strong>Media Contact:</strong></p>
                    <p>Shannon Hutchings<br>
                    <a href="mailto:shannon.hutchings@webbank.com">shannon.hutchings@webbank.com</a>
                    </p>
                    <br>
                    <br>
                    <p><strong>About Steel Partners Holdings L.P.</strong></p>
                    <p>Steel Partners Holdings L.P. (<a @click="clickOutWarning('https://www.steelpartners.com');">www.steelpartners.com</a>) is a diversified global holding company that
    owns and operates businesses and has significant interests in leading companies in various
    industries, including diversified industrial products, energy, defense, supply chain management and
    logistics, banking, and youth sports.</p>
                        <br>
                        <p><strong>Investor Contact:</strong></p>
                        <p>Jennifer Golembeske<br>
                            212-520-2300<br>
                        <a href="mailto:jgolembeske@steelpartners.com">jgolembeske@steelpartners.com</a>
                        </p>
                        <br>
                        <p><strong>About Glassdoor</strong></p>
                        <p><a @click="clickOutWarning('https://www.glassdoor.com');">Glassdoor</a> is revolutionizing how people everywhere find jobs and companies they love by providing
    deeper workplace transparency. Professionals turn to Glassdoor to research ratings, reviews,
    salaries and more at millions of employers, and to <a @click="clickOutWarning('https://www.fishbowlapp.com');">Fishbowl</a> by Glassdoor to engage in candid
    workplace conversations. Companies use Glassdoor to post jobs and attract talent through <a @click="clickOutWarning('https://www.glassdoor.com/employers/solutions/employer-branding/');">employer
    branding and employee insights products</a>. Glassdoor is a subsidiary of Recruit Holdings, a leading
    global technology company, and a part of its fast-growing HR Technology business unit. For more
    information, visit <a @click="clickOutWarning('https://www.glassdoor.com');">www.glassdoor.com</a>.</p>
                            <p>&quot;Glassdoor&quot; and logo are proprietary trademarks of Glassdoor, Inc.</p>
                            <p><strong>Media Contact: </strong> pr@glassdoor.com</p>
                    </div>

                    <div class="modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website and does not
                                    represent either the linked website or you, should you enter into a transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked site. We
                                    encourage you to review their privacy and security policies which may differ from
                                    WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "Collective Liquidity and Aliya Capital - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
