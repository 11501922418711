<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr3-hero.png" alt="WebBank and iCreditWorks">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr3-small.png" alt="WebBank and iCreditWorks">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">WebBank Makes a Strategic Investment in FinTech Platform iCreditWorks</h2>
            <br>
            <h4 class="news-page-subtitle">WebBank, a Utah-chartered industrial bank, makes a strategic investment
                in iCreditWorks at a $200 million valuation to support the Platform's continued commercial growth</h4>
            <br>
            <h4 class="news-date">June 13th, 2022</h4>
            <br>
            <p><a href="#" @click="clickOutWarning('https://www.icreditworks.com/')">iCreditWorks</a>, one of the fastest-growing FinTech platforms in the United States, announced today it has
                accepted a strategic investment from WebBank at a $200M valuation. The investment will allow iCreditWorks
                to accelerate the commercial growth of its omni-channel platform, which features the first-in-market,
                native mobile App within the "Point-Of-Sale" (POS) sector.</p>
            <br>
            <p>"We continue to expand our relationship with WebBank to ensure our alignment and to fuel our mission to reimagine
                POS financing," said Stephen E. Sweeney, Chairman and Founder of iCreditWorks. "WebBank's support of our vision is
                testimony to the iCreditWorks omni-channel platform offering and its unique position in the marketplace."</p>
            <br>
            <p>iCreditWorks believes in responsible lending and only offering access to affordable financing options<sup>*</sup> that
                allow consumers to receive the care they deserve.</p>
            <br>
            <p>"We made this investment in iCreditWorks to support the vision and innovation of their Platform," said Jason Lloyd,
                President &amp; CEO of WebBank. "We are excited to expand and grow our relationship with iCreditWorks and look forward
                to our mutual success."</p>
            <br>
            <p>iCreditWorks, represented by Truist Financial, is in the midst of a capital raise at a $200 million valuation to fuel
                its continued growth in the dental sector and expansion into new verticals.</p>
            <br>
            <div class="news-footnote">
                <p><sup>*</sup> All loans issued by WebBank.</p>
            </div>
            <br>
            <br>
            <p class="news-section-title">About WebBank</p>
            <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its inception in 1997, WebBank
                has originated and funded over $150 billion in consumer and commercial credit products. As "The Bank Behind the Brand<sup>&reg;</sup>",
                WebBank is a national issuer of consumer and small business credit products through Strategic Partner (Brand) platforms,
                which include retailers, manufacturers, finance companies, software as a service (SaaS), and financial technology (FinTech)
                companies. The Bank is a leading player in the digital lending space, driving innovation in financial products through embedded
                finance with Strategic Partner platforms. WebBank engages in a full range of banking activities including consumer and commercial
                loan products, revolving lines of credit, credit cards, private-label card issuance, auto-refinancing and more. The Bank provides
                capital in the form of asset-backed lending and other credit facilities to Strategic Partner platforms, credit funds,and other
                lenders with a targeted focus on specialty finance assets. The Bank is also a leading provider of commercial insurance premium
                finance products through its wholly owned subsidiary National Partners. For more information,
                please visit <router-link to="/">www.webbank.com</router-link>.</p>

            <br>
            <p class="news-section-title">About iCreditWorks</p>
            <p>iCreditWorks leverages innovative technology and mobility to deliver an omnichannel platform experience that provides access
                to a broad suite of "Point-Of-Sale" (POS) financing products, empowering consumers to take control of their financing. The
                first-of-its-kind iCreditWorks native mobile App delivers a simple, seamless, and secure financing experience that redefines
                the consumer journey.</p>
            <br>
            <p>To learn more about the Company, please visit <a href="#" @click="clickOutWarning('https://www.icreditworks.com/')">www.icreditworks.com</a>.</p>
                        </div>
                </div>
            </div>
        </div>
    </section>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

</template>

<script>
export default {

    created() {
        document.title = "WebBank Investment in iCreditWorks - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
