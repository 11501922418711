<template>
    <section class="hero contact-hero is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-8 is-offset-4-desktop">
                            <div class="hero-box-right">
                                <p class="title hero-title">
                                    Contact Us
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </section>

    <section class="section" v-if="success">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2 has-text-centered">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <span class="icon has-text-success"><i class="fa-solid fa-badge-check fa-10x"></i></span>
                    <br>
                    <br>
                    <br>
                    <br>
                    <p class="has-text-success is-size-3">Thank you! Your message has been sent!</p>
                    <br>
                    <br>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </section>

    <section class="section" v-else>
        <div class="container">
            <Form @submit="submitForm" :validation-schema="yupSchema">
                <div class="has-text-centered">
                    <h3 class="is-size-1 has-text-primary section-title">How Can We Help?</h3>
                </div>
                <br>
                <br>

                <div class="columns">
                    <div class="column is-8 is-offset-2">

                        <div class="notification is-danger" v-if="captcha_error">
                            Captcha verification failed. Please try again.
                        </div>

                        <div class="notification is-danger" v-if="errors && errors.fields">
                            Please check your input and try again.
                        </div>

                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">First Name</label>
                                    <div class="control">
                                        <Field class="input" type="text" placeholder="John" name="first_name" />
                                    </div>
                                    <ErrorMessage name="first_name" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('first_name')"
                                        class="help has-text-danger">
                                        {{ errors.messages.first_name.join(', ') }}
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Last Name</label>
                                    <div class="control">
                                        <Field class="input" type="text" placeholder="Doe" name="last_name" />
                                    </div>
                                    <ErrorMessage name="last_name" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('last_name')"
                                        class="help has-text-danger">
                                        {{ errors.messages.last_name.join(', ') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Email</label>
                                    <div class="control has-icons-left has-icons-right">
                                        <Field class="input" type="email" name="email" placeholder="me@mycompany.com" />
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <ErrorMessage name="email" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('email')"
                                        class="help has-text-danger">
                                        {{ errors.messages.email.join(', ') }}
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Phone</label>
                                    <div class="control">
                                        <Field class="input" v-maska:[options] type="tel" name="phone"
                                            placeholder="xxx-xxx-xxxx" />
                                    </div>
                                    <ErrorMessage name="phone" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('phone')"
                                        class="help has-text-danger">
                                        {{ errors.messages.phone.join(', ') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Brand Partner</label>
                                    <div class="control">
                                        <Field class="input" type="text" name="brand_partner"
                                            placeholder="My Company" />
                                    </div>
                                    <ErrorMessage name="brand_partner" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('brand_partner')"
                                        class="help has-text-danger">
                                        {{ errors.messages.brand_partner.join(', ') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Message</label>
                            <div class="control">
                                <Field class="textarea" as="textarea" name="description"
                                    placeholder="Additional Details">
                                </Field>
                            </div>
                            <ErrorMessage name="description" class="help has-text-danger" />
                            <p v-if="errors && errors.fields && errors.fields.includes('description')"
                                class="help has-text-danger">
                                {{ errors.messages.description.join(', ') }}
                            </p>
                        </div>

                        <div class="field is-grouped">
                            <div class="control">
                                <button class="button is-primary" type="submit"
                                    :class="{'is-loading': sending}">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </section>

    <section class="hero map-hero is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-8 is-offset-4-desktop has-text-right">
                            <div class="hero-box-right">
                                <h3 class="title hero-title">Contact Us</h3>
                                <address class="is-size-3" v-html="address"></address>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </section>

    <div class="blue-bg is-hidden-widescreen">
        <img src="https://assets.webbank.com/382.png" alt="WebBank Map" style="margin-bottom: -10px;">
        <img src="https://assets.webbank.com/wbmap2.png" alt="WebBank Map">
        <section class="section">
            <div class="blue-bg container">
                <h2 class="title hero-title-mobile">Contact Us</h2>
                <address class="is-size-5" v-html="address"></address>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';
import { vMaska } from "maska"
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
    directives: { maska: vMaska },
    components: {
        Form,
        Field,
        ErrorMessage,
    },

    setup() {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const yupSchema = yup.object().shape({
            first_name: yup.string().required().min(3).max(100).label('First Name'),
            last_name: yup.string().required().min(3).max(100).label('Last Name'),
            email: yup.string().required().matches(emailRegex, 'Invalid email format').label('Email'),
            phone: yup.string().required().min(12).max(12).label('Phone'),
            brand_partner: yup.string().required().min(3).max(100).label('Brand Partner'),
            description: yup.string().required().min(3).max(1000).label('Message')
        });
        return { yupSchema };
    },

    data: function () {
        return {
            sending: false,
            success: false,
            errors: [],
            server_error: null,
            captcha_error: null,
            options: {
                mask: "###-###-####",
                eager: false
            },
            address: "WebBank<br>P.O. Box 757<br>Portsmouth, NH 03802"
        }
    },

    created() {
        document.title = "Contact Us  - WebBank";
    },

    methods: {
        submitForm: function (values) {
            this.sending = true;

            this.$recaptcha('contact_us_form')
                .then(token => {
                    // You can now use this token to verify the captcha response on your server
                    // console.log(token);
                    values['form_name'] = 'contact_us';
                    values['g-recaptcha-response'] = token;
                    console.log('posting values: ' + JSON.stringify(values));
                    axios.post(process.env.VUE_APP_MAIL_ENDPOINT, values)
                        .then(result => {
                            console.log('result: ' + JSON.stringify(result));
                            this.success = true;
                        }).catch(error => {
                            console.log('STATUS: ' + error.response.status);
                            if (error.response.status === 403) {
                                this.captcha_error = error.message;
                            } else {
                                // can I still reach the "body" of the response?
                                console.log('error.body: ' + JSON.stringify(error.response.data));
                                this.errors = this.processErrors(error.response.data);
                                // console.log('ERRORS: ' + JSON.stringify(this.errors));
                            }
                        }).finally(() => this.sending = false);
                });
        },
        processErrors: function (apiResponse) {
            const errors = {
                fields: Object.keys(apiResponse), // Array of field names with errors
                messages: apiResponse // Keep the original structure for message access
            };
            return errors;
        },

        validateEmail(value) {
            // if the field is empty
            if (!value) {
                return 'Email address is required';
            }

            // if the field is not a valid email
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return 'Please enter a valid email';
            }

            // All is good
            return true;
        },

        validatePhone(value) {
            // if the field is empty
            if (!value) {
                return 'Phone number is required';
            }

            // if the field is not a valid email
            const regex = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/i;
            if (!regex.test(value)) {
                return 'Please enter a valid 10-digit phone number';
            }

            // All is good
            return true;
        },
    }
}
</script>
