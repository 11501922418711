<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/paypal.svg" alt="PayPal Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
                <img src="https://assets.webbank.com/partners/paypal.svg" alt="PayPal Logo">
          </div>
            <br>
            <p>In partnership with PayPal, WebBank is the lender for PayPal Pay Monthly, a closed-end, consumer installment
                loan offered to eligible PayPal customers during their online checkout experience. To learn more about
                Pay Monthly, visit <a href="#" title="Your account at PayPal Pay Monthly" @click="$emit('link-click', 'https://www.paypal.com/us/digital-wallet/ways-to-pay/buy-now-pay-later')">PayPal Pay Later</a>.
                For questions about a current Pay Monthly loan, please call 844-629-9108 or visit the PayPal Help Center
                <a href="#" title="PayPal Help Center" @click="$emit('link-click', 'https://www.paypal.com/us/smarthelp/contact-us')">here</a>.</p>
            <br>
            <!-- <p><a href="#" title="PayPal Pay Monthly Privacy Policy" @click="$emit('link-click', 'https://www.paypal.com/myaccount/privacy/privacyhub')">PayPal Pay Monthly Privacy Policy</a></p> -->
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
