<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/icredit.svg" alt="iCredit Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/icredit.svg" alt="iCredit Logo">
          </div>
            <br>
            <p>Through the iCreditWorks platform, WebBank is the lender of closed-end consumer installment loans for the purpose of financing dental care procedures.
                If you have questions about your account, please call 877-388-4821 or you may contact iCreditWorks via email at <a href="mailto:info@icreditworks.com">info@icreditworks.com</a>.
                If you are interested in applying for a loan through iCreditWorks, click
                <a href="#" title="Apply at iCreditWorks" @click="$emit('link-click', 'https://www.icreditworks.com/')">here</a>.</p>
            <br>
            <p><a href="#" title="iCreditWorks Privacy Policy" @click="$emit('link-click', 'https://www.icreditworks.com/policies')">iCreditWorks Privacy Policy</a></p>
            <br>
            <p><a href="#" title="iCreditWorks Privacy Notice" @click="$emit('link-click', 'https://icw-website-content-uat.s3.amazonaws.com/icwmw_cdn/CONFIDENTIAL_icreditworks_Privacy_Policy_a3aa46607c.pdf')">iCreditWorks Privacy Notice</a></p>
            <br>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
