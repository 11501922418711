<template>
<div>

    <div class="box partner-box" @click="paypal_modal = true">
        <img src="https://assets.webbank.com/partners/paypal.svg" alt="PayPal Logo">
    </div>

<div class="modal" :class="{'is-active': paypal_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/paypal.svg" alt="PayPal Logo">
          </div>
            <br>
            <p>In partnership with PayPal, WebBank is the lender of PayPal Working Capital and PayPal
                Business Loans and Loan Builder loans. For questions about your PayPal Working Capital
                account please call 877-981-2163, or for questions about your PayPal Business Loan or
                Loan Builder account please call 1-800-347-5626. If you are interested in applying
                for a loan, click below.</p>
            <br>
            <p><a href="#" title="PayPal Working Capital" @click="$emit('link-click', 'https://www.paypal.com/workingcapital/')">PayPal Working Capital</a></p>
            <br>
            <p><a href="#" title="PayPal Business Loan" @click="$emit('link-click', 'https://www.paypal.com/us/webapps/mpp/paypal-business-loan')">PayPal Business Loan</a></p>
            <br>
            <p><a href="#" title="PayPal Loan Builder" @click="$emit('link-click', 'https://www.loanbuilder.com/lb/home')">PayPal Loan Builder</a></p>
            <br>
            <br>
            <p>In partnership with PayPal and Concerto Card Company, WebBank is the issuer and lender of
                the PayPal Business Cashback Mastercard<sup>&reg;</sup> credit card. For questions about
                your PayPal Business Cashback Mastercard please call 1-877-930-0422. To learn more or
                to apply for the PayPal Business Cashback Mastercard, visit
                <a href="#" title="PayPal Cashback Mastercard" @click="$emit('link-click', 'https://www.paypal.com/us/business/cards/cashback-card')">www.paypal.com/us/business/cards/cashback-card</a> for details.</p>
            <br>
            <br>
            <button class="button is-primary is-light" @click="paypal_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="paypal_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            paypal_modal: false,
        }
    }
}
</script>
