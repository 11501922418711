<template>
    <section class="hero wb-hero is-hidden-touch">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-8 is-offset-4-desktop has-text-right">
                            <div class="hero-box-right">
                                <div class="columns">
                                    <div class="column is-6 is-offset-6">
                                    <p class="title hero-title">
                                        The Bank<br>Behind The Brand
                                    </p>
                                    <p class="subtitle hero-subtitle">
                                        As the Bank Behind The Brand<sup>&reg;</sup>, we empower
                                        fintech partners to drive innovation and financial inclusion.
                                    </p>
                                    <br>
                                    <router-link to="/become-partner" class="button is-primary is-large" title="Partner with WebBank">Partner With Us</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>

    <div class="blue-bg is-hidden-desktop">
                        <img src="https://assets.webbank.com/wb-hero-mobile.png" alt="Welcome to WebBank!">

    <section class="hero wb-hero-mobile">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-8 is-offset-4-desktop">
                            <p class="title hero-title-mobile">
                                The Bank<br>Behind The Brand
                            </p>
                            <p class="subtitle hero-subtitle-mobile">
                                As the Bank Behind The Brand<sup>&reg;</sup>, we empower
                                fintech partners to drive innovation and financial inclusion.
                            </p>
                            <br>
                            <router-link to="/become-partner" class="button is-white" title="Partner with WebBank">Partner With Us</router-link>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </section>
    </div>

    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-5 is-offset-1">
                    <br>
                    <img src="https://assets.webbank.com/wb-home2.png" class="body-image" alt="WebBank Strategic Partnerships" />
                </div>
                <div class="column is-5">
                    <br>
                    <h3 class="is-size-1 has-text-primary section-title">Strong Partnerships.<br>Great Solutions.</h3>
                    <br>
                    <p>WebBank is a national issuer of consumer and small business credit products
                        through Strategic
                        Partner platforms, which include retailers, manufacturers, finance companies, software as a
                        service (“SaaS”) and financial technology (fintech) companies. We are a leading player in the
                        digital lending space, driving innovation in financial products through embedded finance with
                        Strategic Partner platforms. </p>
                </div>
            </div>
        </div>
    </section>
    <br>
    <div id="differentiators" class="blue-bg">
        <section class="section">
            <div class="container">
                <div class="has-text-centered">
                    <h3 class="has-text-white hero-title is-hidden-mobile">KEY DIFFERENTIATORS</h3>
                    <h3 class="is-size-3 has-text-white section-title is-hidden-desktop">KEY DIFFERENTIATORS</h3>
                </div>
                <br>
<div class="tile is-ancestor">
  <div class="tile is-vertical">
    <div class="tile">
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
            <br>
            <br>
            <span class="icon diff-icon"><i class="fa-light fa-hand-holding-hand fa-4x"></i></span>
            <br>
            <br>
            <h4 class="box-title">Collaborative</h4>
            <p class="is-size-5">We take a consultative approach that bridges innovation and regulatory requirements to drive product and funding solutions.</p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
            <br>
            <br>
            <span class="icon diff-icon"><i class="fa-light fa-seedling fa-4x"></i></span>
            <br>
            <br>
            <h4 class="box-title">Growth Oriented</h4>
            <p class="is-size-5">Our entrepreneurial spirit together with our
                                institutional knowledge power growth and scale
                                with partners that range from start-ups to Fortune
                                500 companies.</p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
            <br>
            <br>
            <span class="icon diff-icon"><i class="fa-light fa-lightbulb-on fa-4x"></i></span>
            <br>
            <br>
            <h4 class="box-title">Innovative</h4>
            <p class="is-size-5">We continuously partner with platforms to incubate
                                and launch new products through the digitization of
                                financial services.</p>
        </article>
      </div>
    </div>
  </div>
</div>
            </div>
        </section>
        <br><br>
    </div>


    <div id="extraordinary">
        <section class="section">
            <div class="container">
                <div class="columns">
                    <div class="column is-5 is-offset-1 has-text-right-desktop">
                        <h3 class="is-size-1 has-text-primary section-title">Extraordinary Brands Partner with WebBank.</h3>
                        <br>
                        <p>Our partners represent some of the most successful start-ups and some
                            of the most recognizable brands representing a diverse mix of businesses. We work closely
                            with each of our partners to develop solutions that expand access to capital and financial
                            products. </p>
                        <br>
                        <router-link to="/brand-partners" class="button is-primary is-large">See Our Partners</router-link>
                    </div>
                    <div class="column is-5">
                        <img src="https://assets.webbank.com/414.png" class="body-image" alt="WebBank Brand Partners" />
                    </div>
                </div>
            </div>
        </section>
    </div>

    <br>

    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-5 is-offset-1">
                    <img src="https://assets.webbank.com/R5_C1557.png" class="body-image" alt="WebBank Specialty Finance" />
                </div>
                <div class="column is-5">
                    <h3 class="is-size-1 has-text-primary section-title">Access to Capital to Fund Specialty Finance Assets</h3>
                    <br>
                    <p>WebBank provides asset-based lending and other credit facilities to Strategic
                        Partners, credit funds, and other lenders with a focus on specialty finance assets. </p>
                    <br>
                    <router-link to="/asset-finance" class="button is-primary is-large">Learn More</router-link>
                </div>
            </div>
        </div>
    </section>
    <br>

    <div id="newsroom">
        <section class="section">
            <div class="container">
                <div class="has-text-centered">
                    <h3 class="has-text-white hero-title is-hidden-mobile">News</h3>
                    <h3 class="is-size-3 has-text-white section-title is-hidden-desktop">News</h3>
                </div>
                <br>

<div class="tile is-ancestor">
  <div class="tile is-vertical">
    <div class="tile">
                    <div class="tile is-parent">
                      <article class="tile is-child card">
                                <router-link to="/newsroom/webbank-PR31-2023-07-26">
                                    <div class="card-image">
                                      <figure class="image">
                                        <img src="https://assets.webbank.com/newsroom/pr31-small.png" alt="Financial Technology Association">
                                      </figure>
                                    </div>
                                    <div class="card-content">
                                      <div class="media">
                                        <div class="media-content">
                                          <p class="press-release">Press Release</p>
                                          <p class="title is-4">WebBank Joins the Financial Technology Association</p>
                                          <br>
                                          <br>
                                        <time datetime="2023-07-25" class="news-date">July 25th, 2023</time>
                                        </div>
                                      </div>
                                    </div>
                                </router-link>
                      </article>
                    </div>
                  <div class="tile is-parent">
                    <article class="tile is-child card">
                              <router-link to="/newsroom/webbank-PR32-2023-07-26">
                                  <div class="card-image">
                                    <figure class="image">
                                      <img src="https://assets.webbank.com/newsroom/pr32-small.png" alt="Financial Technology Association">
                                    </figure>
                                  </div>
                                  <div class="card-content">
                                    <div class="media">
                                      <div class="media-content">
                                        <p class="press-release">Press Release</p>
                                        <p class="title is-4">WebBank Announces Jason Lloyd's Appointment to the Utah
                                        Bankers Association Board of Directors</p>
                                        <br>
                                        <br>
                                      <time datetime="2023-07-25" class="news-date">July 25th, 2023</time>
                                      </div>
                                    </div>
                                  </div>
                              </router-link>
                    </article>
                  </div>
                <div class="tile is-parent">
                  <article class="tile is-child card">
                            <router-link to="/newsroom/webbank-PR30-2023-06-11">
                                <div class="card-image">
                                  <figure class="image">
                                    <img src="https://assets.webbank.com/newsroom/pr30-small.png" alt="American Banker Best Places to Work">
                                  </figure>
                                </div>
                                <div class="card-content">
                                  <div class="media">
                                    <div class="media-content">
                                      <p class="press-release">Press Release</p>
                                      <p class="title is-4">WebBank Honored as One of the Best Places to Work in Financial Technology 2023</p>
                                      <br>
                                      <br>
                                    <time datetime="2023-06-11" class="news-date">June 11th, 2023</time>
                                    </div>
                                  </div>
                                </div>
                            </router-link>
                  </article>
                </div>
    </div>


  </div>
</div>

                <br>

                <div class="has-text-centered">
                    <h3 class="is-size-3 has-text-white section-title">
                        <router-link to="/newsroom" class="has-text-white" title="WebBank Newsroom">
                            <span>Read More from the Newsroom</span>&nbsp;&nbsp;
                            <span class="icon"><i class="fas fa-arrow-right fa-sm"></i></span>
                        </router-link>
                    </h3>
                </div>
                <br>
                <br>
            </div>
        </section>
    </div>
            <section class="bottom-hero2 is-hidden-mobile">
                <div class="container">
                    <div class="columns">
                        <div class="column is-10">

                    <div class="hero-box-left3">
                        <div class="columns">
                            <div class="column is-6 has-text-left">
                                <div class="">
                                    <p class="hero-title" v-html="hero_title"></p>
                                    <p class="hero-subtitle" style="color: black;" v-html="hero_subtitle"></p>
                                    <br>
                                    <router-link to="/working-at-webbank" class="button is-large is-primary" title="Work at WebBank!">
                                        <span class="is-hidden-mobile">Explore </span> &nbsp;Open Positions
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                        </div>
                    </div>
                </div>
                </section>

            <section class="is-hidden-tablet">
                <img src="https://assets.webbank.com/home4.png" alt="WebBank" class="is-fullwidth">
                <div class="container p-4">
                    <p class="hero-title" v-html="hero_title"></p>
                    <p class="hero-subtitle" style="color: black;" v-html="hero_subtitle"></p>
                    <br>
                    <router-link to="/working-at-webbank" class="button is-primary is-fullwidth is-light" title="Work at WebBank!">
                        Explore Open Positions
                    </router-link>
                </div>
            </section>

</template>

<script>
import { useNavbarStore } from '@/stores/navbar';

export default {
    components: {  },
    data() {
        return {
            hero_title: 'Not Just A Job,<br>Discover a Great Career.',
            hero_subtitle: "Work with the most talented people in our industry.<br>Join us and see where new opportunities with WebBank can take you.",

        }
    },
    mounted() {
        console.log('parent is: ' + this.parent);
        if (this.parent === 'business') {
            this.hero_title = "Support for Interests That are Important to You";
            this.hero_subtitle = "We hire more than just great banking and finance talent. We hire people who are also great citizens, neighbors, and difference makers. Let's talk about a career at WebBank and about how we can support interests you have that go beyond the Bank.";
        }
    },
    setup() {
        const store = useNavbarStore();
        return { store };
    },
    created() {
        document.title = "Home - WebBank";
    },
};
</script>
