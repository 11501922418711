<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/fingerhut-fetti2.png" alt="Fingerhut Fetti Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
              <div class="columns is-mobile">
                  <div class="column is-5">
                    <img src="https://assets.webbank.com/partners/fingerhut_fetti.png" style="max-height: 30px;" alt="Fingerhut Fetti Logo">
                  </div>
                  <div class="column">
                    <img src="https://assets.webbank.com/FingerhutFreshstart.png" style="max-height: 30px;" alt="Fingerhut Freshstart Logo">
                  </div>
              </div>
          </div>
            <br>
            <p>In partnership with Fingerhut, WebBank is the issuer and lender of the Fingerhut Fetti Credit Account, and the Fingerhut FreshStart installment loan.
                Both products can be used to finance purchases through <a href="#" title="Visit Fingerhut" @click="$emit('link-click', 'https://www.fingerhut.com')">www.fingerhut.com</a>
                or the Fingerhut catalog. For questions about either Fingerhut account,
                please call 800-964-1975. To apply for an account, please click <a href="#" title="Apply at Fingerhut" @click="$emit('link-click', 'https://www.fingerhut.com/creditApp/index')">here</a>.</p>

            <br>
            <p><a href="#" title="Fingerhut Privacy Policy" @click="$emit('link-click', 'https://www.fingerhut.com/content/PrivacyOnline')">Fingerhut Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Fingerhut Privacy Notice" @click="$emit('link-click', 'https://www.fingerhut.com/assets/f/nonselling/Fingerhut_Account_Privacy.pdf')">Fingerhut Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://www.fingerhut.com/assets/f/nonselling/WebBank_Privacy.pdf')">WebBank Privacy Notice</a></p>
            <br>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
