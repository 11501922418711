<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr39_hero.png" alt="Empower to Acquire Petal">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr39_small.png" alt="Empower to Acquire Petal">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">Empower Signs Definitive Agreement to Acquire Petal, and Completes
                            Acquisition of Cashalo, to Accelerate Access to Fair Credit for Underserved Consumers
                            funds they need to exercise their options free of interest or stock fees</h2>
                        <h3 class="news-page-subtitle">Empower enters the U.S. credit card category and the Southeast
                            Asian credit market, poised to revolutionize lending with a smarter, more inclusive approach
                            to underwriting and product innovation</h3>
                        <br>
                        <p><em><small>April 9, 2024</small></em></p>
                        <p><em><small>San Francisco, California</small></em></p>
                        <br>
                        <p>SAN FRANCISCO, April 9, 2024 /PRNewswire/ -- Empower Finance, Inc. has entered into a
                            definitive agreement to acquire
                            <a @click="clickOutWarning('https://c212.net/c/link/?t=0&l=en&o=4136153-1&h=1620734346&u=https%3A%2F%2Fwww.petalcard.com%2F&a=Petal');">Petal</a>,
                            a consumer credit card and financial technology
                            company headquartered in New York, and completed its acquisition of Cashalo, a consumer
                            credit and lending company headquartered in the Philippines.
                            <a @click="clickOutWarning('https://c212.net/c/link/?t=0&l=en&o=4136153-1&h=691900357&u=https%3A%2F%2Fempower.me%2F&a=Empower');">Empower</a>
                            is a leader in alternative credit underwriting and a pioneer in financial product innovations for
                            underserved consumers in the U.S. and Mexico. The combined companies will fold into Empower
                            and continue to transform the way people access and build credit, enabling greater
                            participation in established and emerging credit economies.
                        </p>
                        <br>
                        <p>Empower underwrites individuals using real-time cash flow, other nontraditional data, and
                            machine learning to assess financial responsibility and credit risk differently than
                            mainstream lenders, which rely primarily on credit agency reporting. This proprietary
                            underwriting, coupled with credit products that are uniquely structured to match today's
                            fluid working patterns, allows the company to offer lines of credit, which are issued by
                            FinWise Bank, Member FDIC, and no-interest advances to 3 in 4 consumers who would otherwise
                            have minimal access to liquidity. Founded in 2016, Empower has 2 million active subscribers
                            and achieved profitability in 2022.</p>
                        <br>
                        <p>Petal and Cashalo give Empower strategic footholds in the credit card category in the U.S.
                            and the credit market in Southeast Asia, respectively. "In both companies, we found a shared
                            commitment to harnessing technology and rich alternative data to unlock financial
                            opportunity for more people who merit our consideration. I'm confident that by merging Petal
                            and Cashalo into Empower, we amass new product, operational, and analytical capabilities to
                            help alleviate the credit insecurity that billions of people around the world struggle
                            with," says Warren Hogarth, Co-Founder and CEO of Empower (and former Partner at Sequoia
                            Capital).</p>
                        <br>
                        <p>Petal pioneered access to high-quality Visa® credit cards that don't require a credit score
                            to qualify, via cash flow underwriting, enabling individuals to use their open banking data
                            to qualify for credit and establish a credit history. This technology makes credit more
                            accessible to millions of U.S. consumers without credit histories, or for whom traditional
                            credit scores do not tell the whole story. Since 2018, nearly 400,000 consumers have been
                            approved for the Petal credit card, which is issued by WebBank; the majority of those
                            consumers had thin or no credit files at the time they were approved.</p>
                        <br>
                        <p>This shared approach is vital to solving gaps in U.S. credit accessibility. Despite the
                            maturity of the U.S. credit economy, TransUnion reports that over 45 million consumers are
                            considered to be credit unserved or underserved. Credit card approval rates vary
                            significantly by credit tier according to the Consumer Financial Protection Bureau in their
                            2022 review of the consumer credit card market. Over 60% of general-purpose card
                            applications submitted by consumers with prime and above-prime credit scores were approved,
                            while just 20% of applications submitted by consumers with no score and 17% of applications
                            submitted by consumers in the non-prime credit tier were approved.</p>
                        <br>
                        <p>"Safe, affordable credit is unavailable to tens of millions of consumers in the U.S. and
                            billions worldwide. We believe that modern product design and new technologies like cash
                            flow underwriting can be used to radically improve credit access around the world," says
                            Jason Rosen, Petal's Co-Founder. "This merger brings together two of the leading innovators
                            in this arena. Our combined product offerings, financial strength, technical capabilities,
                            and global reach will allow us to move much faster to close the equity gap in credit."</p>
                        <br>
                        <p>Empower's acquisition of Petal is expected to close later this quarter.</p>
                        <br>
                        <p>For press inquiries, contact: <a href="mailto:media@empower.me">media@empower.me</a> and
                            <a href="mailto:press@petalcard.com">press@petalcard.com</a>
                        </p>
                        <br>
                        <p><strong>About Empower Finance, Inc.</strong></p>
                        <p>Empower is a financial technology company on a mission to expand access to fair credit and
                            give anyone in the world the opportunity to improve their financial security and mobility.
                            The company is a leader in alternative credit underwriting and offers innovative financial
                            products in a seamless mobile experience. Lines of credit are provided by FinWise Bank,
                            Member FDIC. Empower is based in San Francisco, CA.</p>
                        <br>
                        <p><strong>About Petal</strong></p>
                        <p>Petal is a new kind of credit card company built to help people financially succeed. Petal
                            has been at the forefront of using cash flow underwriting as an alternative to traditional
                            credit scores since 2016, and offers a simple, modern digital experience that encourages
                            members to build credit and spend responsibly. Petal credit cards are issued by WebBank.
                            Petal is based in New York, NY, and Richmond, VA.</p>
                        <br>
                        <p><strong>About Cashalo</strong></p>
                        <p>Cashalo is a fintech platform that delivers digital credit to unbanked and underserved
                            Filipinos, helping them elevate their financial well-being. All loans under the Cashalo
                            platform are financed by Paloo Financing Inc. Cashalo is based in Manila, Philippines.</p>
                        <br>
                    </div>

                    <div class=" modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website
                                    and does
                                    not
                                    represent either the linked website or you, should you enter into a
                                    transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked
                                    site. We
                                    encourage you to review their privacy and security policies which may differ
                                    from
                                    WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "Empower to Acquire Petal";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
