<template>
    <div class="notification is-primary is-light" v-if="is_holiday">
        <p class="is-size-4">
            <span class="icon"><i class="fa-solid fa-triangle-exclamation"></i></span>&nbsp;&nbsp;
            <span v-html="message"></span>
        </p>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    data: function () {
        return {
            holidays: [
                { start: '01/27/2023', end: '01/31/2023', message: 'The Online Banking feature will be unavailable beginning on Monday, January 30th at 4 pm MT through Tuesday morning, January 31st' },
                { start: '02/09/2023', end: '02/20/2023', message: "WebBank will be closed on Monday, February 20th in observance of President's Day" },
                { start: '05/15/2023', end: '05/29/2023', message: "WebBank will be closed on Monday, May 29th in observance of Memorial Day" },
                { start: '06/05/2023', end: '06/19/2023', message: "WebBank will be closed on Monday, June 19th in observance of Juneteenth National Independence Day" },
                { start: '06/20/2023', end: '07/04/2023', message: "WebBank will be closed on Tuesday, July 4th in observance of Independence Day" },
                { start: '08/21/2023', end: '09/04/2023', message: "WebBank will be closed on Monday, September 4th in observance of Labor Day" },
                { start: '09/25/2023', end: '10/09/2023', message: "WebBank will be closed on Monday, October 9th in observance of Columbus Day" },
                { start: '10/27/2023', end: '11/10/2023', message: "WebBank will be closed on Friday, November 10th in observance of Vetrans Day" },
                { start: '11/09/2023', end: '11/23/2023', message: "WebBank will be closed on Thursday, November 23rd in observance of Thanksgiving Day" },
                { start: '12/11/2023', end: '12/25/2023', message: "WebBank will be closed on Monday, December 25th in observance of Christmas Day" },
                { start: '12/18/2023', end: '01/01/2024', message: "WebBank will be closed on Monday, January 1st in observance of New Years Day" },
                { start: '01/01/2024', end: '01/15/2024', message: "WebBank will be closed on Monday, January 15th in observance of Martin Luther King Jr Day" },
                { start: '05/13/2024', end: '05/27/2024', message: "WebBank will be closed on Monday, May 27th in observance of Memorial Day" },
                { start: '06/05/2024', end: '06/19/2024', message: "WebBank will be closed on Wednesday, June 19th in observance of Juneteenth" },
                { start: '06/20/2024', end: '07/05/2024', message: "WebBank will be closed on Thursday, July 4th and Friday, July 5th in observance of Independence Day" },
                { start: '08/19/2024', end: '09/02/2024', message: "WebBank will be closed on Monday, September 2nd in observance of Labor Day" },
                { start: '09/30/2024', end: '10/14/2024', message: "WebBank will be closed on Monday, October 14th in observance of Columbus Day" },
                { start: '10/28/2024', end: '11/11/2024', message: "WebBank will be closed on Monday, November 11th in observance of Veterans Day" },
                { start: '11/14/2024', end: '11/28/2024', message: "WebBank will be closed on Thursday, November 28th in observance of Thanksgiving" },
                { start: '12/11/2024', end: '12/25/2024', message: "WebBank will be closed on Wednesday, December 25th in observance of Christmas" },
                { start: '12/26/2024', end: '01/01/2025', message: "WebBank will be closed on Wednesday, January 1st in observance of New Years Day" },
                { start: '01/06/2025', end: '01/20/2025', message: "WebBank will be closed on Monday, January 20th in observance of Martin Luther King Day" },
                { start: '02/03/2025', end: '02/17/2025', message: "WebBank will be closed on Monday, February 17th in observance of Presidents' Day" },
            ],
            is_holiday: false,
            message: "",
        }
    },

    mounted() {
        this.notifyHolidays();
    },

    methods: {
        notifyHolidays: function () {
            let now = moment();
            console.log('current time: ' + now);
            for (let x in this.holidays) {
                console.log('start: ' + this.holidays[x].start);

                let modstart = this.modifyDate(this.holidays[x].start);
                console.log('modstart: ' + modstart);

                let starttime = moment(modstart);
                console.log('starttime: ' + starttime);
                if (starttime < now) {
                    console.log('+++ START TIME IS LESS THAN NOW! +++');

                    let modend = this.modifyDate(this.holidays[x].end);
                    console.log('modend: ' + modend);

                    let endtime = moment(modend);
                    console.log('endtime: ' + endtime);
                    if (endtime > now) {
                        console.log('+++ END TIME IS GREATER THAN NOW! +++');
                        this.is_holiday = true;
                        this.message += this.holidays[x].message + "<br>";
                    }
                }

                console.log('end: ' + this.holidays[x].end);
                console.log('message: ' + this.holidays[x].message);
            }
        },

        modifyDate: function (string) {
            let parts = string.split("/");
            let month = parts[0];
            if (month < 10 && month.length < 2) {
                month = "0" + month;
            }
            let day = parts[1];
            if (day < 10 && day.length < 2) {
                day = "0" + day;
            }
            return parts[2] + "-" + month + "-" + day;
        }
    }
}
</script>
