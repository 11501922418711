<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr15-hero.png"
                            alt="Lane Health Partners with Burnham &amp; Flower">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr15-small.png"
                            alt="Lane Health Partners with Burnham &amp; Flower">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">Lane Health announces partnership with Burnham &amp; Flower Insurance Group to offer innovative spending account solutions</h2>
            <br>
            <h4 class="news-date">September 19th, 2022 - Norfolk, VA</h4>
            <br>
            <p>Lane Health is thrilled to announced they have entered into an agreement with Burnham &amp; Flower Insurance Group to offer an Advance line of credit, issued by WebBank, for virtually all Burnham &amp; Flower's HSA account holders as they seek to aggressively grow their position in the marketplace. The Advance¹ line of credit will be powered through Lane Health.</p>
            <br>
            <p>The activated Advance¹ line of credit provides access to on-demand funds for employees with benefit spending accounts and allows them to repay over 12 months through the convenience of payroll deduction. By repaying with pre-tax dollars through their HSA, employees can save up to 20% or more² on out-of-pocket costs, and employers save on payroll taxes. Employers bear no risk, including on uncollectible Advances¹, and the Advance¹ is offered at no cost to them.</p>
            <br>
            <p>“The Advance¹ line of credit makes us different than any other provider in the spending account space,” noted Brad Gambill, Co-founder &amp; CEO of Lane Health.  “With the activated Advance¹ supplying real-time, on-demand funds to employees, Burnham &amp; Flower's clients can give their employees greater peace of mind, knowing they'll have help paying for healthcare expenses when they need it.  And employees can be confident selecting a High Deductible Health Plan even without making any initial HSA contributions. This is a very easy and valuable benefit to offer its clients and their employees.”</p>
            <br>
            <p>“Burnham &amp; Flower Insurance Group is excited to partner with Lane Health to offer access to the Advance¹ line of credit to our HSA solution and help provide financial security to employees facing large or unexpected healthcare expenses,” said Brian Ackerman, CEO of Burnham &amp; Flower Insurance Group. “This solution addresses the key issues related to the accessibility and affordability of healthcare for so many employees. It answers a significant problem with the financial burden of healthcare, and we are excited that our customers will be able to offer this groundbreaking solution.”</p>
            <br>
            <p class="news-section-title">About Lane Health</p>
            <p>Lane Health helps make healthcare more accessible and affordable by offering a full suite of consumer health spending accounts, all on a single Card with a Heart<sup>&reg;</sup>. And through the activated Advance line of credit, issued by WebBank, Lane Health members gain access to an on-demand safety net to help them pay for their healthcare expenses, helping them sleep better at night. Lane Health's industry trailblazing and rapid growth through broker and strategic partner distribution has been recognized with the AlegeusAPEX Awards for Innovation and Growth in 2021 and 2022. Visit <a href="#" @click="clickOutWarning('https://lanehealth.com')">www.LaneHealth.com</a> to learn more.</p>
            <br>
            <p class="news-section-title">About Burnham &amp; Flower</p>
            <p>Burnham &amp; Flower Insurance Group was founded in 1966 by Homer J. Flower and Duane F. Burnham. The company was established primarily to provide retirement programs to Michigan Township Officials and Employees. Since those humble beginnings, the agency has grown into six separate corporations, serving over 2,600 clients in the public and private sector. Burnham &amp; Flower is currently one of the largest municipal insurance organizations in the country and is also a part of the Acrisure Agency Partners, which is one of the fastest growing brokerages in industry history with 560 agency partners scattered throughout the United States. Burnham &amp; Flower's corporate office is in Kalamazoo, MI and they can be reached at 269.381.1173 or via email at info@bfgroup.com</p>
            <br>
            <br>
            <p><strong>Contact:</strong><br>
            James Denison<br>
            Senior Director of Marketing<br>
            JDennison@lanehealth.com<br>
            804-218-6912</p>
            <br>
            <br>
            <br>
            <br>
            <p><small><em><sup>1</sup> Advances issued by WebBank</em></small></p>
            <p><small><em><sup>2</sup> Assuming 7.65% payroll taxes and 12.35% combined federal/state tax rate; savings will vary based on individual tax situations</em></small></p>

                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "Lane Health Partners with Burnham &amp; Flower - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
