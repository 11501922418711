<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr1-hero.png" alt="WebBank and Capital On Tap">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr1-small.png" alt="WebBank and Capital On Tap">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">Capital on Tap Celebrates 10 years, 200,000 Customers on the Heels of $200 Million Raise</h2>
            <h3 class="news-date">June 17th, 2022</h3>
            <br>
            <p>On the heels of a $200 million funding facility, Capital on Tap announced they are celebrating 200,000
                customers as they enter their second decade of existence.</p>
            <br>
            <p>The company unveiled a 10th birthday rebrand to reflect their emergence as a leading FinTech committed to
                championing the success of small businesses in the US and UK.</p>
            <br>
            <p>Capital on Tap launched in the UK in the summer of 2012 providing fixed-term loans
                to small businesses. After several product iterations, Capital on Tap began providing a small business
                credit card and spend management platform in 2017. That was when the business really took off.</p>
            <br>
            <p>"We really have experienced explosive growth over the past decade," said David Luck, Co-Founder and CEO of
                Capital on Tap. "More than 200,000 small business customers in the US and UK have spent more than $5 billion
                on their Capital on Tap Business Credit Cards."</p>
            <br>
            <p>Recognizing small businesses in the US were underserved by traditional banking models, Capital on Tap made
                the product available to US customers in March 2021, in partnership with WebBank, the issuer and lender.</p>
            <br>
            <p>Though the launch took place amidst a global pandemic, more than 12,000 small business customers signed on
                to the platform in the first year.</p>
            <br>
            <p>In April of this year, Capital on Tap settled into their US headquarters in their Midtown, Atlanta, Georgia office.</p>
            <br>
            <p>Capital on Tap's new look opts for sleek and bold, but most importantly, simple. Guided by the mission of making
                running a small business easier, Capital on Tap realized simplicity needs to be at the core of both their product
                and the way they present themselves to cardholders.</p>
            <br>
            <p>"Our new logo, updated website and mobile app, and overall look and feel better reflect a global company that's relied
                on by hundreds of thousands of small businesses on two sides of the Atlantic," Luck said.</p>
            <br>
            <p>The rebrand comes at the tail end of a Q2 loaded with a plethora of product releases including redeeming points for
                gift cards at a discounted rate, enabling Apple Pay and Google Pay, and a new feature-packed mobile app.</p>
            <br>
            <p>Not to mention the closure of a $200 million funding facility to fuel continued growth in the US.</p>
            <br>
            <p>As part of the birthday rebrand, Capital on Tap will begin issuing black cards instead of their signature yellow.</p>
            <br>
            <p>"The color of our card may appear to be just a cosmetic change, but to our customers it represents a level of
                sophistication they very much deserve," Luck said. "It's time we back them with a product that looks as powerful
                as it is."</p>
            <br>
            <p class="news-section-title">About Capital on Tap</p>
            <p>Capital on Tap makes it easy for small businesses to manage their business spending, access funding, and earn best-in-class
                rewards. More than 200,000 small businesses have spent $5 billion on their Capital on Tap Business Credit Cards across
                the US and UK. The Capital on Tap Business Credit Card is issued by WebBank.</p>
            <br>
            <p class="news-section-title">About WebBank</p>
            <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its inception in 1997, WebBank
                has originated and funded over $150 billion in consumer and commercial credit products. WebBank engages in a full range
                of banking activities including consumer and commercial loan products, revolving lines of credit, credit cards,
            private-label card issuance, auto-refinancing, and more.</p>
                        </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "Capital On Tap Celebrates 10 Years - WebBank";
    },

}
</script>
