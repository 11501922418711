<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/PR17-hero.png"
                            alt="Lane Health Partners with Burnham &amp; Flower">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/PR17-small.png"
                            alt="Lane Health Partners with Burnham &amp; Flower">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">Capital on Tap Raises $110 Million to Power the Spend of Small Businesses</h2>
            <br>
            <h4 class="news-date">London, October 20th, 2022</h4>
            <br>
            <p>Global FinTech Capital on Tap has secured a $110 million corporate revolving credit facility with Atalaya Capital Management to provide small businesses in the US and UK with a central hub to manage all aspects of their business finances.</p>
            <br>
            <p>Since launching in 2012, Capital on Tap has provided over $5 billion of funding to more than 200,000 small businesses. In 2021, Capital on Tap launched their US business, which already has more than 14,000 Capital on Tap Business Credit Card holders in its one year of existence. The Capital on Tap Business Credit Cards are issued through WebBank.</p>
            <br>
            <p>While Capital on Tap has closed several funding facilities in the past, over $1 billion since June 2021 to fund customers, this revolving credit facility is the first that will be used to enhance Capital on Tap's quickly evolving product offering.</p>
            <br>
            <p>Today, Capital on Tap provides access to an all-in-one business credit card and spend management platform built to save small businesses time and money with instant access to funds, flexible repayment options, accounting integrations, unlimited free 1.5% cashback, and as many employee cards as they need.</p>
            <br>
            <p>The funding will allow Capital on Tap to build out their central finance hub for small business owners in the US and UK. This will include a full accounts payable suite to manage bills and expenses, a credit score builder to help new companies gain access to business credit, and several other products and features that will help small business owners from the day they start their business.</p>
            <br>
            <p>“We continue to see small businesses overlooked and underserved by many incumbent banks,” said David Luck, Co-Founder and CEO of Capital on Tap. “We want to build a simple, seamless finance platform for small business owners.”</p>
            <br>
            <p>Originally backed by three early angel investors, Capital on Tap has been profitable since 2017 and has experienced an impressive 62% annual sales growth over the last 3 years. While this is the FinTech's first corporate revolving credit facility, Capital on Tap has previously closed two funding facilities with Atalaya Capital Management, both in 2021.</p>
            <br>
            <p>“We are extremely pleased to have received this facility, which will allow us to better serve our small business customers with the spend management tools and flexible funding they need to grow,” said Alan Hart, CFO at Capital on Tap. “Moreover, it is fantastic that we have broadened our relationship with Atalaya to include a general purpose corporate facility.”</p>
            <br>
            <p>James Intermont, Managing Director at Atalaya Capital Management, commented, “Capital on Tap's impressive growth has positive implications for the prosperity of small business owners in the US and UK. We're thrilled to expand our relationship with Capital on Tap via their first revolving credit facility to support this critical segment of the business population.”</p>
            <br>

            <p class="news-section-title">About Capital on Tap</p>
            <p>Capital on Tap makes it easy for small businesses to manage their business spending, access funding, and earn cash back, travel, and gift card rewards. Over 200,000 small businesses have spent more than $5 billion on their <a href="#" @click="clickOutWarning('https://www.capitalontap.com/en/business-credit-cards/')">Capital on Tap Business Credit Cards</a> across the US and UK.</p>
            <br>

            <p class="news-section-title">About Atalaya</p>
            <p>Atalaya Capital Management is a privately held, SEC-registered, alternative investment advisory firm. Atalaya primarily focuses on making private credit and special opportunities investments in three principal asset classes - specialty finance, real estate, and corporate. Founded in 2006, Atalaya is headquartered in New York City and has approximately $9 billion in assets under management. For more information visit <a href="#" @click="clickOutWarning('https://www.atalayacap.com')">www.atalayacap.com</a>.</p>
            <br>
            <br>
            <p>Written by: Kiera Wiatrak</p>
            <br>
                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "Capital on Tap - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
