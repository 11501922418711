<template>
    <section class="section">
        <div class="container">

            <div class="columns">
                <div class="column is-8 is-offset-2">
                    <div class="content">

            <h1 class="is-size-1 section-title has-text-primary">USA PATRIOT ACT Notice</h1>
            <br>
            <h2 class="is-size-4">IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT</h2>
            <br>

            <p class="is-size-5">To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.</p>
            <br>
            <p class="is-size-5">What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver's license or other identifying documents.</p>
            <br>
            <br>
            <br>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "USA Patriot Act - WebBank";
    },

}
</script>

<style scoped>
.patriot-hero {
    background: rgba(31, 55, 107, 0.5) url(https://assets.webbank.com/patriot-act.png);
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, .4);
    background-position: center;
    background-size: cover;
    min-height: 400px;
}
</style>
