<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-jackson.png" class="is-rounded" alt="Jim Jackson">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Jim Jackson</h2>
            <h3 class="people-title">SVP Strategic Partner Oversight</h3>
            <p class="people-bio">
Jim is responsible for managing the oversight of our Strategic Partners, focusing on the risks associated with third-party lending as well as maintaining strong relationships with each of these amazing companies. He has spent over 20 years in the banking and financial services industry.  Jim has extensive experience supporting, developing, and overseeing third-party lending strategies, strategic partnerships, and third-party risk. He is also responsible for being our designated ski guide when visitors come to town and providing a soundtrack for our various meetings. Prior to joining WebBank in 2012, Jim worked in the mortgage industry for companies including Flagship Financial and New Freedom Mortgage, with a focus on operations and compliance as well as creating and overseeing a large network of branching operations. He holds a bachelor's degree from Brigham Young University and an MBA from University of Utah &dash; David Eccles School of Business. Jim received a B.A. from Brigham Young University and an M.B.A. from the
    University of Utah.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
