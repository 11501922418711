<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr36_hero2.png" alt="Collective Liquidity">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr36_small2.png" alt="Collective Liquidity">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">Collective Liquidity Launches Zero Interest Option Exercise Loans
                        </h2>
                        <h3 class="news-page-subtitle">Collective partners with WebBank to advance unicorn employees the
                            funds they need to exercise their options free of interest or stock fees</h3>
                        <br>
                        <p><em><small>February 27, 2024</small></em></p>
                        <p><em><small>New York, New York</small></em></p>
                        <br>
                        <p>Collective Liquidity, Inc. today announced the launch of <a
                                @click="clickOutWarning('https://www.collectiveliquidity.com/option-exercise');">Option
                                Exercise Loans</a> for unicorn employees. The loans will pay the employees’ exercise
                            price on their stock options. Where some private market lenders charge interest rates and
                            stock fees that can result in annual borrowing costs in excess of 20% annually, the Option
                            Exercise Loans, issued by WebBank and available through Collective will charge a 0% interest
                            rate and have no stock fees. Borrowers will pay just a 2.5% loan origination fee (estimated
                            APR 15%). By combining the new loans with Collective’s existing solutions, Collective
                            enables employees of private growth companies to seamlessly exercise their options, reduce
                            their risk, obtain immediate liquidity, and start building long-term wealth all in a single
                            transaction.</p>
                        <br>
                        <p>Before they can sell the shares underlying their options, employees of private growth
                            companies must pay the exercise price on those options. Previously, if they didn’t have the
                            funds to pay the exercise price, they could either go without liquidity or borrow from
                            lenders charging interest rates and stock fees that substantially ate into the value of
                            their shares. In a worst-case scenario faced by 46% of employees holding in-the money stock
                            options, the employee’s options expire worthless due to their inability to exercise. The
                            addition of Option Exercise Loans to the Collective suite of wealth management solutions
                            solves this problem by providing loans to fund the exercise price.</p>
                        <br>
                        <p>Another benefit for employees from the new loan program is that instead of having to assemble
                            a patchwork of different service providers to address their different option exercise, risk
                            management, liquidity and investment needs, the Option Exercise Loans are bundled with
                            Collective products into a single, seamlessly integrated solution immediately available over
                            the <a @click="clickOutWarning('https://www.collectiveliquidity.com/');">Collective
                                website</a>. Collective’s Customer Service Representatives are also always available to
                            talk employees through their alternatives and customize a solution meeting their financial
                            needs.</p>
                        <br>
                        <p>Collective’s CEO and Co-Founder, Greg Brogger, said, “with the launch of Option Exercise Loan
                            program, it is much easier for customers to obtain liquidity for the options they worked so
                            hard to earn and we’re even more excited to be able to do it without charging them interest
                            or stock fees.”</p>
                        <br>
                        <br>
                        <p><strong>ABOUT COLLECTIVE LIQUIDITY</strong></p>
                        <p>Collective Liquidity provides unicorn shareholders with the financial tools and products they
                            need to effectively manage their wealth. Collective’s wealth management solutions enable
                            diversification, liquidity and wealth creation over the long term, generating dramatically
                            better financial outcomes for Collective’s clients.</p>
                        <br>
                        <p><strong>Media Contact:</strong></p>
                        <p>Adam Pratt<br>
                            <a href="mailto:adam@collectiveliquidity.com">adam@collectiveliquidity.com</a><br>
                            <a
                                @click="clickOutWarning('https://www.linkedin.com/company/collective-liquidity/');">LinkedIn</a>
                        </p>
                    </div>

                    <div class="modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website and does
                                    not
                                    represent either the linked website or you, should you enter into a transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked site. We
                                    encourage you to review their privacy and security policies which may differ from
                                    WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "Collective Liquidity and Aliya Capital - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
