<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/gemini.png" alt="Gemini Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/gemini.png" alt="Gemini Logo">
          </div>
            <br>
            <p>In partnership with Gemini, WebBank is the issuer and lender of the Gemini Credit Card.
                For questions about your Gemini Credit Card please call 1-877-725-1116.
                If you are interested in learning more about it or to apply for the Gemini Credit Card, please click
                <a href="#" title="Apply at Gemini" @click="$emit('link-click', 'https://www.gemini.com/credit-card')">here</a>.</p>
            <br>
            <p><a href="#" title="Gemini Cardholder Agreement" @click="$emit('link-click', 'https://www.gemini.com/legal/cardholder-agreement#section-')">Gemini Cardholder Agreement</a></p>
            <br>
            <p><a href="#" title="Gemini Privacy Policy" @click="$emit('link-click', 'https://www.gemini.com/legal/privacy-policy')">Gemini Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Gemini Privacy Notice" @click="$emit('link-click', 'https://www.gemini.com/legal/consumer-privacy-notice')">Gemini Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://www.gemini.com/legal/webbank-consumer-privacy-notice')">WebBank Privacy Notice</a></p>
            <br>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
