<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-blankenstein.png" class="is-rounded" alt="Aaron Blankenstein">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Aaron Blankenstein</h2>
            <h3 class="people-title">SVP Chief Compliance Officer</h3>
            <p class="people-bio">
Aaron joined WebBank in February 2018 as the Bank Secrecy Act (BSA) Officer. He has over 20 years of regulatory compliance experience with multiple financial institutions. Prior to joining WebBank, Aaron was the head of the Consumer Financial Crimes Compliance (CFCC) program at Goldman Sachs, supporting their digital consumer initiatives. Prior to this, he served as the BSA Officer for both GE Capital Bank and Ally Bank where he was responsible for all financial crimes compliance activities, as well as other compliance responsibilities. Aaron received a B.S. from the University of Utah and M.A. from the University
    of Phoenix.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
