<template>

            <a name="online-deposit-policy" id="online-deposit-policy">&nbsp;</a>

<div style="margin-top: 60px;">
        <p class="is-pulled-right mt-10"><router-link :to="{ name: 'PrivacyPage', hash: '#top' }"><i class="fa-solid fa-arrow-up"></i> Back to Top</router-link></p>
        <h4 class="has-text-primary">WebBank Online Deposit Privacy Policy</h4>
<br>
            <p>This privacy policy governs WebBank's online deposit program. It applies to current or former online deposit customers and applicants as well as consumers that interact or view one of our digital advertising campaigns for the online deposit program. Your privacy is important to us. In this privacy policy, we explain how we collect, share, use, and protect your information. Our information collection and sharing practices are further described below.</p>
            <br>
            <h4 class="pp-section-title">Types of Information We Collect and Sources</h4>
            <br>
            <p>The following are examples of the types of information we collect and the sources:</p>
            <ul>
                <li>Information you provide in connection with your deposit account application, including your name, email address, mailing address, telephone number(s), deposit account numbers, social security number, and driver's license number (or comparable identification number);
    Consumer reports from Consumer Reporting Agencies pursuant to the Fair Credit Reporting Act in connection with an application;
    Information from other third parties concerning your identity and information related to preventing fraud;
    Account information; and Your IP address, the type of operating system and browser you use, the identity of any website you came from, and the site you visit next. We may use cookies, web beacons or other technologies to collect and store this information.
                </li>
            </ul>
            <br>
            <h4 class="pp-section-title">Use of Information</h4>
            <p>We use the information discussed above in a number of ways, such as:</p>
            <ul>
                <li>Processing your online deposit application;</li>
                <li>Processing transactions;</li>
                <li>Verifying your identity (such as when you access your account);</li>
                <li>Preventing and reporting fraud;</li>
                <li>Enhancing the security of your online deposit account and our online deposit services;</li>
                <li>Responding to your requests and communicating with you;</li>
                <li>Managing your online deposit account preferences;</li>
                <li>Performing analytics concerning your online deposit account;</li>
                <li>Performing analytics concerning our marketing, including your responses to our emails and the pages and advertisements you view;</li>
                <li>Providing you tailored content and marketing messages;</li>
                <li>Operating, evaluating and improving our business (including developing new products and services; improving existing products and services; performing data analytics; and performing accounting, auditing and other internal functions);</li>
                <li>Complying with and enforcing applicable legal requirements, relevant industry standards, contractual obligations and our policies; and For any other purposes that we may specifically disclose at the time you provide, or we collect your information.</li>
            </ul>
            <br>
            <p>We may also use data that we collect on an aggregate or anonymous basis (such that it does not identify any individual customers) for various business purposes, where permissible under applicable laws and regulations.</p>
            <br>
            <h4 class="pp-section-title">Disclosure of Information</h4>
            <br>
            <p>We may share the information we collect from and about you:</p>
            <ul>
                <li>As described in our Online Deposit Privacy Notice, including with WebBank third-party service providers;</li>
                <li>With third parties or affiliates in connection with a corporate transaction, such as a sale, consolidation or merger of WebBank businesses;</li>
                <li>With other third parties to comply with legal requirements such as the demands of applicable subpoenas and court orders; to verify or enforce our terms of use, our other rights, or other applicable policies; to address fraud, security or technical issues; to respond to an emergency; or otherwise to protect the rights, property or security of our customers or third parties; and</li>
                <li>With third parties in an anonymous or aggregated manner to help deliver products, services, and content that are tailored to you and for other purposes</li>
            </ul>
            <br>
            <h4 class="pp-section-title">Understanding Cookies, Web Beacons and Other Tracking Technologies</h4>
            <p>We, our service providers, and other companies we work with may deploy and use cookies, web beacons, local shared objects and other tracking technologies for various purposes, such as fraud prevention and monitoring our advertising and marketing campaign performance for the online deposit program. Some of these tracking tools may detect characteristics or settings of the specific device you use to access our online services.</p>
            <br>
            <p>“Cookies” are small amounts of data a website can send to a visitor's web browser. They are often stored on the device you are using to help track You areas of interest. Cookies may also enable us, our service providers, and other companies we work with to relate your use of our online services over time to customize your online experience. Most web browsers allow you to adjust your browser settings to decline or delete cookies but doing so may degrade your experience with our online services</p>
            <br>
            <p>Clear GIFs, pixel tags or web beacons—which are typically one-pixel, transparent images located on a webpage or in an email or other message—or similar technologies may be used on our sites and in some of our digital communications (such as email or other marketing messages). They may also be used when you are served advertisements concerning our online deposit products, or you otherwise interact with advertisements concerning our online deposit products outside of our website. These are principally used to help recognize users, assess traffic patterns and measure site or campaign engagement.</p>
            <br>
            <p>Local Shared Objects, sometimes referred to as “flash cookies” may be stored on your hard drive using a media player or other software installed on your device. Local Shared Objects are similar to cookies in terms of their operation but may not be managed in your browser in the same way.</p>
            <br>
            <h4 class="pp-section-title">Do Not Track</h4>
            <p>Many web browsers possess a do-not-track feature that lets your browser tell websites you visit that you do not want to have your online activities tracked. At this time, our website does not respond to browser do-not-track signals.</p>
            <br>
            <h4 class="pp-section-title">Linking to Third-Party Websites</h4>
            <p>WebBank may provide links to websites that are owned or operated by others ("third-party websites"). When you use a link online to visit a third-party website, you will be subject to that website's privacy and security practices, which may differ from ours. You should familiarize yourself with the privacy notice/policy, terms of use and security practices of the linked third-party website before providing any information on that website.</p>
            <br>
            <h4 class="pp-section-title">Security</h4>
            <p>To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured files and buildings. You can learn more about some of the steps we take to protect your personal information and steps you can take to protect your security on our <a href="/security">Online Deposit Security</a> webpage.</p>
            <br>
            <p>Please note that information you send to us electronically may not be secure when it is transmitted to us. We recommend that you do not use unsecure channels to communicate sensitive or confidential information (such as your Social Security number or account number) to us.</p>
            <br>
            <h4 class="pp-section-title">Minors</h4>
            <p>Our online deposit products are not for persons under 18 years of age and our website is not intended for persons under 18 years of age. We do not knowingly collect personal information from persons under 18 years of age.</p>
            <br>
            <h4 class="pp-section-title">Updating Your Online Deposit Account Information</h4>
            <p>Keeping your account information up-to-date is very important</p>
            <br>
            <p>You may review or update certain online deposit account information by logging in and accessing our Online Banking Portal. If you cannot change the incorrect information online, or you prefer to request changes offline, please call us at 1-844-994-BANK (2265).</p>
            <br>
            <h4 class="pp-section-title">Accessing Our Website from Outside the U.S.</h4>
            <p>Our website is intended for a U.S. audience. If you visit our website, your information will be transferred to or processed in the United States.</p>
            <br>
            <h4 class="pp-section-title">Changes to this Online Deposit Privacy Policy</h4>
            <p>We may change this Online Deposit Privacy Policy from time to time. When we do, we will let you know by appropriate means such as by posting the revised policy on this page with a new “Last Updated” date. Any changes to this Online Deposit Privacy Policy will become effective when posted or otherwise provided to you unless indicated otherwise.</p>
            <br>
            <h4 class="pp-section-title">Questions</h4>
            <p>If you have questions about our privacy practices, please contact us at 1-844-994 BANK (2265).</p>
            <h4 class="pp-section-title">California Privacy Notice</h4>
            <p>If you are a California resident who is a natural person, the California Consumer Privacy Act (“CCPA”) provides certain rights concerning your personal information, unless an exception applies. These exceptions include information collected, used, and disclosed pursuant to the Gramm-Leach-Bliley Act (“GLBA”) or the Fair Credit Reporting Act (“FCRA”). Further, the CCPA does not consider information to be personal information if it cannot be reasonably linked to any consumer or household.</p>
            <br>
            <p>If you are a current or former online deposit customer or applicant, WebBank's collection and use of your personal information is pursuant to the GLBA and FCRA, and, therefore, the CCPA does not apply. If you are otherwise a consumer that interacted or viewed one of our digital advertising campaigns for the online deposit program, we do not collect information in a manner that can be reasonably linked to you, and, therefore, the CCPA does not apply.</p>
            <br>
            <p>[WebBank does not and will not sell personal information it collects in connection with its online deposit program to third parties.]</p>
            <br>
            <br>
</div>
</template>
