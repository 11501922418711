<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/mosaic.svg" alt="Mosaic Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/mosaic.svg" alt="Mosaic Logo">
          </div>
            <br>
            <p>In partnership with Mosaic, WebBank is the lender of Mosaic-branded home improvement installment loans and solar energy
                and geothermal purpose installment loans to consumers through Solar Mosaic, Inc.
                (click <a href="#" title="More information at Mosaic" @click="$emit('link-click', 'https://www.joinmosaic.com/')">here</a> for more information).
                To speak with someone about your Mosaic-branded installment loan issued by WebBank, please call Mosaic at (855) 746-5551,
                or you may contact Mosaic via email at <a href="mailto:loanservicing@joinmosaic.com">loanservicing@joinmosaic.com</a>.</p>
            <br>
            <p><a href="#" title="Mosaic Privacy Policy" @click="$emit('link-click', 'https://joinmosaic.com/privacy/')">Mosaic Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Mosaic Privacy Notice" @click="$emit('link-click', 'https://joinmosaic.com/wp-content/uploads/2021/12/FINAL-Mosaic-GLBA-English-Nov.-2021.pdf')">Mosaic Privacy Notice</a></p>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
