<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/libertas.svg" alt="Libertas Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/libertas.svg" alt="Libertas Logo">
          </div>
            <br>
            <p>Through the Libertas Funding platform, WebBank is the lender of small business installment loans on a national basis.
                If you have questions about your account please call 800-704-8675, or you may contact Libertas via email at
                <a href="mailto:customer.service@libertasfunding.com">customer.service@libertasfunding.com</a>. If you are interested in applying for loan through Libertas Funding, click
                <a href="#" title="Apply at Libertas" @click="$emit('link-click', 'https://libertasfunding.com/')">here</a>.</p>
            <br>
            <p><a href="#" title="Libertas Privacy Policy" @click="$emit('link-click', 'https://www.libertasfunding.com/wp-content/uploads/2022/03/PV4-Online-Privacy-Policy-Clarizen-Approved-3.2.22.pdf')">Libertas Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Libertas Privacy Notice" @click="$emit('link-click', 'https://www.libertasfunding.com/wp-content/uploads/2022/03/PV5-California-Privacy-Notice-Clarizen-Approved-3.2.22.pdf')">Libertas Privacy Notice</a></p>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
