<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr25_hero.png"
        alt="Libertas Funding Volume Milestone">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr25_small.png"
        alt="Libertas Funding Volume Milestone">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">CAN Capital Reaches Impressive Milestone, Providing Access to
                Over $8 Billion in Working Capital to U.S. Businesses</h2>
            <br>
            <h4 class="news-date">April 5th, 2023</h4>
            <br>
            <p>CAN Capital, Inc., an innovator in alternative small business finance, announced today that it
    recently reached a significant milestone - providing access to over $8 billion in working capital
    to U.S. Small Businesses, loans originated by WebBank. For 25 years, CAN Capital’s technology,
    data-driven models, experienced team, and customer-focused delivery have enabled the
    company to become a leader in alternative finance servicing small businesses.</p>
<br>
<p>CAN Capital’s milestone reflects the success of its mission to unlock capital for even more small
    businesses. The company facilitated over 208,000 funding contracts, enabling more than 85,000
    merchants to access the working capital they need to take their businesses to the next level.</p>
<br>
<p>Ed Siciliano, CAN Capital’s CEO, says, “Everyone at CAN is excited about this milestone, it speaks
    to the value that we feel we have brought to the SMB market for decades. Having said that, we
    are constantly working to improve how we service our customers and partners with the benefit
    of having outstanding employees.”</p>
    <br>
            <p class="news-section-title">About CAN Capital, Inc.</p>

            <p>CAN Capital, Inc., established in 1998, is the pioneer in alternative small business finance. As a
    technology-powered financial services provider, CAN Capital uses innovative and proprietary
    risk models combined with daily performance data to evaluate business performance and
    facilitate access to Capital for entrepreneurs in a fast and efficient way. CAN Capital, Inc. makes
    Capital available to businesses through business loans made by WebBank and makes
    equipment financing available through equipment loans and leases offered by various providers
    of such products. ©2023 CAN Capital, Inc. All rights reserved.</p>
<br>
<br>
            <p><strong>Press Contact:</strong> Evan Day</p>
            <p><strong>Email:</strong>
                <a href="mailto:eday@cancapital.com">eday@cancapital.com</a>
            </p>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "Libertas Funding Volume Milestone - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
