<template>
  <section class="hero newsroom-hero is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only">
    <div class="hero-body">
      <section class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-8">
              <div class="deposit-products-box">
                <p class="title hero-title">
                  Newsroom
                </p>
                <p class="subtitle hero-subtitle">
                  Get the latest news about WebBank, our partners and the products we offer right here.
                </p>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  </section>

  <div class="blue-bg is-hidden-widescreen">
    <img src="https://assets.webbank.com/newsroom-hero.png">
    <section class="section">
      <div class="container">
        <p class="title hero-title-mobile">
          Newsroom
        </p>
        <br>
      </div>
    </section>
  </div>

  <section class="section grey-bg">
    <div class="container">

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/onemain-financial-PR26-2023-05-16">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr26_small.png" alt="OneMain Financial">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">OneMain Financial Partners with FinMkt to Offer Access to Point of Sale
                          Home
                          Improvement Loans</p>
                        <br>
                        <br>
                        <time datetime="2023-05-02" class="news-date">May 2nd, 2023</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/can-capital-PR25-2023-04-24">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr25_small.png" alt="CAN Capital">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">CAN Capital Reaches Impressive Milestone, Providing Access to Over
                          $8 Billion in Working Capital to U.S. Businesses</p>
                        <br>
                        <br>
                        <time datetime="2022-12-07" class="news-date">April 5th, 2023</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/libertas-funding-PR23-2023-03-31">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr23_small.png" alt="Libertas Funding">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Libertas Eclipses $2 Billion Funding Volume Milestone</p>
                        <br>
                        <br>
                        <time datetime="2022-12-07" class="news-date">March 27th, 2023</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>


      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/glassdoor-PR22-2023-01-27">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr22-small2.png" alt="Eraj Asadi">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Announces Eraj Asadi Named SVP - Head of Asset Finance</p>
                        <br>
                        <br>
                        <time datetime="2022-12-07" class="news-date">January 25th, 2023</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/glassdoor-PR21-2023-01-20">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr21-small.png" alt="Glassdoor">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Honored as One of the Best Places to Work in 2023</p>
                        <br>
                        <br>
                        <time datetime="2022-12-07" class="news-date">January 20th, 2023</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/kelsey-hardy-PR20-2023-01-20">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr20-small.png" alt="Kelsey Hardy Promotion">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Announces Kelsey Hardy Named SVP - Business Development</p>
                        <br>
                        <br>
                        <time datetime="2022-12-07" class="news-date">January 20th, 2023</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/avant-funding-PR19-2022-12-07">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr19-small.png" alt="Avant Secures Funding">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Avant Secures $250 Million In Funding From Ares Management Corporation</p>
                        <br>
                        <br>
                        <time datetime="2022-12-07" class="news-date">December 7th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/collective-liquidity-PR18-2022-11-15">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr18-small.png" alt="Collective Liquidity">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Collective Liquidity Launches Wealth Management Platform for Unicorn
                          Shareholders</p>
                        <br>
                        <br>
                        <time datetime="2022-11-15" class="news-date">November 15th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/zip-card-PR16-2022-10-25">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr16-small.png" alt="Zip Card">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Zip Accelerates BNPL Adoption with Arrival of Zip Card</p>
                        <br>
                        <br>
                        <time datetime="2022-10-20" class="news-date">October 25th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/capital-on-tap-PR17-2022-10-20">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/PR17-small.png" alt="Capital on Tap">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Capital on Tap Raises $110 Million to Power the Spend of Small Businesses
                        </p>
                        <br>
                        <br>
                        <time datetime="2022-10-20" class="news-date">October 20th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/lane-health-burnham-flower-PR15-2022-08-19">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr15-small.png" alt="WebBank CEO Interview">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Lane Health Partners with Burnham &amp; Flower</p>
                        <br>
                        <br>
                        <time datetime="2022-08-19" class="news-date">September 19th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <a href="https://assets.webbank.com/newsroom/PR-14-FT-Partners-Executive-Interview.pdf" target="_blank">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr14-small.png" alt="WebBank CEO Interview">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">FinTech CEO Interview: WebBank</p>
                        <br>
                        <br>
                        <time datetime="2022-09-06" class="news-date">September 6th, 2022</time>
                      </div>
                    </div>
                  </div>
                </a>
              </article>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/most-innovative-PR11-2022-07-21">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr11-small.png" alt="ABF Journal">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">News Article</p>
                        <p class="title is-4">WebBank Named One of 2022 Most Innovative Companies in Specialty Finance
                          by ABF Journal</p>
                        <br>
                        <br>
                        <time datetime="2022-06-17" class="news-date">July 21st, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/lane-health-pr10-2022-07-20">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr10-small.png" alt="WebBank and Lane Health">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">CGI Business Solutions announces partnership with Lane Health to offer
                          innovative spending account solutions</p>
                        <br>
                        <time datetime="2022-06-17" class="news-date">July 20th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/capital-on-tap-2022-06-17">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr1-small.png" alt="Placeholder image">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Capital on Tap Celebrates 10 years, 200,000 Customers on the Heels of $200
                          Million Raise</p>
                        <br>
                        <time datetime="2022-06-17" class="news-date">June 17th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/american-express-PR13-2022-08-16">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr13-small.png"
                        alt="WebBank Collaborates with American Express">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Collaborates with American Express to Expand Credit Card Issuing
                        </p>
                        <br>
                        <br>
                        <time datetime="2022-08-04" class="news-date">August 16th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/security-premium-finance-PR12-2022-08-04">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr12-small.png"
                        alt="National Partners Acquires Security Premium Finance">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">National Partners PFco Acquires Security Premium Finance Company, LLC</p>
                        <br>
                        <br>
                        <time datetime="2022-08-04" class="news-date">August 4th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/paypal-2022-06-15">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr2-small.png" alt="Placeholder image">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">PayPal Introduces 'Pay Monthly' to Give Consumers More Choice at Checkout
                        </p>
                        <br>
                        <time datetime="2022-06-17" class="news-date">June 15th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/icreditworks-2022-06-13">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr3-small.png" alt="Placeholder image">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Makes a Strategic Investment in FinTech Platform iCreditWorks</p>
                        <br>
                        <br>
                        <br>
                        <time datetime="2022-06-17" class="news-date">June 13th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/avant-pr9-2022-07-14">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr9-small.png" alt="WebBank and Avant">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">WebBank Invests $250 Million in Avant to Support Growth of Credit Card
                          Program</p>
                        <br>
                        <br>
                        <time datetime="2022-06-17" class="news-date">July 14th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/klarna-2022-06-01">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr4-small.png" alt="Klarna and WebBank">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Klarna Card launches in the US alongside new money management tools,
                          empowering consumers to take control of their finances</p>
                        <br>
                        <time datetime="2022-06-17" class="news-date">June 1st, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
          </div>
        </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/dignifi-2022-05-10">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr5-small.png" alt="WebBank and DigniFi">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">DigniFi Welcomes Pagaya to Its Inclusive Financing Platform, Expanding
                          Lending Services to Small Businesses</p>
                        <br>
                        <br>
                        <time datetime="2022-06-17" class="news-date">May 10th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/gemini-2022-04-14">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr6-small.png" alt="WebBank and Gemini">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">The Gemini Crypto Rewards Credit Card is Now Available in the United
                          States</p>
                        <br>
                        <br>
                        <br>
                        <time datetime="2022-06-17" class="news-date">April 14th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/capital-on-tap-2022-04-13">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr8-small.png" alt="WebBank and Capital on Tap">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">Atlanta's Capital on Tap secures $200 million funding facility to fuel
                          continued growth</p>
                        <br>
                        <br>
                        <br>
                        <time datetime="2022-06-17" class="news-date">April 13th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
        </div>
      </div>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-parent">
              <article class="tile is-child card">
                <router-link to="/newsroom/icreditworks-2022-04-13">
                  <div class="card-image">
                    <figure class="image">
                      <img src="https://assets.webbank.com/newsroom/pr7-small.png" alt="iCreditWorks TrueZERO%">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="press-release">Press Release</p>
                        <p class="title is-4">iCreditWorks Announces the Addition of the TrueZERO% Installment Loan to
                          Its Broad Point-Of-Sale Product Suite</p>
                        <br>
                        <br>
                        <time datetime="2022-06-17" class="news-date">April 13th, 2022</time>
                      </div>
                    </div>
                  </div>
                </router-link>
              </article>
            </div>
            <div class="tile is-parent">
                &nbsp;
            </div>
            <div class="tile is-parent">
                &nbsp;
            </div>
          </div>
        </div>
      </div>

      <newsroom-pagination></newsroom-pagination>


    </div>
  </section>

</template>

<script>
import NewsroomPagination from './Components/NewsroomPagination.vue';
export default {
    components: { NewsroomPagination },
    created() {
        document.title = "Newsroom Page 2 - WebBank";
    },

}
</script>
