<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/avant_logo.png">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/avant_logo.png" alt="Avant Logo">
          </div>
            <br>
            <p>In partnership with Avant, WebBank offers two Avant-branded credit products. WebBank is the lender of Avant closed-end, consumer installment loans
                (click <a href="#" title="Avant Personal Loans" @click="$emit('link-click', 'https://www.avant.com/personal-loans/');">here</a> for more information).
                WebBank is also the issuer and lender of the Avant Credit Card
                (click <a href="#" title="Avant Credit Card" @click="$emit('link-click', 'https://www.avant.com/credit-card/');">here</a> for more information).</p>
            <br>
            <p>To speak with somebody about your Avant-branded installment loan, please call Avant at 800-712-5407, or you may contact Avant via email at <a href="mailto:support@avant.com">support@avant.com</a>.
                To speak with somebody about your Avant-branded credit card, please call Avant at 855-752-7012, or you may contact Avant via email at <a href="creditcards@avant.com">creditcards@avant.com</a>.</p>
            <br>
            <p><a href="#" title="Avant Cardholder Agreement" @click="$emit('link-click', 'https://www.avant.com/cardmember_agreement');">Avant Cardholder Agreement</a></p>
            <br>
            <p><a href="#" title="Avant Privacy Policy" @click="$emit('link-click', 'https://www.avant.com/privacy-policy/');">Avant Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Avant Privacy Notice" @click="$emit('link-click', 'https://www.avant.com/avant-privacy-notice/');">Avant Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://www.avant.com/webbank-privacy-notice/');">WebBank Privacy Notice</a></p>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
