<template>
    <section class="hero security-hero is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-10">
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                    <div class="hero-box-left">
                            <p class="title hero-title">
                                Banking Security
                            </p>
                            <p class="subtitle hero-subtitle">
                               See how WebBank's strong security protocols keep your money safe and give you peace of mind.
                            </p>
                        </div>
                    </div>
                    </div>

                </div>
            </section>
        </div>
    </section>

    <div class="blue-bg is-hidden-widescreen">
        <img src="https://assets.webbank.com/security-hero2.png">
        <section class="section">
            <div class="container">
                            <p class="title hero-title-mobile">
                                Banking Security
                            </p>
                            <p class="subtitle hero-subtitle-mobile">
                               See how WebBank's strong security protocols keep your money safe and give you peace of mind.
                            </p>
                            <br>
            </div>
        </section>
    </div>

        <section class="section">
            <div class="container">
                <div class="columns">
                    <div class="column p-6">
                        <h3 class="is-size-1 has-text-primary section-title">WebBank Security Systems and Procedures</h3>
                        <br>
                        <div class="content">
                            <ul>
    <li>WebBank continuously monitors all transactions and accounts for suspicious activity.</li>
    <li>Our enforcement of layered security ensures restricted access to your account information.</li>
    <li>Ongoing antivirus deployments and updates keep our computer systems protected and malware-free.</li>
    <li>System hardware and software firewalls are continuously in place to help block unauthorized access to your information.</li>
    <li>We require Secure Socket Layer (SSL) encryption for the creation of a secure browser connection that prevents data intercept by malicious actors.</li>
    <li>WebBank offers a secure messaging portal for private communications.</li>
    <li>Our Automatic Sign-Out feature signs off and secures your account after a period of session inactivity. </li>
                            </ul>
                        </div>
                        <br>
                    </div>
                    <div class="column">
                        <br>
                        <img src="https://assets.webbank.com/security1.png" />
                    </div>
                </div>
            </div>
        </section>

        <section class="section grey-bg">
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <br>
                        <br>
                        <img src="https://assets.webbank.com/security2.png" />
                    </div>
                    <div class="column p-6">
                        <div class="content">
                        <h3 class="is-size-1 has-text-primary section-title">Doing Your Part: Best Practices for Secure Banking</h3>
                            <ul>
    <li>Always use a secure browser that’s compliant with Secure Socket Layer (SSL) standards. Look for the small padlock at the bottom right of your browser window and a URL that begins with https ("s" for secure).</li>
    <li>Watch for spam emails that appear to be from your bank but are really from criminals trying to lure you to a fake website to get your personal information. Never click on an embedded link if you are suspicious; instead, type the URL directly into the browser or contact your bank to verify the authenticity of the email. </li>
    <li>Double check that you have typed the correct website address for your bank before conducting your transaction. Beware of copycat websites that deliberately use a similar name to trick you into disclosing personal information. </li>
    <li>Watch out for pop-up windows asking for personal information or warning of a virus. These could be fake solicitations from malicious actors.  </li>
    <li>WebBank will never ask for your online banking username or password. If someone claiming to be from WebBank asks you for this information, do not provide it. If you are concerned about a request you received, please call us immediately at 1-844-994-BANK (2265).</li>
                            </ul>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
        </section>

</template>

<script>
export default {

    created() {
        document.title = "Banking Security - WebBank";
    },

}
</script>
