<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr34-hero.png" alt="Collective Liquidity and Aliya Capital">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr34-small.png" alt="Collective Liquidity and Aliya Capital">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">Collective Liquidity Closes Financing Round and Partners with Aliya Capital Partners</h2>
                        <h3 class="news-page-subtitle">Collective and Aliya partner to provide investors with unique access to late-stage private growth companies and liquidity to unicorn shareholders</h3>
                        <br>
                            <p><em><small>October 2nd, 2023</small></em></p>
                            <br>
                        <p>Collective Liquidity announced today the closing of an extension to its Series A
    Preferred financing led by Aliya Capital Partners. With the closing of the financing, the company has
    raised approximately $12 million in its Series A financing. The proceeds from the most recent closing will
    go towards further scaling the Collective platform and team.</p>
                        <br>
                        <p>Collective enables employees to reduce their risk from over-concentration in a single company by
    exchanging shares tax free for an interest in the Collective Exchange Fund, a diversified portfolio of
    leading unicorns. Employees can then access liquidity by borrowing non-recourse against their fund
    interest, lender WebBank or redeeming their interests for cash. Already providing liquidity and
    diversification to the employees of more than 20 leading unicorns, Collective has been rapidly growing
    the size of the Exchange Fund since closing its first transaction at the beginning of the year.</p>
                        <br>
                        <p>Collective provides investors exposure to its unique pipeline of some of the most sought after pre-IPO
    companies through the Collective Liquidity Fund. The Collective Liquidity Fund combines this access
    with quarterly liquidity and unmatched transparency from its publication of its Target Portfolio and a
    NAV updated daily.</p>
                        <br>
                        <p>Aliya Capital Partners is a global investment firm with experience investing across multiple asset classes
    and with a particular expertise in late stage venture, leading investments in more than 50 private growth
    companies over the past decade. Aliya and Collective will collaborate on raising capital for the Collective
    Exchange Fund and in extending Collective's relationships within the venture ecosystem.</p>
                        <br>
                        <p>Greg Brogger said, “we are very excited to be partnering with Aliya - we believe there is enormous
    potential in matching Collective's unique ability to provide access to the best private growth companies
    with Aliya's global investor network.”</p>
                        <br>
                        <p>Ross M. Kestin, Founding Partner and CEO of Aliya, will be joining the Collective Liquidity Board of
    Directors. Mr. Kestin said, “we were extremely impressed by the platform and momentum the
    Collective team has built in such a short period of time. The strategic fit between Aliya and Collective
    couldn't be stronger.</p>
                        <br>
                        <p><strong>ABOUT COLLECTIVE LIQUIDITY</strong></p>
                        <p>Collective Liquidity is dedicated to providing unicorn shareholders with the financial tools they need to
    effectively manage their wealth. Collective's initial solutions enable transparency, diversification and
    liquidity, generating dramatically better financial outcomes for unicorn shareholders. To learn more
    about Collective Liquidity and to get an instant valuation of your private company stock please visit:
    <a @click="clickOutWarning('https://www.collectiveliquidity.com/my-valuation');">https://www.collectiveliquidity.com/my-valuation</a></p>
                        <br>
                        <p><strong>ABOUT ALIYA CAPITAL PARTNERS</strong></p>
                        <p>Aliya Capital Partners is a leading global investment firm focused on sourcing, researching, evaluating
    and investing in asymmetrical risk-reward investment opportunities. The firm&#39;s partners have managed
    over $3 billion in its strategies since 2007, including taking sizable positions in more than 50 private
    companies since 2018. For more information, visit: <a @click="clickOutWarning('https://aliyacapitalpartners.com');">https://aliyacapitalpartners.com</a>.</p>
                        <br>
                    <p><strong>PRESS CONTACT:</strong></p>
                    <br>
                    <p>Adam Pratt:<br>
                    <a href="mailto:adam@collectiveliquidity.com">adam@collectiveliquidity.com</a>
                    </p>
                    <br>
                    <br>
                    <p><strong>IMPORTANT DISCLOSURES</strong></p>
                    <br>
                    <p>All loans issued by WebBank, Member FDIC.</p>
                    <br>
                    <p>This information relating to the Collective Exchange Fund, LP (the “Fund”) has been prepared solely for
    informational purposes, is not complete, and does not contain certain material information about the
    Fund, including important disclosures and risk factors associated with an investment in the Fund, and is
    subject to change without notice. It does not constitute an offer to buy or sell an interest in the Fund,
    nor shall there be any sale of a security in any jurisdiction where such solicitation or sale would be
    unlawful.</p>
                        <br>
                    <p>The Fund's limited partnership interest will not be registered with the U.S. Securities Exchange
    Commission or other regulatory authority. Investors will be required to verify their status as an
    “Accredited Investor” pursuant to Rule 501 of Regulation D to participate in any offering of the Fund's
    limited partnership interests. No securities commission or regulatory authority has recommended or
    approved any investment or the accuracy or completeness of any of the information or materials
    provided by or through Collective Liquidity, Inc. or Collective Asset Management, LLC (collectively,
    “Collective Liquidity”).</p>
                    <br>
                    <p>Limited partnership interests in the Fund are not insured by the FDIC and are not deposits or other
    obligations of Collective Liquidity and are not guaranteed by Collective Liquidity. Limited partnership
    interests in the Fund are subject to investment risks, including possible loss of the principal invested.</p>
                        <br>
    <p>Prospective investors should consider the investment objectives, risks, fees and expenses of the Fund
    carefully before investing in the Fund. This and other important information are contained in the Fund’s
    Confidential Private Placement Memorandum (“PPM”), which can be obtained by contacting Collective
    Liquidity.</p>
    <br>
    <p>Investment in the Fund involves substantial risk and any offering may only be made pursuant to the
    relevant PPM and the relevant subscription application, all of which must be read in their entirety. No
    offer to purchase securities will be made or accepted prior to receipt by the offeree of these documents
    and the completion of all appropriate documentation. The Fund intends to primarily invest in securities
    of private, late-stage, venture-backed growth companies. There are significant potential risks relating to
    investing in such securities. The Fund is not suitable for investors who cannot bear the risk of loss of all
    or part of their investment. The Fund is appropriate only for investors who can tolerate a high degree of
    risk and do not require a liquid investment. The Fund has no history of public trading and investors
    should not expect to sell limited partnership interests in the Fund. No secondary market exists for the
    Fund’s limited partnership interests, and none is expected to develop. The Fund has a limited operating
    history, and its performance is highly dependent upon the expertise and abilities of its manager. There is
    no assurance that the Fund’s investment objectives will be achieved, and results may vary substantially
    over time. This is not a complete enumeration of the Fund’s risks. Please read the Fund’s PPM for other
    risk factors related to the Fund. Although the manager of the Fund will value its portfolio using the
    Private Market Valuation Algorithm, it can be difficult to obtain financial and other information with
    respect to private companies, and even where the manager is able to obtain such information, there can
    be no assurance that it is complete or accurate. Because such valuations are inherently uncertain and
    may be based on estimates, the manager’s determinations of fair market value may differ materially
    from the values that would be assessed if a readily available market for these securities existed.</p>
    <br>
    <p>The information contained herein does not constitute a recommendation or advice by Collective
    Liquidity. You should consult your own tax, legal, accounting, financial or other advisers about the
    information discussed herein based on your specific risk profile and financial situation, including the
    suitability of an investment in the Fund, with Collective Liquidity, or any product offered or managed by
    Collective Liquidity.</p>
    <br>
    <p>Information contained herein has been obtained from sources believed to be reliable, but not
    guaranteed. No part of this material may be reproduced in any form, or referred to in any other
    publication, without express written permission.</p>
                    </div>

                    <div class="modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website and does not
                                    represent either the linked website or you, should you enter into a transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked site. We
                                    encourage you to review their privacy and security policies which may differ from
                                    WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "Collective Liquidity and Aliya Capital - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
