<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr21-hero.png"
                            alt="WebBank Glassdoor">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr21-small.png"
                            alt="WebBank Glassdoor">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">WEBBANK HONORED AS ONE OF THE BEST PLACES TO WORK IN 2023, A GLASSDOOR EMPLOYEES' CHOICE AWARD WINNER</h2>
            <br>
            <h4 class="news-date">January 20th, 2023</h4>
            <br>
            <p>SALT LAKE CITY — WebBank, a Best-in-Class national issuer of consumer and small business credit
products through Strategic Partner platforms, has been named one of Glassdoor's Best Places to Work in
2023, an <a href="#" @click="clickOutWarning('https://www.glassdoor.com/Award/Best-Small-and-Medium-Companies-to-Work-For-LST_KQ0,43.htm')">Employees' Choice Award</a>
in the U.S. small and medium company category. Unlike other
workplace awards, the Glassdoor Employees' Choice Award, now in its 15th year, is based solely on the
input of employees, who voluntarily provide anonymous feedback by completing a <a href="#" @click="clickOutWarning('https://www.glassdoor.com/Overview/Working-at-WebBank-EI_IE307070.11,18.htm')">company review</a>
about their job, work environment and employer on Glassdoor, the worldwide leader on insights about
jobs and companies.</p>
            <br>
            <p>“We are thrilled to be selected as one of Glassdoor's Best Places to Work in 2023, which is a testament to
our outstanding company culture.” said Shannon Hutchings, SVP, People and Brand at WebBank.
“WebBank is committed to fostering a positive, supportive and transparent work environment and this
award reinforces our philosophy regarding the importance of a best-in-class culture.”</p>
            <br>
            <p>Some of the anonymous reviews WebBank employees provided on Glassdoor.com include:
                <ul>
                    <li>“The company truly cares about employee satisfaction and retention. The leadership works hard to maintain a positive, supportive culture and to communicate frequently with all employees.”</li>
                    <li>“The culture is amazing! Everyone I’ve met and worked with have been very welcoming, positive, hard-working, and willing to help even if it’s not their expertise. WebBank cares about you as a person first before anything else.”</li>
                    <li>“WebBank attracts great talent – people who work hard, care for each other, offer a helping hand, and love working for the Bank. Management listens to employees and responds to their concerns.</li>
                </ul>
            </p>
            <br>
            <p>On Glassdoor, current and former employees voluntarily and anonymously share insights and opinions
about their work environments by sharing a company review, designed to capture a genuine and authentic
inside look at what a specific job may be like at a particular company. When sharing a company review
on Glassdoor, employees are asked to rate their satisfaction with the company overall, and key workplace
factors like career opportunities, compensation and benefits, culture and values, senior management,
diversity and inclusion, and work/life balance. In addition, employees are asked to describe the best
reasons to work at their companies as well as any downsides.</p>
            <br>
            <p>Glassdoor's Best Places to Work winners were determined using company reviews shared by U.S.-based
employees between October 19, 2021 and October 17, 2022. To be considered for the U.S. small and
medium company list, a company must have had fewer than 1,000 employees and received at least 30
ratings across each of Glassdoor's nine workplace attributes (overall company rating, career
opportunities, compensation and benefits, culture and values, diversity and inclusion, senior management,
work-life balance, recommend to a friend and six-month business outlook). The final list is compiled
using Glassdoor's proprietary algorithm, led by its <a href="#" @click="clickOutWarning('https://www.glassdoor.com/research/')">Economic Research Team</a>, and considers quantity,
quality and consistency of reviews. Complete awards methodology can be found
<a href="#" @click="clickOutWarning('https://media.glassdoor.com/staticList/pdf/2023/Glassdoor_Best_Places_to_Work_2023_Methodology_US.pdf')">here</a>.</p>
            <br>
            <p class="news-section-title">About WebBank</p>

            <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its inception in
1997, WebBank has originated and funded over $165 billion in consumer and commercial credit products.
As “The Bank Behind the Brand<sup>&reg;</sup>”, WebBank is a national issuer of consumer and small business credit
products through Strategic Partner (Brand) platforms, which include retailers, manufacturers, finance
companies, software as a service (SaaS) and financial technology (FinTech) companies. The Bank is a
leading player in the digital lending space, driving innovation in financial products through embedded
finance with Strategic Partner platforms. WebBank engages in a full range of banking activities including
consumer and commercial loan products, revolving lines of credit, credit cards, private-label card
issuance, auto-refinancing and more. The Bank provides capital in the form of asset-based lending and
other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a targeted focus
on specialty finance assets. The Bank is also a leading provider of commercial insurance premium finance
products through its wholly owned subsidiary National Partners. For more information, please visit
www.webbank.com.</p>
<br>
            <p class="news-section-title">About Glassdoor</p>
<p><a href="#" @click="clickOutWarning('https://www.glassdoor.com/')">Glassdoor</a> is revolutionizing how people everywhere find jobs and companies they love by providing
deeper workplace transparency. Professionals turn to Glassdoor to research ratings, reviews, salaries and
more at millions of employers, and to <a href="#" @click="clickOutWarning('https://www.fishbowlapp.com/')">Fishbowl</a> by Glassdoor to engage in candid workplace
conversations. Companies use Glassdoor to post jobs and attract talent through <a href="#" @click="clickOutWarning('https://www.glassdoor.com/employers/products/enhanced-profile.htm')">employer branding</a>
and employee insights products. Glassdoor is a subsidiary of Recruit Holdings, a leading global technology
company, and a part of its fast-growing HR Technology business unit. For more information, visit
<a href="#" @click="clickOutWarning('https://www.glassdoor.com/')">www.glassdoor.com</a>.</p>
<p>"Glassdoor" and logo are proprietary trademarks of Glassdoor, Inc.</p>
<br>
<br>
            <p class="news-section-title">Media Contacts:</p>
            <p>WebBank<br>Shannon Hutchings<br>
                <a href="mailto:shannon.hutchings@webbank.com">shannon.hutchings@webbank.com</a>
            </p>
            <br>
            <p>Glassdoor<br><a href="mailto:pr@glassdoor.com">pr@glassdoor.com</a></p>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "WebBank on Glassdoor - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
