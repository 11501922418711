<template>
<div>

    <div class="box partner-box" @click="dignifi_modal = true">
        <img src="https://assets.webbank.com/partners/dignifi.svg" alt="Dignifi Logo">
    </div>

<div class="modal" :class="{'is-active': dignifi_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/dignifi.svg" alt="Dignifi Logo">
          </div>
            <br>
            <p>Through the DigniFi platform, WebBank is the lender of auto-repair installment loans on a national basis. If you have questions about your account please call 855-808-5861, or if you are interested in applying for loan, click
                <a href="#" title="Visit Dignifi" @click="$emit('link-click', 'https://www.dignifi.com/')">here</a>.</p>
                <br>
            <p><a href="#" title="Dignifi Cardholder Agreement" @click="$emit('link-click', 'https://app.dignifi.com/cardholder_agreement')">DigniFi Cardholder Agreement</a></p>
            <br>
            <p><a href="#" title="Dignifi Privacy Policy" @click="$emit('link-click', 'https://dignifi-cdn-production.s3.us-west-2.amazonaws.com/DigniFi_legalDocuments/DigniFi_legalDoc-privacyPolicy.pdf')">Dignifi Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Dignifi Privacy Notice" @click="$emit('link-click', 'https://dignifi-cdn-production.s3.us-west-2.amazonaws.com/DigniFi_legalDocuments/DigniFi_legalDoc-privacyNoticeStatement.pdf')">Dignifi Privacy Notice</a></p>
            <br>
            <button class="button is-primary is-light" @click="dignifi_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="dignifi_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            dignifi_modal: false,
        }
    }
}
</script>
