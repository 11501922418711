<template>

<a name="other-business-policy" id="other-business-policy">&nbsp;</a>

<div style="margin-top: 60px;">
        <p class="is-pulled-right mt-10"><router-link :to="{ name: 'PrivacyPage', hash: '#top' }"><i class="fa-solid fa-arrow-up"></i> Back to Top</router-link></p>
        <h4 class="has-text-primary">WebBank Individuals at Other Businesses Privacy Policy</h4>
<br>
    <p>This privacy policy governs WebBank's collection and use of personal information about individuals associated with third-party businesses, including owners, directors, officers, and employees at current, former, and prospective Brand Partners. WebBank does not and will not sell such information to third parties.</p>
    <br>
    <h4 class="pp-section-title">Questions</h4>
    <br>
    <p>If you have questions about our privacy practices, please contact us at 1-844-994 BANK (2265).</p>
    <br>
    <h4 class="pp-section-title">California Privacy Notice</h4>
    <br>
    <p>If you are a California resident who is a natural person, the California Consumer Privacy Act (“CCPA”) provides certain rights concerning your personal information unless an exception applies. These exceptions include information collected in the course of a business-to-business transaction or activity about individuals associated with another business, so long as such information is not sold to a third party (the “Business-to-Business exception”). As noted above, WebBank does not and will not sell personal information about California natural persons associated with third-party businesses, and, therefore, the CCPA does not apply.</p>
    <br>
</div>
</template>
