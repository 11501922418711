<template>

    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                    <br>
                    <br>
                    <h3 class="is-size-1 has-text-primary section-title">Learn More About Commercial Insurance Premium Financing</h3>
                    <br>
                    <div class="content">
                        <p class="">Commercial Insurance Premium Financing through WebBank's subsidiary, National Partners,
                            helps businesses get the insurance coverage they need while avoiding the high upfront costs of premiums.
                            Our premium finance experts help businesses set up plans that spread out the cost of insurance so they can
                            manage cash flow better and grow.</p>
                            <br>
                        <a href="#" class="button is-primary is-large" title="Visit National Partners"
                            @click="clickOutWarning('https://www.nationalpartners.com/')">Visit The Website</a>

                    </div>
                    <br>
                </div>
                <div class="column">
                    <br>
                    <br>
                    <img src="https://assets.webbank.com/cinsurance.png" class="body-image" alt="WebBank Commercial Insurance" />
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br>
          <div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
          </div>
      </div>
  </div>
</div>

    </section>
</template>

<script>
export default {

    created() {
        document.title = "Commercial Insurance - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },

    }
}
</script>
