<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-hutchings.png" class="is-rounded" alt="Shannon Hutchings">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Shannon Hutchings</h2>
            <h3 class="people-title">SVP People and Brand</h3>
            <p class="people-bio">
Shannon is responsible for employee engagement, the Bank's culture, the holistic brand of WebBank and its Strategy Deployment. She is also responsible for the Bank's Diversity, Equity and Inclusion initiatives. Shannon has over 25 years of banking experience in operations, credit, relationship management, compliance and product development. Prior to WebBank, she had multiple roles at AT&amp;T, CitiBank and Volvo Financial Services. Shannon joined WebBank in 2008 and held positions in various departments including Strategic Partners Oversight, Payments, Vendor Management, and Due Diligence &amp; Implementation. Shannon received a B.S. from the University of Phoenix.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
