<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr22-hero2.png"
        alt="Eraj Asadi Promotion">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr22-small2.png"
        alt="Eraj Asadi Promotion">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">WebBank Announces Eraj Asadi Named SVP - Head of Asset Finance</h2>
            <br>
            <h4 class="news-date">January 25th, 2023</h4>
            <br>
            <p>SALT LAKE CITY - WebBank is pleased to announce the appointment of Eraj Asadi as Senior Vice President - Head of Asset Finance.</p>
            <br>
            <p>Eraj joined WebBank in May 2022, bringing 30+ years of both sell and buy side asset and structured
finance experience through multiple economic cycles. He has extensive experience in building and
running asset finance businesses within a bank infrastructure. Before joining the Bank, Eraj worked at
firms including Perella, Weinberg Partners, Brevet Capital, Rabobank, Lehman Brothers and CS First
Boston.</p>
<br>
<p>In his new role, Eraj will be responsible for leading the Asset Finance team in the expansion, management
and strategy of the Bank's asset finance platform.</p>
<br>
<p>“As WebBank looks to expand its Asset Finance business, it is imperative to have a leader who
understands this industry, and Eraj's experience and knowledge will be paramount for this important
initiative,” said Jason Lloyd, President &amp; CEO. “He has already made meaningful contributions during his
short time here, and I look forward to the additional enhancements he will make in this new role.”</p>
            <br>
            <p class="news-section-title">About WebBank</p>

            <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its inception in
1997, WebBank has originated and funded over $165 billion in consumer and commercial credit products.
As “The Bank Behind the Brand<sup>&reg;</sup>”, WebBank is a national issuer of consumer and small business credit
products through Strategic Partner (Brand) platforms, which include retailers, manufacturers, finance
companies, software as a service (SaaS) and financial technology (FinTech) companies. The Bank is a
leading player in the digital lending space, driving innovation in financial products through embedded
finance with Strategic Partner platforms. WebBank engages in a full range of banking activities including
consumer and commercial loan products, revolving lines of credit, credit cards, private-label card
issuance, auto-refinancing and more. The Bank provides capital in the form of asset-based lending and
other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a targeted focus
on specialty finance assets. The Bank is also a leading provider of commercial insurance premium finance
products through its wholly owned subsidiary National Partners. For more information, please visit
www.webbank.com.</p>
<br>
<br>
            <p class="news-section-title">Contact:</p>
            <p>WebBank<br>Shannon Hutchings<br>
                <a href="mailto:shannon.hutchings@webbank.com">shannon.hutchings@webbank.com</a>
            </p>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "Eraj Asadi - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
