<template>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-5 is-offset-1">
                    <br>
                    <br>
                    <h3 class="is-size-1 has-text-primary section-title">Explore a Strategic Partnership</h3>
                    <br>
                    <p class="">WebBank is a leading player in the digital lending space, driving innovation in
                        financial products through embedded finance with Strategic Partner platforms. We engage in a
                        full range of banking activities including consumer and commercial loan products, revolving
                        lines of credit, credit cards, private-label card issuance, auto refinancing, and more.</p>
                    <br>
                </div>
                <div class="column is-5">
                    <br>
                    <img src="https://assets.webbank.com/R5_C1723.png" class="body-image"
                        alt="Strategic Partnerships at WebBank" />
                </div>
            </div>

            <hr>
            <br>
            <br>

            <section class="section" v-if="success">
                <div class="container">
                    <div class="columns">
                        <div class="column is-8 is-offset-2 has-text-centered">
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                            <span class="icon has-text-success"><i class="fa-solid fa-badge-check fa-10x"></i></span>
                            <br>
                            <br>
                            <br>
                            <br>
                            <p class="has-text-success is-size-3">Thank you! Your message has been sent!</p>
                            <br>
                            <br>
                            <br>
                            <br>
                        </div>
                    </div>
                </div>
            </section>

            <div class="columns" v-else>
                <div class="column is-8 is-offset-2">
                    <div class="has-text-centered">
                        <h3 class="is-size-1 has-text-primary section-title">Let's Work Together</h3>
                        <br>
                        <p>Interested in learning more about a Strategic Partnership with WebBank? Complete the form and
                            someone from our team will reach out to you to start a conversation. </p>
                    </div>
                    <br>
                    <br>

                    <div class="notification is-danger" v-if="captcha_error">
                        Captcha verification failed. Please try again.
                    </div>

                    <div class="notification is-danger" v-if="errors && errors.fields">
                        Please check your input and try again.
                    </div>

                    <Form @submit="submitForm" :validation-schema="yupSchema">
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">First Name</label>
                                    <div class="control">
                                        <Field class="input" type="text" placeholder="John" name="first_name" />
                                    </div>
                                    <ErrorMessage name="first_name" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('first_name')"
                                        class="help has-text-danger">
                                        {{ errors.messages.first_name.join(', ') }}
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Last Name</label>
                                    <div class="control">
                                        <Field class="input" type="text" placeholder="Doe" name="last_name" />
                                    </div>
                                    <ErrorMessage name="last_name" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('last_name')"
                                        class="help has-text-danger">
                                        {{ errors.messages.last_name.join(', ') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Email</label>
                                    <div class="control has-icons-left has-icons-right">
                                        <Field class="input" type="email" name="email" placeholder="me@mycompany.com" />
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                    <ErrorMessage name="email" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('email')"
                                        class="help has-text-danger">
                                        {{ errors.messages.email.join(', ') }}
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Phone</label>
                                    <div class="control">
                                        <Field class="input" v-maska:[options] type="tel" name="phone"
                                            placeholder="xxx-xxx-xxxx" />
                                    </div>
                                    <ErrorMessage name="phone" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('phone')"
                                        class="help has-text-danger">
                                        {{ errors.messages.phone.join(', ') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Company Name</label>
                                    <div class="control">
                                        <Field class="input" type="text" name="company_name" placeholder="My Company" />
                                    </div>
                                    <ErrorMessage name="company_name" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('company_name')"
                                        class="help has-text-danger">
                                        {{ errors.messages.company_name.join(', ') }}
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Website</label>
                                    <div class="control">
                                        <Field class="input" type="text" name="website"
                                            placeholder="https://mycompany.com" />
                                    </div>
                                    <ErrorMessage name="website" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('website')"
                                        class="help has-text-danger">
                                        {{ errors.messages.website.join(', ') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Number of Employees</label>
                                    <div class="control">
                                        <Field class="input" type="text" inputmode="numeric" name="number_employees"
                                            placeholder="1" v-maska:[number_options] />
                                    </div>
                                    <ErrorMessage name="number_employees" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('number_employees')"
                                        class="help has-text-danger">
                                        {{ errors.messages.number_employees.join(', ') }}
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Years in Business</label>
                                    <div class="control">
                                        <Field class="input" type="text" inputmode="numeric" name="years_in_business"
                                            placeholder="1" v-maska:[number_options] />
                                    </div>
                                    <ErrorMessage name="years_in_business" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('years_in_business')"
                                        class="help has-text-danger">
                                        {{ errors.messages.years_in_business.join(', ') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Annual Revenue</label>
                                    <div class="control has-icons-left">
                                        <Field class="input" type="text" inputmode="numeric" name="annual_revenue"
                                            placeholder="xxxxxx" v-maska:[number_options] />
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-dollar-sign"></i>
                                        </span>
                                    </div>
                                    <ErrorMessage name="annual_revenue" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('annual_revenue')"
                                        class="help has-text-danger">
                                        {{ errors.messages.annual_revenue.join(', ') }}
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Projected Year-One Product Volume</label>
                                    <div class="control has-icons-left">
                                        <Field class="input" type="text" inputmode="numeric" name="year_one_volume"
                                            placeholder="xxxxxx" v-maska:[number_options] />
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-dollar-sign"></i>
                                        </span>
                                    </div>
                                    <ErrorMessage name="year_one_volume" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('year_one_volume')"
                                        class="help has-text-danger">
                                        {{ errors.messages.year_one_volume.join(', ') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <label class="label">Amount of Most Recent Capital Raise</label>
                                    <div class="control has-icons-left">
                                        <Field class="input" type="text" inputmode="numeric"
                                            name="recent_capital_amount" placeholder="xxxxxx"
                                            v-maska:[number_options] />
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-dollar-sign"></i>
                                        </span>
                                    </div>
                                    <ErrorMessage name="recent_capital_amount" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('recent_capital_amount')"
                                        class="help has-text-danger">
                                        {{ errors.messages.recent_capital_amount.join(', ') }}
                                    </p>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <label class="label">Date of Most Recent Capital Raise</label>
                                    <div class="control">
                                        <Field class="input" type="date" inputmode="date" name="recent_capital_date"
                                            placeholder="xx/xx/xxxx" />
                                    </div>
                                    <ErrorMessage name="recent_capital_date" class="help has-text-danger" />
                                    <p v-if="errors && errors.fields && errors.fields.includes('recent_capital_date')"
                                        class="help has-text-danger">
                                        {{ errors.messages.recent_capital_date.join(', ') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Product Stage</label>
                            <div class="control">
                                <div class="select">
                                    <Field as="select" name="product_stage">
                                        <option>Choose One...</option>
                                        <option value="in_market">In Market</option>
                                        <option value="in_testing">In Testing</option>
                                        <option value="under_development">Under Development</option>
                                    </Field>
                                </div>
                            </div>
                            <ErrorMessage name="product_stage" class="help has-text-danger" />
                            <p v-if="errors && errors.fields && errors.fields.includes('product_stage')"
                                class="help has-text-danger">
                                {{ errors.messages.product_stage.join(', ') }}
                            </p>
                        </div>
                        <br>


                        <div class="field">
                            <label class="label">Description of Opportunity</label>
                            <div class="control">
                                <Field class="textarea" as="textarea" name="description" placeholder="Your message...">
                                </Field>
                            </div>
                            <ErrorMessage name="description" class="help has-text-danger" />
                            <p v-if="errors && errors.fields && errors.fields.includes('description')"
                                class="help has-text-danger">
                                {{ errors.messages.description.join(', ') }}
                            </p>
                        </div>

                        <div class="field is-grouped">
                            <div class="control">
                                <button class="button is-primary" type="submit"
                                    :class="{ 'is-loading': sending }">Submit</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
import axios from 'axios';
import { vMaska } from "maska"
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
    directives: { maska: vMaska },
    components: {
        Form,
        Field,
        ErrorMessage,
    },

    setup() {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const urlRegex = /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?$/;
        const yupSchema = yup.object().shape({
            first_name: yup.string().required().min(3).max(100).label('First Name'),
            last_name: yup.string().required().min(3).max(100).label('Last Name'),
            email: yup.string().required().matches(emailRegex, 'Invalid email format').label('Email'),
            phone: yup.string().required().min(12).max(12).label('Phone'),
            company_name: yup.string().required().min(3).max(100).label('Company Name'),
            description: yup.string().required().min(3).max(1000).label('Description of Opportunity'),
            website: yup
                        .string()
                        .transform((value, originalValue) => {
                            // Coerce originalValue to a string to ensure .trim() can be safely called
                            const trimmedValue = String(originalValue).trim();
                            return trimmedValue === "" ? undefined : trimmedValue;
                        })
                        // .required('Website is required')
                        .matches(urlRegex, 'Invalid url format')
                        .max(100)
                        .label('Website'),
            recent_capital_amount: yup
                            .number('Most Recent Capital Raise must be a number')
                            .transform((value, originalValue) => originalValue.trim() === "" ? undefined : value)
                            .required('Most Recent Capital Raise is required')
                            .positive('Most Recent Capital Raise must be a positive number')
                            .integer('Most Recent Capital Raise must be an integer')
                            .label('Most Recent Capital Raise'),
            annual_revenue: yup
                            .number('Annual Revenue must be a number')
                            .transform((value, originalValue) => originalValue.trim() === "" ? undefined : value)
                            .required('Annual Revenue is required')
                            .positive('Annual Revenue must be a positive number')
                            .integer('Annual Revenue must be an integer')
                            .label('Annual Revenue'),
            year_one_volume: yup
                            .number('Year One Volume must be a number')
                            .transform((value, originalValue) => originalValue.trim() === "" ? undefined : value)
                            .required('Year One Volume is required')
                            .positive('Year One Volume must be a positive number')
                            .integer('Year One Volume must be an integer')
                            .label('Year One Volume'),
            number_employees: yup
                            .number('Number of Employees must be a number')
                            .transform((value, originalValue) => originalValue.trim() === "" ? undefined : value)
                            .required('Number of Employees is required')
                            .positive('Number of Employees must be a positive number')
                            .integer('Number of Employees must be an integer')
                            .label('Number of Employees'),
            years_in_business: yup
                            .number('Years in Business must be a number')
                            .transform((value, originalValue) => originalValue.trim() === "" ? undefined : value)
                            .required('Years in Business is required')
                            .positive('Years in Business must be a positive number')
                            .integer('Years in Business must be an integer')
                            .min(1, 'Years in Business must be at least 1')
                            .max(100, 'Years in Business must be less than 100')
                            .label('Years in Business'),
            recent_capital_date: yup
                            .date()
                            .transform((value, originalValue) => {
                                // Ensure originalValue is treated as a string to safely call .trim() and check for emptiness
                                const stringValue = originalValue != null ? String(originalValue).trim() : "";

                                // Check if the stringValue is empty or if creating a Date object results in an invalid date
                                if (stringValue === "" || isNaN(new Date(stringValue).getTime())) {
                                return undefined; // Return undefined to trigger required validation or bypass the transformation
                                }

                                // Attempt to create a Date object from the stringValue
                                const dateValue = new Date(stringValue);
                                // Check if the dateValue is valid
                                return !isNaN(dateValue.getTime()) ? dateValue : undefined;
                            })
                            .required('Date of Most Recent Capital Raise is required')
                            .max(new Date())
                            .label('Date of Most Recent Capital Raise'),
            product_stage: yup.string().required().label('Product Stage'),
        });
        return { yupSchema };
    },

    created() {
        document.title = "Partner With Us - WebBank";
    },

    data: function () {
        return {
            sending: false,
            success: false,
            errors: [],
            captcha_error: null,
            options: {
                mask: "###-###-####",
                eager: false
            },
            number_options: {
                mask: "##########",
                eager: false,
                reversed: true,
            },
        }
    },

    methods: {
        submitForm: function (values) {
            this.errors = [];
            this.sending = true;

            this.$recaptcha('become_partner_form')
                .then(token => {
                    // You can now use this token to verify the captcha response on your server
                    // console.log(token);
                    values['form_name'] = 'become_partner';
                    values['g-recaptcha-response'] = token;
                    let convert = ['recent_capital_amount', 'annual_revenue', 'year_one_volume', 'number_employees', 'years_in_business'];
                    convert.forEach((key) => {
                        values[key] = parseInt(values[key]);
                    });
                    console.log('posting values: ' + JSON.stringify(values));

                    axios.post(process.env.VUE_APP_MAIL_ENDPOINT, values)
                        .then(result => {
                            console.log('result: ' + JSON.stringify(result));
                            this.success = true;
                        }).catch(error => {
                            console.log('STATUS: ' + error.response.status);
                            if (error.response.status === 403) {
                                this.captcha_error = error.message;
                            } else {
                                // can I still reach the "body" of the response?
                                console.log('error.body: ' + JSON.stringify(error.response.data));
                                this.errors = this.processErrors(error.response.data);
                                // console.log('ERRORS: ' + JSON.stringify(this.errors));
                            }
                        }).finally(() => this.sending = false);
                });
        },
        processErrors: function (apiResponse) {
            const errors = {
                fields: Object.keys(apiResponse), // Array of field names with errors
                messages: apiResponse // Keep the original structure for message access
            };
            return errors;
        }


    }
}
</script>
