<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr19-hero.png"
                            alt="Avant Secures Funding">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr19-small.png"
                            alt="Avant Secures Funding">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">Avant Secures $250 Million In Funding From Ares Management Corporation</h2>
            <br>
            <h4 class="news-date">December 7th, 2022</h4>
            <br>
            <p>Avant, LLC, a credit-first financial technology company<sup>*</sup> that provides access to innovative financial solutions to underserved non-prime consumers ("Avant"), today announced it secured $250 million in corporate debt and redeemable preferred equity from Ares Management Corporation (“Ares”).</p>
            <br>
            <p>"Avant has established a track record of success over the course of a decade of delivering on our mission to empower customers to reach their goals with innovative solutions designed to move their financial lives forward," said Matt Bochenek, CEO of Avant. "This capital infusion from Ares will further fuel our credit portfolio and next-gen platform, enabling stronger competitive advantage."</p>
            <br>
            <p>This funding follows a $250 million securitization in September and several other success markers in 2022. In January, the Avant Credit Card, issued by WebBank, surpassed one million customers. In August, the financial technology company unveiled a new multi-year exclusive partnership with Major League Soccer (“MLS”) for a co-branded fan affinity credit card that will be powered by Avant and issued by WebBank.</p>
            <br>
            <p>“We are excited to support the Avant platform through this next phase of growth,” said Jeffrey Kramer, Partner in the Ares Alternative Credit strategy. “Our flexible capital base enabled us to meet Avant’s evolving needs, and we look forward to continuing our long-standing relationship.”</p>
            <br>
            <p>Avant's September securitization brought the total amount of asset-backed debt financing commitments secured by Avant in 2022 to $1.1 billion and Avant's total commitments to over $1.6 billion to support its consumer credit portfolios. These commitments illustrate Avant's ability to successfully navigate economic cycles and increase the resiliency of Avant's funding profile. To date, Avant has provided access to $9 billion in credit serving almost three million customers.</p>
            <br>
            <p>FT Partners served as exclusive strategic and financial advisor to Avant and its Board of Directors on this transaction. For more information, visit Avant's website:
                <a href="#" @click="clickOutWarning('https://www.avant.com/')">https://www.avant.com/</a>.</p>
            <br>
            <p class="news-section-title">About Avant</p>
<p>Avant is a credit-first financial technology company<sup>*</sup> that provides access to
innovative financial solutions, including personal loans and credit cards,
championing customers on their financial journey. Through a combination of
technology, analytics, and superior customer service, the Avant platform is designed
to serve the non-prime U.S. consumer, largely underserved by traditional financial
institutions. We're on a mission to move our customers Financially Forward<sup>&trade;</sup> to
reach their financial goals. Since 2012, Avant has connected almost 3 million
customers to $8 billion in loans and over 1 million credit cards. Avant has been
featured in The Wall Street Journal, The New York Times, TechCrunch, Fortune, and
Bloomberg.</p>
<p><small><em><sup>*</sup>Avant, LLC is a financial technology company, not a bank. Avant-branded credit products are issued by WebBank.</em></small></p>
<br>
<p class="news-section-title">About Ares Management Corporation</p>
<p>Ares Management Corporation (NYSE: ARES) is a leading global alternative
investment manager offering clients complementary primary and secondary
investment solutions across the credit, private equity, real estate and infrastructure
asset classes. We seek to provide flexible capital to support businesses and create
value for our stakeholders and within our communities. By collaborating across our
investment groups, we aim to generate consistent and attractive investment returns
throughout market cycles. As of September 30, 2022, Ares Management
Corporation's global platform had approximately $341 billion of assets under
management, with over 2,450 employees operating across North America, Europe,
Asia Pacific and the Middle East. For more information, please visit
<a href="#" @click="clickOutWarning('https://www.aresmgmt.com/')">www.aresmgmt.com</a>.</p>
<br>
<p class="news-section-title">About Alternative Credit</p>
<p>Ares' Alternative Credit strategy focuses on lending to and investing in assets that
generate contractual cash flows and fills gaps in the capital markets between credit,
private equity and real estate. Ares Alternative Credit targets investments across
the capital structure in specialty finance, lender finance, loan portfolios, equipment
leasing, structured products, net lease, cash flow streams (royalties, licensing,
management fees), and other asset-focused investments. Co-Headed by Keith
Ashton and Joel Holsinger, Ares Alternative Credit leverages a broadly skilled and
cohesive team of approximately 50 investment professionals as of September 30,
2022. Aligning Ares’ investment activities with its societal impact, Ares and
Alternative Credit portfolio managers have committed to donating a portion of
carried interest from certain Alternative Credit funds to global health and education
charities.</p>
<br>
            <p class="news-section-title">Media Contact:</p>
            <p>Vested<br>
                <a href="mailto:avant@fullyvested.com">avant@fullyvested.com</a>
            </p>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "Avant Secures Funding - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
