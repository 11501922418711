<template>
    <section class="hero savings-hero is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-10">
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                    <div class="home-box has-text-right">
                            <p class="title hero-title">
                                Savings Accounts
                            </p>
                            <br>
                        </div>
                    </div>
                    </div>

                </div>
            </section>
        </div>
    </section>

    <div class="blue-bg is-hidden-widescreen">
        <section class="savings-hero-mobile">
        </section>

        <section class="section">
            <div class="container">
                <p class="title hero-title-mobile">
                    Savings Accounts
                </p>
            </div>
        </section>
    </div>

    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8">
                    <div class="content">
                    <h3 class="is-size-1 has-text-primary section-title">See How WebBank Helps Your Money Grow Faster</h3>
                    <br>
                    <ul>
                        <li>No monthly fees</li>
                        <li>Competitive interest rates</li>
                        <li>Interest compounded daily for faster savings growth</li>
                        <li>Convenient online account management</li>
                        <li>FDIC insured deposits up to maximum allowed by law</li>
                    </ul>
                    </div>
                </div>
                <div class="column has-text-centered">

                        <div class="box wb-box">
                            <br>
                            <br>
                            <br>
                            <span class="icon diff-icon"><i class="fa-light fa-coins fa-4x"></i></span>
                            <br>
                            <br>
                            <h4 class="box-title">Rates and Fees</h4>
                            <p class="">Compare and see. WebBank's competitive rates and no monthly fees make our savings accounts some of the best in banking.</p>
                            <br>
                            <router-link to="/rates-fees" class="button is-primary">Learn More</router-link>
                        </div>
                </div>
            </div>
        </div>
    </section>
    <br>
</template>

<script>
export default {

}
</script>
