<template>
    <section class="bottom-hero2 is-hidden-mobile">
        <div class="container">
            <div class="columns">
                <div class="column is-10">

                    <div class="hero-box-left3">
                        <div class="columns">
                            <div class="column has-text-left" :class="column_width">
                                <div class="">
                                    <p class="hero-title" v-html="hero_title"></p>
                                    <p class="hero-subtitle" style="color: black;" v-html="hero_subtitle"></p>
                                    <br>
                                    <router-link to="/working-at-webbank" class="button is-large is-primary"
                                        title="Work at WebBank!" v-if="parent === 'home'">
                                        <span class="is-hidden-mobile">Explore </span> &nbsp;Open Positions
                                    </router-link>
                                    <router-link :to="button_route" class="button is-primary is-large is-primary"
                                        title="Work at WebBank!" v-else>
                                        {{ button_text }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </section>

    <section class="is-hidden-tablet">
        <img src="https://assets.webbank.com/home4.png" alt="WebBank" class="is-fullwidth">
        <div class="container p-4">
            <p class="hero-title" v-html="hero_title"></p>
            <p class="hero-subtitle" style="color: black;" v-html="hero_subtitle"></p>
            <br>
            <router-link :to="button_route" class="button is-primary is-fullwidth is-light" title="Work at WebBank!">
                {{ button_text }}
            </router-link>
        </div>
    </section>
</template>

<script>
export default {
    name: 'CareersHero',
    props: ['parent'],
    data: function () {
        return {
            column_width: 'is-6',
            hero_title: 'Not Just A Job,<br>Discover a Great Career.',
            hero_subtitle: "Work with the most talented people in our industry.<br>Join us and see where new opportunities with WebBank can take you.",
            button_text: 'Explore Open Positions',
            button_route: "/working-at-webbank",
        }
    },

    beforeMount() {
        console.log(' =====> parent is: ' + this.parent);
        if (this.parent === 'business') {
            this.hero_title = "Support for Interests That are Important to You";
            this.hero_subtitle = "We hire more than just great banking and finance talent. We hire people who are also great citizens, neighbors, and difference makers. Let's talk about a career at WebBank and about how we can support interests you have that go beyond the Bank.";
        } else if (this.parent === 'deposits' || this.parent === 'ourpeople') {
            this.hero_title = "Let's Talk";
            this.hero_subtitle = "Reach out to a WebBank Customer Service Representative if you have questions about the products we offer.";
            this.button_text = "Contact an Associate";
            this.button_route = "/contact-us";
        } else if (this.parent === 'about') {
            this.hero_title = "Make an Impact Every Day";
            this.hero_subtitle = "Great benefits? You bet. Flexible workplace? You got it. But we think what will inspire you the most is a chance to make a difference every day with a company brimming with growth opportunities that are there for the taking.";
            this.button_text = "Learn More";
        } else if (this.parent === 'working') {
            this.hero_title = "Reach Out and Get the Full Story";
            this.hero_subtitle = "From our Diversity Equity & Inclusion initiatives to our referral bonus program, there's more to learn about working at WebBank. Get the full story. Find the opening that looks right to you and apply today. Let's take the conversation, and your career, to the next level.";
            this.button_text = "See All Open Jobs";
            this.column_width = "is-8";
        } else if (this.parent === 'business-brands') {
            this.hero_title = "Get a Fresh Start and a Career with Impact";
            this.hero_subtitle = "At WebBank, we're built for growth—for business and our people. Check us out and discover opportunities to step into a banking career that can go as far as you want to take it.";
            this.button_text = "Learn More";
            this.column_width = "is-8";
        }
    },
}
</script>
