<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/petal.svg" alt="Petal Card Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/petal.svg" alt="Petal Card Logo">
          </div>
            <br>
            <p>In partnership with Petal Card, Inc., WebBank is the issuer and lender of the Petal Visa credit card.
                For questions about your Petal account, please call 855-697-3825. If you are interested in applying for credit, click
                <a href="#" title="Apply at Petal" @click="$emit('link-click', 'https://www.petalcard.com/')">here</a>.</p>
            <br>
            <p><a href="#" title="Petal Cardholder Agreement" @click="$emit('link-click', 'https://www.petalcard.com/legal#the-petal-visa-credit-card-cardholder-agreement')">Petal Cardholder Agreement</a></p>
            <br>
            <p><a href="#" title="Petal Privacy Policy and Privacy Notice" @click="$emit('link-click', 'https://www.petalcard.com/legal#general-privacy-policy')">Petal Privacy Policy and Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://www.petalcard.com/legal#webbank-general-privacy-policy')">WebBank Privacy Notice</a></p>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
