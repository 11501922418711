<template>
<div>

    <div class="box partner-box" style="display: grid; place-items: center;" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/bgmoney.png" style="max-width: 85%;" alt="BMG Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div style="display: grid; place-items: center;">
            <img src="https://assets.webbank.com/partners/bgmoney.png" style="max-width: 400px;" alt="BMG Logo">
          </div>
            <br>
            <p>In partnership with BMG Money, WebBank is the lender of closed-end consumer installment loans
                <a href="#" title="BMG Money" @click="$emit('link-click', 'https://www.bmgmoney.com')">click here</a>
                for more information. To speak with someone about your BMG Money installment loan, please call
                BMG Money at <a href="tel:+18003168507">800-316-8507</a>
                or send an email to: <a href="mailto:customer.service@bmgmoney.com">customer.service@bmgmoney.com</a>.</p>
            <br>
            <p><a href="#" title="BMG Money Privacy Policy" @click="$emit('link-click', 'https://www.bmgmoney.com/privacy-policy')">BMG Money Privacy Policy</a></p>
            <br>
            <p><a href="#" title="BMG Money Privacy Notice" @click="$emit('link-click', 'https://www.bmgmoney.com/federal-financial-privacy-notice')">BMG Money Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://www.bmgmoney.com/federal-financial-privacy-notice')">WebBank Privacy Notice</a></p>
            <br>

            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
