<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/sezzle_logo.png" alt="Sezzle Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/sezzle_logo.png" alt="Sezzle Logo">
          </div>
            <br>
            <p>In partnership with Sezzle, WebBank is the lender of consumer closed-end loans issued through the Sezzle app.
                For more information, please <a href="#" title="Sezzle" @click="$emit('link-click', 'https://sezzle.com/');">click here</a>. To speak with someone about your
                Sezzle installment loan, please call Sezzle at 1-888-540-1867.</p>
            <br>
            <p><a href="#" title="Sezzle Privacy Policy" @click="$emit('link-click', 'https://legal.sezzle.com/privacy')">Sezzle Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Sezzle Privacy Notice" @click="$emit('link-click', 'https://legal.sezzle.com/privacy/en-us/')">Sezzle Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://legal.sezzle.com/privacy/en-us/')">WebBank Privacy Notice</a></p>
            <br>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
