<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/can-capital.svg" alt="CAN Capital Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/can-capital.svg" alt="CAN Capital Logo">
          </div>
            <br>
            <p>In partnership with CAN Capital, WebBank is an issuer and lender of business financing through the CAN Capital platform.
                If you have questions about your account please call 877-500-8282, or if you are interested in applying for a working
                capital loan through CAN Capital, click <a href="#" @click="$emit('link-click', 'https://www.cancapital.com/')">here</a>.</p>
            <br>
            <p><a href="#" title="CAN Capital Privacy Policy" @click="$emit('link-click', 'https://www.cancapital.com/policy/privacy-policy/')">CAN Capital Privacy Policy</a></p>
            <br>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
