<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/dell.jpg" alt="Dell Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/dell.jpg" alt="Dell Logo">
          </div>
            <br>
            <p>In partnership with Dell Financial Services, WebBank is the issuer and lender of Dell Business Credit.</p>
            <br>
            <p>Dell Business Credit enables small businesses to preserve their cashflow with an IT-dedicated revolving line of credit.
                Businesses can easily apply and enjoy fast credit decisions, low monthly payments, and no annual fees.
                If you are interested in applying, click
                <a href="#" title="Apply at Dell Business Credit" @click="$emit('link-click', 'https://www.dell.com/financing/comm/dbc/apply/marcom/creditapp?cookieUpdated=true')">here</a>.
                If you have any questions about Dell Business Credit, you can learn more
                <a href="#" title="Dell Business Credit Support" @click="$emit('link-click', 'https://www.dell.com/en-us/work/lp/dell-business-credit')">here</a> or
                you can speak to a Dell Financial Services representative by calling 866-413-3355.</p>
            <br>
            <p><a href="#" title="Dell Business Credit Agreement" @click="$emit('link-click', 'https://www.dell.com/downloads/global/services/sd/dbc_credit_agreement.pdf')">Dell Business Credit Agreement</a></p>
            <br>
            <p><a href="#" title="Dell Business Privacy Policy" @click="$emit('link-click', 'https://www.dell.com/learn/us/en/uscorp1/policies-privacy')">Dell Business Privacy Policy</a></p>
            <br>

            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
import { CDN_URL } from '../constants';

export default {

    data: function () {
        return {
            partner_modal: false,
            cdn_url: CDN_URL,
        }
    }
}
</script>
