<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-sanz.png" class="is-rounded" alt="Parris Sanz">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Parris Sanz</h2>
            <h3 class="people-title">EVP General Counsel</h3>
            <p class="people-bio">
Parris joined WebBank in 2021, bringing more than 25 years of legal experience and over 16 years as a General Counsel and executive in the Fintech industry. Before joining WebBank, Mr. Sanz was General Counsel, Chief Compliance Officer and SVP of Operations of Petal Card, Inc. Before Petal, Mr. Sanz was CEO of CAN Capital, Inc. where he was previously EVP, Chief Legal Officer and Chief Compliance Officer for over 12 years. Previously, Mr. Sanz was an attorney in private practice at the law firms of Latham & Watkins, LLP and Paul Hastings LLP, where he handled a wide variety of corporate, finance, and commercial transactions. Mr. Sanz received his J.D. from Harvard Law School and a Bachelor of Arts degree from U.C. Berkeley where he graduated with High Honors and Phi Beta Kappa.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
