<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr9-hero2.png" alt="WebBank and Avant">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr9-small.png" alt="WebBank and Avant">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">WebBank Invests $250 Million in Avant to Support Growth of Credit Card Program</h2>
            <h4 class="news-page-title">Investment Enables Continued Growth of Avant<sup>&reg;</sup> MasterCard<sup>&reg;</sup> Credit Card</h4>
            <br>
            <h4 class="news-date">July 14th, 2022</h4>
            <br>
            <p>WebBank announced today it has committed to invest up to $250 million in the obligations of a securitization
                structure sponsored by Avant that are secured by credit card receivables. The investment will be used to meet
                the continuing demand and growth of the Avant® MasterCard<sup>&reg;</sup> Credit Card issued by WebBank.  WebBank's
                investment will be applied over a three-year revolving term.</p>
            <br>
            <p>“WebBank and Avant have a longstanding partnership driving access to capital to consumers through multiple innovative
                credit products including the Avant Credit Card,” said Jason Lloyd, President &amp; CEO of WebBank. “The Bank is
                pleased to provide an additional investment in the Avant Credit Card program to provide the necessary capital for
                long-term growth.”</p>
            <br>
            <p>“WebBank and Avant have partnered closely over the last almost decade to expand access to affordable, transparent
                financial products, and together to help move our customers' financial lives forward,” said Matt Bochenek, CEO
                of Avant. “Deepening our partnership through this relationship will allow us to facilitate access to credit cards
                for hundreds of thousands of additional underserved customers over the next three years.”</p>
            <br>
            <p class="news-section-title">About WebBank</p>
            <p>WebBank is a Utah chartered  Industrial  Bank  headquartered  in  Salt  Lake  City,  Utah.  Since  its inception  in  1997,
                WebBank  has  originated  and  funded  over  $150  billion  in  consumer  and commercial credit products.  As "The Bank
                Behind the Brand<sup>&reg;</sup>", WebBank is a national issuer of consumer and small business credit products through
                Strategic Partner (Brand) platforms, which include  retailers,  manufacturers,  finance  companies,  software  as  a
                service  (SaaS)  and  financial technology (FinTech) companies.  The Bank is a leading player in the digital lending
                space, driving innovation  in  financial  products  through  embedded  finance  with  Strategic  Partner  platforms.
                WebBank engages in a full range of banking activities including consumer and commercial loan products, revolving lines
                of credit, credit cards, private-label card issuance, auto-refinancing and more. The Bank provides capital in the form
                of asset-backed lending and other credit facilities to Strategic  Partner  platforms,  credit  funds,  and  other  lenders
                with  a  targeted  focus  on  specialty finance  assets.  The  Bank  is  also  a  leading  provider  of  commercial
                insurance  premium  finance products  through  its  wholly  owned  subsidiary  National  Partners.  For  more  information,
                please visit
                <a href="#" @click="clickOutWarning('https://webbank.com/')">webbank.com</a>.</p>
            <br>
            <p class="news-section-title">About Avant</p>
            <p>Avant provides access to a full suite of digital financial solutions, including personal loans, credit cards,  mobile  banking,
                and  auto  refinance,  to  everyday  American  consumers.  Through  a combination  of  technology,  analytics,  and  superior
                customer  service,  Avant  gives  underserved consumers  access  to  credit  with  innovative  products  that  simplify  and
                improve  their  financial journeys. Since 2012, Avant has connected more than 2 million customers to $8 billion in loans and
                over 1 million credit cards. A credit-first neobank, Avant has been featured in The Wall Street Journal,  The  New  York  Times,
                TechCrunch,  Fortune  and  Bloomberg,  and  has  raised  over  $650 million of equity capital. For more information on Avant,
                visit
                <a href="#" @click="clickOutWarning('https://avant.com/')">www.avant.com</a>.</p>
                <p>Avant, LLC is a financial technology company, not a bank. Banking services are provided through Evolve Bank
                &amp; Trust. Avant-branded credit products are issued by WebBank.</p>
            <br>
            <p class="news-section-title">Contact</p>
            <p>Shannon Hutchings<br>
                shannon.hutchings@webbank.com</p>
            <br>
                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "Capital on Tap - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
