<template>

<a name="brand-partners" id="brand-partners">&nbsp;</a>


<div style="margin-top: 60px;">
        <h4 class="has-text-primary">Brand Partner Programs’ Privacy Policies</h4>
<br>
<p>Most of our current and former customers became our customers through one of our Brand
Partner programs. Our current Brand Partner programs are generally listed through the Brand
Partners’ links at the top of our website.</p>
<p>If you are a consumer, you would have been provided with a Gramm-Leach-Bliley Act
(“GLBA”) Privacy Notice from us prior to your becoming our customer or as otherwise required
by applicable law. You may have also been provided a GLBA Privacy Notice after you became
our customer to the extent required by applicable law. Our Brand Partner may have also
provided you with their own GLBA Privacy Notice and/or Privacy Policy. These GLBA Privacy
Notices and Privacy Policies may be available through the Brand Partners’ links at the top of our
website or by visiting the website of our Brand Partner.</p>
<p>If you are a business, our Brand Partner will maintain the Privacy Policy for the Brand Partner
program on their website and may have provided you with a copy prior to you becoming our
customer. These Privacy Policies may be available through the Brand Partners’ links at the top of our website or by visiting the website of our Brand Partner.</p>
<br>
</div>
</template>
