<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/prosper.svg" alt="Prosper Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/prosper.svg" alt="Prosper Logo">
          </div>
            <br>
            <p>In partnership with Prosper Marketplace, WebBank is the lender of fixed-rate, unsecured personal
                loans for debt consolidation, home improvements, and many other occasions. To speak to someone
                about your Prosper account, please call 1-(866) 615-6319. To apply for a loan through Prosper click
                <a href="#" title="Apply at Prosper" @click="$emit('link-click', 'https://www.prosper.com/')">here</a>.</p>
            <br>
            <p><a href="#" title="Prosper Privacy Policy and Privacy Notice" @click="$emit('link-click', 'https://www.prosper.com/plp/legal/privacy-policy/')">Prosper Privacy Policy and Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Policy" @click="$emit('link-click', 'https://www.prosper.com/WebBank-privacy-policy.pdf')">WebBank Privacy Policy</a></p>
            <br>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
