<template>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                        <br>
                        <br>
                    <h3 class="is-size-1 has-text-primary section-title">About WebBank</h3>
                    <br>
                    <div class="content">
                        <p>WebBank (“Bank”) is a Utah chartered industrial bank headquartered in Salt Lake City, Utah.
                            The Bank has originated and funded over $175 billion in consumer and commercial credit products since
                            its inception, helping millions of people and small businesses get affordable credit products.</p>

                    </div>
                    <br>
                </div>
                <div class="column">
                    <br>
                    <img src="https://assets.webbank.com/about1.png" class="body-image" alt="Life at WebBank" />
                </div>
            </div>
        </div>
    </section>
    <section class="section blue-bg">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                    <br>
                    <img src="https://assets.webbank.com/about2.png" class="body-image" alt="What We Do at WebBank" />
                </div>
                <div class="column">
                    <h3 class="is-size-1 has-text-white section-title">What We Do</h3>
                    <br>
                    <div class="content">
                       <p>As The Bank Behind the Brand<sup>&reg;</sup>, WebBank is a national issuer of consumer and small business credit products through Strategic Partner (Brand) platforms, which include retailers, manufacturers, finance companies, software as a service (SaaS), and financial technology (fintech) companies. The Bank is a leading player in the digital lending space, driving innovation in financial products through embedded finance with Strategic Partner platforms.</p>
                        <br>
                        <p>WebBank engages in a full range of banking activities including consumer and commercial loan products, revolving lines of credit, credit cards, private-label card issuance, auto-refinancing and more. WebBank provides capital in the form of asset-based lending and other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a targeted focus on specialty finance assets. The Bank is also a leading provider of commercial insurance premium finance products through its wholly owned subsidiary, National Partners.</p>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </section>
        <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                        <br>
                        <br>
                    <h3 class="is-size-1 has-text-primary section-title">Our Mission</h3>
                    <br>
                    <div class="content">
                        <p>Empowering fintech strategic partners to drive innovation and financial inclusion. </p>

                    </div>
                    <br>
                </div>
                <div class="column">
                    <br>
                    <img src="https://assets.webbank.com/about3.png" class="body-image" alt="WebBank's Mission" />
                </div>
            </div>
        </div>
    </section>

    <!--
    <section class="section grey-bg">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                    <img src="https://assets.webbank.com/2022_webbank_25_100-3.png" class="body-image" alt="25 Year Anniversary" />
                </div>
                <div class="column">
                        <div class="content">
                    <h3 class="is-size-1 has-text-primary section-title">WebBank's CRA Public File</h3>
                       <p>WebBank is committed to community reinvestment and is pleased to provide information for public inspection under the Community Reinvestment Act (CRA) by clicking the button below.</p>
                       <br>
                       <a class="button is-primary is-large" href="https://assets.webbank.com/2024_CRA_Public_File_March_31-2024.pdf" target="_blank">Access CRA Public File</a>
                        </div>
                    <br>
                </div>
            </div>
        </div>
    </section>
    -->

    <section class="section">
        <div class="container">

                    <div class="has-text-centered">
                    <h3 class="is-size-1 has-text-primary section-title">Our Offices</h3>
                    </div>
                    <br>

            <div class="columns">
                <div class="column">
                        <div class="box">
                                <h3 class="is-size-3 has-text-primary section-title">Salt Lake City, Utah</h3>
                                        <address>215 South State Street, Suite 1000<br>Salt Lake City, UT 84111</address>
                                <p>1-844-994-BANK (2265)</p>
                                <br>
                                <p>9am - 5pm Weekdays<br>
                                        9am - 1pm Saturday<br>
                                        Closed Sunday</p>
                        </div>
                </div>
                <div class="column">
                        <div class="box">
                                <h3 class="is-size-3 has-text-primary section-title">Summit, New Jersey</h3>
                                        <address>1 Deforest Avenue,<br>Suite 100<br>Summit, NJ 07901 </address>
                                <br>
                                <br>
                                <p>9am - 5pm Weekdays<br>
                                        Closed Saturday and Sunday</p>
                        </div>
                </div>
            </div>
        </div>
    </section>

    <CareersHero parent="about"></CareersHero>

</template>

<script>
import CareersHero from '@/components/CareersHero.vue'
export default {
    name: 'AboutPage',
    components: {
        CareersHero
    },

    created() {
        document.title = "About Us - WebBank";
    },

}
</script>
