<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/Scratch-logo-vertical.png" alt="Scratch Pay Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <div class="columns">
                <div class="column is-6 is-offset-3">
                    <figure class="image">
                <img src="https://assets.webbank.com/partners/Scratch-logo-horizontal.png" alt="Fingerhut Fetti Logo">
                    </figure>
                </div>
            </div>
          </div>
            <br>
            <p>In partnership with Scratch Financial,  Inc.  (DBA  “Scratchpay”), WebBank is the lender of the Scratchpay Credit Account
                providing payment plans with simple, readily available financing options to pay for veterinary care, medical, dental and
                other healthcare.  If you are interested in learning about financing options, click
                <a href="#" title="Scratchpay Credit Account" @click="$emit('link-click', 'https://scratchpay.com/')">here</a>. If you
                have questions about your Scratchpay Credit Account, click
                <a href="#" title="Contact Scratchpay Credit" @click="$emit('link-click', 'https://scratchpay.com/contact')">here</a>.</p>

            <br>
            <p><a href="#" title="Scratchpay Privacy Policy" @click="$emit('link-click', 'https://scratchpay.com/legal/privacy')">Scratchpay Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Scratchpay Privacy Notice &amp; WebBank Privacy Notice" @click="$emit('link-click', 'https://scratchpay.com/legal/privacy-notice')">Scratchpay Privacy Notice &amp; WebBank Privacy Notice</a></p>
            <br>
            <p><a href="#" title="California Consumer Privacy Act and DSAR form" @click="$emit('link-click', 'https://scratchpay.com/legal/ccpa-dsar-form')">California Consumer Privacy Act and DSAR form</a></p>
            <br>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
