<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr43_hero.png" alt="BEST PLACES TO WORK IN FINANCIAL TECHNOLOGY 2024">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr43_small.png" alt="BEST PLACES TO WORK IN FINANCIAL TECHNOLOGY 2024">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">WEBBANK, A STEEL PARTNERS COMPANY, HONORED AS ONE OF AMERICAN BANKER’S BEST PLACES TO WORK IN FINANCIAL TECHNOLOGY 2024</h2>
                        <br>
                        <p><em><small>May 16, 2024</small></em></p>
                        <br>
                        <p>SALT LAKE CITY - WebBank, a wholly owned subsidiary of Steel Partners Holdings (NYSE: SPLP), has been named one of the <strong>2024 Best Places to Work in Financial Technology</strong>. This is the second year in a row WebBank has been named a winner, ranking ninth on the list of forty companies. The awards program was created in 2017 and is a project of Arizent and Best Companies Group.</p>
                        <br>
                        <p>This annual survey and awards program is designed to identify, recognize, and honor the best employers in the financial technology industry. Companies recognized on this year’s list operate in and serve companies and consumers in a wide range of financial services including banking and mortgages, insurance, payments and financial advisory.</p>
                        <br>
                        <p>“Cultivating a workplace culture where innovation thrives and every team member feels empowered to contribute to our success is central to our vision,” said Jason Lloyd, WebBank President & CEO. “Being recognized as a 2024 Best Place to Work in Fintech is a testament to the dedication of our incredible team who continually drives us towards greater success.”</p>
                        <br>
                        <p>“I am thrilled to celebrate WebBank’s recognition as a Best Place to Work in Fintech,” said Warren Lichtenstein, Executive Chairman of Steel Partners Holdings. “This achievement is a testament to WebBank’s best-in-class culture that is deeply rooted in our shared values of teamwork, respect, integrity and commitment.”</p>
                        <br>
                        <p>The 2024 list included 40 companies and was published by Arizent brands <a @click="clickOutWarning('https://www.americanbanker.com');">American Banker</a>, <a @click="clickOutWarning('https://www.nationalmortgagenews.com');">National Mortgage News</a>, <a @click="clickOutWarning('https://www.americanbanker.com/payments');">PaymentsSource</a>, <a @click="clickOutWarning('https://www.financial-planning.com');">Financial Planning</a> and <a @click="clickOutWarning('https://www.dig-in.com/');">Digital Insurance</a> on May 6, 2024.</p>
                        <br>
                        <p>"Though the fintech industry has faced many headwinds in the past year, the sector is still a vital cog in financial services, bringing ease of use and convenience to consumers and businesses," said Penny Crosman, executive editor, technology at American Banker. "This year's list honors companies that continue to innovate and create jobs for technologists."</p>
                        <br>
                        <p>To be considered for participation, companies must provide technology products, services or solutions that enable the delivery of financial services. Companies must also have been in business for at least one full year and employ at least 15 people in the U.S.</p>
                        <br>
                        <p>Companies from across the United States entered a two-part survey process to determine Arizent’s Best Places to Work in Financial Technology. The first part consisted of evaluating each nominated company's workplace policies, practices, philosophy, systems, and demographics. The second part consisted of an employee survey to measure the employee experience. The combined scores determined the top companies and the final ranking. Best Companies Group managed the overall registration and survey process, analyzed the data, and determined the final ranking.</p>
                        <br>
                        <p><strong>About WebBank</strong></p>
                        <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its inception in 1997, WebBank has originated and funded over $190 billion in consumer and commercial credit products.  As “The Bank Behind the Brand®”, WebBank is a national issuer of consumer and small business credit products through Strategic Partner (Brand) platforms, which include retailers, manufacturers, finance companies, software as a service (SaaS) and financial technology (FinTech) companies.  The Bank is a leading player in the digital lending space, driving innovation in financial products through embedded finance with Strategic Partner platforms.  WebBank engages in a full range of banking activities including consumer and commercial loan products, revolving lines of credit, credit cards, private-label card issuance, auto-refinancing and more. The Bank provides capital in the form of asset-based lending and other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a targeted focus on specialty finance assets. The Bank is also a leading provider of commercial insurance premium finance products through its wholly owned subsidiary National Partners. For more information, please visit www.webbank.com.</p>
                        <br>
                        <p><strong>Contact:</strong></p>
                        <p><strong>Media Contact:</strong>
                            <br>Shannon Hutchings<br>
                            <a href="mailto:shannon.hutchings@webbank.com">shannon.hutchings@webbank.com</a><br>
                        </p>
                        <br>

                        <p><strong>Investor Contact:</strong>
                            <br>Jennifer Golembeske
                            <br>212-520-2300
                            <br><a href="mailto:jgolembeske@steelpartners.com">jgolembeske@steelpartners.com</a><br>
                        </p>
                        <br>
                        <br>
                        <p>For more information on Arizent’s Best Places to Work in Financial Technology program, including full eligibility criteria, visit <a @click="clickOutWarning('https://www.bestplacestoworkfintech.com');">www.BestPlacestoWorkFinTech.com</a> or contact Penny Crosman at <a href="mailto:penny.crosman@arizent.com">penny.crosman@arizent.com</a>.</p>
                    </div>

                    <div class=" modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website
                                    and does not represent either the linked website or you, should you enter into a
                                    transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked
                                    site. We encourage you to review their privacy and security policies which may differ
                                    from WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "ADDITION OF MALIN ERIKSSON TOS WEBBANK BOARD OF DIRECTORS";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
