<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr23_hero.png"
        alt="Libertas Funding Volume Milestone">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr23_small.png"
        alt="Libertas Funding Volume Milestone">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">Libertas Eclipses $2 Billion Funding Volume Milestone</h2>
            <br>
            <h4 class="news-date">March 27th, 2023</h4>
            <br>
            <p class="news-page-subtitle">Funding and customer growth is testament to Libertas' ability to deliver rapid
                access to financing, flexible solutions and a dedicated team of financial experts.</p>
            <br>
            <p><strong>Greenwich, CT, March 27, 2023</strong> -- <a href="#" @click="clickOutWarning('https://www.libertasfunding.com/')">Libertas Funding, LLC</a>, which delivers high-tech, high-touch
    access to funding at the speed of business, today announced it surpassed $2 billion in total
    funding volume for its business customers. The milestone reflects Libertas' unmatched track
    record providing rapid access to financing, scalable and flexible solutions personalized to client
    needs, and a team of financial experts guiding businesses through every step of the funding
    process.</p>
<br>
<p>“Surpassing $2 billion isn't just a Libertas milestone; it is a testament to our clients because
    their success is our success,” said Randy Saluck, Libertas' Chief Executive Officer. “After
    reaching $1 billion in funding to businesses after five years in operation, we achieved the next
    billion in only 18 months - demonstrating a proven model that can continue to scale in the
    months and years ahead.”</p>
<br>
<p>The funding milestone is attributable to several competitive differentiators enabling Libertas to
    scale and adapt during challenging and evolving economic and market conditions:</p>
    <ul>
        <li><strong>Unique ability to rapidly fund businesses.</strong> Libertas' high-tech, high-touch model treats
    business owners as people rather than numbers, so that clients can benefit from
    frictionless financing that combines the speed and efficiency automation can deliver
    with human interaction.</li>

        <li><strong>Flexibility to deliver custom funding solutions.</strong> The Libertas team gets to know each
    client's business inside-and-out, so it can develop creative
    <a href="#" @click="clickOutWarning('https://libertasfunding.com/funding-solutions/revenue-based-funding/')">revenue-based funding</a> and
    business <a href="#" @click="clickOutWarning('https://libertasfunding.com/funding-solutions/term-loan/')">term loan</a>
    solutions (term loans are originated by Libertas' banking partner WebBank) to address any challenge and seize every opportunity.</li>
        <li><strong>Foundational approach to funding.</strong> Relationships are an asset at Libertas, with a team
    that doesn't view each client engagement as a transaction but instead as a foundation
    to fund the business' future. This approach is why nearly three-quarters of Libertas'
    total funding volume is a result of returning customers.</li>
        <li><strong>Elite network of referral partners.</strong> Referral partners have been a significant source of
    client and funding growth since inception. They entrust Libertas to serve their clients
    because we meet and exceed expectations every time.</li>
    </ul>
            <br>
    <p>To learn more about why Libertas has become the “go-to” financing source for growing
    businesses, visit <a href="#" @click="clickOutWarning('https://www.libertasfunding.com/')">https://libertasfunding.com/</a>.</p>
    <br>
            <p class="news-section-title">About Libertas Funding, LLC.</p>

            <p>Founded in 2016, Libertas has provided access to over $2 billion in funding for small and
    medium-sized businesses since its inception. Libertas empowers businesses to grow with high-
    tech, high-touch access to funding that pairs best-in-class client service with top-of-line
    technology to ensure businesses have the capital to thrive. By partnering with financial
    industry experts, business financing decisions are always informed and guided by experience.
    Visit Libertas at <a href="#" @click="clickOutWarning('https://www.libertasfunding.com/')">https://libertasfunding.com/</a> and discover a long-term financial resource that is
    as invested in the success of your business as you are.</p>
<br>
<br>
            <p class="news-section-title">Contact Information</p>
            <p>Libertas Funding, LLC</p>
            <p>Jessica Patrovic<br>
                <a href="mailto:jessica@libertasfunding.comm">jessica@libertasfunding.comm</a>
            </p>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "Libertas Funding Volume Milestone - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
