<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-perry.png" class="is-rounded" alt="Alicia Perry">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Alicia Perry</h2>
            <h3 class="people-title">SVP Technology Enablement</h3>
            <p class="people-bio">
Alicia oversees Information Technology Infrastructure, Information Security, and Technology Governance. She has 20 years of financial service industry experience developing governance and controls, operational processes, and reporting and solution design. Prior to joining WebBank in 2020, Alicia held critical senior positions at American Express and Interactive Communications (InComm) leading global teams through program and product launches, organizational transformations, mergers and acquisitions. Alicia received a B.A. and M.A. from the University of Utah and M.I.B. from
    Thunderbird School of Global Management.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
