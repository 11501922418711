<template>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                    <br>
                    <br>
                    <h3 class="is-size-1 has-text-primary section-title">Our People</h3>
                    <br>
                    <div class="content">
                        <p>WebBank is led by some of the most experienced and successful individuals in the industry.
                            Meet the team bringing leadership and foresight to every part of our business. </p>

                    </div>
                    <br>
                </div>
                <div class="column">
                    <br>
                    <img src="https://assets.webbank.com/people1-1.png" class="body-image" alt="Our People" />
                </div>
            </div>
        </div>
    </section>

    <section class="section">
        <div class="container">
            <div class="has-text-centered">
                <h3 class="is-size-1 has-text-primary section-title">Executive Leadership</h3>
            </div>
            <div class="columns">
                <div class="column p-6">
                    <people-lloyd></people-lloyd>
                </div>
                <div class="column p-6">
                    <people-berkson></people-berkson>
                </div>
            </div>
            <div class="columns">
                <div class="column p-6">
                    <people-sanz></people-sanz>
                </div>
                <div class="column p-6">
                    <people-stanford></people-stanford>
                </div>
            </div>
            <div class="columns">
                <!-- <div class="column p-6">
                    <people-asadi></people-asadi>
                </div> -->
                <div class="column p-6">
                    <people-blankenstein></people-blankenstein>
                </div>
                <div class="column p-6">
                    <people-cannon></people-cannon>
                </div>
            </div>
            <div class="columns">
                <div class="column p-6">
                    <people-hutchings></people-hutchings>
                </div>
                <div class="column p-6">
                    <people-hardy></people-hardy>
                </div>
            </div>
            <div class="columns">
                <div class="column p-6">
                    <people-jackson></people-jackson>
                </div>
                <div class="column p-6">
                    <people-laudano></people-laudano>
                </div>
            </div>
            <div class="columns">
                <div class="column p-6">
                    <people-perry></people-perry>
                </div>
                <div class="column p-6">
                    <people-peterson></people-peterson>
                </div>
            </div>
            <div class="columns">
                <div class="column p-6">
                    <people-pratt></people-pratt>
                </div>
                <div class="column p-6">&nbsp;</div>
            </div>
        </div>
    </section>

    <section class="section grey-bg">
        <div class="container">
            <br>
            <div class="has-text-centered">
                <h3 class="is-size-1 has-text-white section-title">Board of Directors</h3>
            </div>
            <br>
            <div class="columns">
                <div class="column is-size-4 has-text-centered">Jack Howard</div>
                <div class="column is-size-4 has-text-centered">Martha Hayes</div>
                <div class="column is-size-4 has-text-centered">Malin Eriksson</div>
                <div class="column is-size-4 has-text-centered">Jana Schmidt</div>
                <div class="column is-size-4 has-text-centered">Dave Thwaits</div>
                <div class="column is-size-4 has-text-centered">Abhinav Tiwari</div>
            </div>
            <br>
            <br>
        </div>
    </section>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column">
                    <br>
                    <img src="https://assets.webbank.com/Webbank646.png" class="body-image" alt="WebBank Values" />
                </div>
                <div class="column is-half">
                    <br>
                    <br>
                    <h3 class="is-size-1 has-text-primary section-title">Work Where Values Mean Something</h3>
                    <br>
                    <div class="content">
                        <p>Teamwork, respect, integrity, and commitment—these are more than just words at WebBank; they
                            propel personal and company success and growth. Join us and see what our values can do for
                            your career. </p>
                        <br>
                        <a href="#" title="Find a Job at WebBank!"
                            @click="clickOutWarning('https://boards.greenhouse.io/webbank')"
                            class="button is-primary is-large">Explore Open Positions</a>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </section>

    <CareersHero parent="working"></CareersHero>

    <div class="modal" :class="{'is-active': clickout_modal}">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="box">
                <h2 class="box-title">You are leaving our site!</h2>
                <br>
                <p>You will be linking to another website not owned or operated by WebBank.</p>
                <br>
                <p>WebBank is not responsible for the availability or content of this website and does not represent
                    either the linked website or you, should you enter into a transaction.</p>
                <br>
                <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or
                    monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their
                    privacy and security policies which may differ from WebBank.</p>
                <br>
                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                <br>
                <br>
                <div class="field is-grouped">
                    <div class="control">
                        <button class="button is-link" @click="doClickout">Proceed</button>
                    </div>
                    <div class="control">
                        <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import PeopleLloyd from '@/components/PeopleLloyd.vue'
import PeopleBerkson from '@/components/PeopleBerkson.vue'
import PeopleSanz from '@/components/PeopleSanz.vue'
import PeopleBlankenstein from '@/components/PeopleBlankenstein.vue'
import PeopleCannon from '@/components/PeopleCannon.vue'
import PeopleHutchings from '@/components/PeopleHutchings.vue'
import PeopleJackson from '@/components/PeopleJackson.vue'
import PeoplePerry from '@/components/PeoplePerry.vue'
import PeoplePeterson from '@/components/PeoplePeterson.vue'
import PeoplePratt from '@/components/PeoplePratt.vue'
import PeopleStanford from '@/components/PeopleStanford.vue'
import PeopleHardy from '@/components/PeopleHardy.vue'
// import PeopleAsadi from '@/components/PeopleAsadi.vue'
import CareersHero from '@/components/CareersHero.vue';
import PeopleLaudano from '@/components/PeopleLaudano.vue'

export default {
  components: {
        PeopleLloyd,
        PeopleBerkson,
        PeopleSanz,
        PeopleBlankenstein,
        PeopleCannon,
        PeopleHutchings,
        PeopleJackson,
        PeoplePerry,
        PeoplePeterson,
        PeoplePratt,
        PeopleStanford,
        PeopleHardy,
        // PeopleAsadi,
        PeopleLaudano,
        CareersHero,
    },

    created() {
        document.title = "Meet the Team - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },

        closeAllPartnerModals: function () {
            this.avant_modal = false;
        }
    }
}
</script>
