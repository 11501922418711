<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-berkson.png" class="is-rounded" alt="Jeff Berkson">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Jeff Berkson</h2>
            <h3 class="people-title">EVP Chief Risk Officer</h3>
            <p class="people-bio">
Jeff is EVP Chief Risk Officer of WebBank and responsible for regulatory compliance and risk management—with executive oversight over Audit, Compliance, Credit, ERM and IT. Prior to joining WebBank in 2016, he was the Chief Analytics Officer of Credit Risk Management Analytics, a Community Bank credit &amp; ERM consultancy. He has served as Chief Information Officer of Anchor Bank (a $5B in assets regional bank) and Clout Financial Services (a start-up credit card issuer), and he was the CEO of a financial services analytics consultancy and an online property tax appeal service. Jeff received a B.A. summa cum laude from Duke University.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
