<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/zip.png" alt="Zip Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/zip.png" alt="Zip Logo">
          </div>
            <br>
            <p>In partnership with Zip, WebBank is the lender of consumer closed-end loans issued through the Zip app
                (excluding Zip originated loans in CO, NV, MD, and MA.) These loans allow customers to spread purchases
                over time while shopping in-store or within the Zip app. WebBank is also the issuer of the Zip Card,
                which is an access device for the closed-end loans. For more information, please click
                <a href="#" title="How Zip Works" @click="$emit('link-click', 'https://zip.co/us/how-it-works')">here</a>
                or contact Zip at (888) 274-3159 7 days a week, 7am- 11pm EST.</p>
            <br>
            <p><a href="#" title="Zip Privacy Policy" @click="$emit('link-click', 'https://zip.co/us/privacy-policy')">Zip Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Zip Privacy Notice" @click="$emit('link-click', 'https://zip.co/files/us/Privacy_Notice.pdf')">Zip Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://zip.co/files/us/WebBank_Privacy_Notice.pdf')">WebBank Privacy Notice</a></p>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
