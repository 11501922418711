<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/klarna.png" alt="Klarna Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/klarna.png" alt="Klarna Logo">
          </div>
            <br>
            <p>In partnership with Klarna, WebBank is the issuer and lender of the Klarna Card, a revolving charge card product.
                The Klarna Card can be used to finance purchases nearly everywhere that Visa cards are accepted.
                    To apply, please visit (<a href="#" title="Apply at Klarna" @click="$emit('link-click', 'https://www.klarna.com/us/card')">https://www.klarna.com/us/card/</a>)
                    or log in to the Klarna Mobile App to get started. For questions about the Klarna Card, please call 844-552-7621.</p>
            <br>
            <p>In partnership with Klarna, WebBank offers Monthly Financing, which allows customers to spread the cost of purchases over time.
                To learn more, please click <a href="#" title="Learn More at Klarna" @click="$emit('link-click', 'https://www.klarna.com/us/customer-service/what-is-financing-and-how-does-it-work/');">here</a>.
                For any questions related to Monthly Financing, please call 844-552-7621.</p>
            <br>
            <p><a href="#" title="Klarna Cardholder Agreement" @click="$emit('link-click', 'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/cardholder')">Klarna Cardholder Agreement</a></p>
            <br>
            <p><a href="#" title="Klarna Privacy Policy and Notice" @click="$emit('link-click', 'https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_us/privacy')">Klarna Privacy Policy and Privacy Notice</a></p>
            <br>
            <p><a href="#" title="WebBank Privacy Notice" @click="$emit('link-click', 'https://cdn.klarna.com/1.0/shared/content/legal/en_us/glba_notice.pdf')">WebBank Privacy Notice</a></p>
            <br>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
