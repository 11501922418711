<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr28_hero.png"
        alt="Lane Health APEX Award">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr28_small.png"
        alt="Lane Health APEX Award">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">Lane Health Extends Streak of Industry Recognition with Third
                                            Consecutive APEX Award</h2>
                <br>
                <h4 class="news-page-subtitle">The 2023 APEX Award for Partner Excellence was awarded for Consumer Adoption,
    highlighting Lane Health’s ability to grow consumer-driven healthcare business.</h4>
            <br>
            <p><strong>NORFOLK, VA, (PRWEB) MAY 22 , 2023</strong></p>
            <br>
            <p>Lane Health, a leading benefit spending account administrator and healthcare payment and
    lending platform, announced receipt of an Alegeus Performance Excellence (APEX) Award for
    Consumer Adoption at the 2023 Alegeus Client Success Summit. This marks the third straight
    year Lane Health has won an APEX Award, following recognition in 2021 for Innovation and in
    2022 for Overall Growth.</p>
    <br>
    <p>“This award is a testament to the continued trust our partners, brokers, clients, and members
    have placed in us, and we are truly thankful for the opportunity to serve them,” said Scott Beck,
    Chief Revenue Officer at Lane Health. “Our sustained growth is a powerful reminder that
    relentless focus on innovation and exceptional service can drive positive change by helping
    employees get the health care they need, when they need it.”</p>
    <br>
    <p>Through HSAs, FSAs and HRAs administered by Lane Health, employees have access to a
    groundbreaking Advance¹ line of credit, issued by WebBank, which provides funds when
    employees’ spending account funds run out. This safety net can empower employees to move
    to high-deductible health plans (HDHPs) and potentially save money with lower premiums; in
    fact, Lane Health helped one employer increase HDHP enrollment by 10x in one plan year. In
    addition, Lane Health HSA account holders had a 30% higher utilization rate² than industry
    average.</p>
    <br>
    <p>“In a landscape where true differentiators are few and far between, the Advance<sup>¹</sup> line of credit is
    a gamechanger for benefit spending accounts,” said Brad Gambill, co-founder and CEO of Lane
    Health. “For employers seeking to provide unique and innovative employee benefits, the line of
    credit<sup>¹</sup> is a proven solution that can help jump-start employee engagement and spending
    account adoption at no cost to the employer.”</p>
    <br>
    <p>“We are excited to recognize the achievements of great partners like Lane Health,” said Mark
    Waterstraat, Chief Customer Officer at Alegeus. “As a channel-first organization, our partners
    are our singular priority, and it is so gratifying to honor them for the tremendous work they do to
    create better lives for consumers. Congratulations on this well-deserved recognition!”</p>
    <br>
    <p><strong>About Lane Health</strong></p>
    <p>Lane Health can help make healthcare more affordable and accessible through unique,
    integrated capabilities to deploy capital into the healthcare delivery system exactly when
    members need it most. Lane Health’s combination of proprietary technology solutions,
    personalized service, and innovative product lineup positions the company at the forefront of the
    healthcare and FinTech juncture. Lane Health’s industry trailblazing and rapid growth through
    broker and strategic partner distribution has been recognized with the Alegeus APEX Awards in
    2021, 2022 and 2023. Visit <a href="#" @click="clickOutWarning('http://www.lanehealth.com')">http://www.LaneHealth.com</a> to learn more.</p>
    <br>
    <br>
    <p><sup>¹</sup> <em>Advances issued by WebBank; Advance must be activated before funds can be accessed</em></p>
    <p><sup>²</sup> <em>Rate calculated from 2022 internal Lane Health data and EBRI Trends in Health Savings
    Account Balances, Contributions, Distributions, and Investments, 2011–2021</em></p>
    <br>
<br>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "Lane Health APEX Award - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
