<template>

<a name="visitors-policy" id="visitors-policy">&nbsp;</a>

<div style="margin-top: 60px;">
        <p class="is-pulled-right mt-10"><router-link :to="{ name: 'PrivacyPage', hash: '#top' }"><i class="fa-solid fa-arrow-up"></i> Back to Top</router-link></p>
        <h4 class="has-text-primary">WebBank Website Visitors Privacy Policy</h4>
<br>

            <p>This privacy policy applies to consumers that visit our website at https://www.webbank.com/. Your privacy is important to us. In this privacy policy, we explain how we collect, share, use, and protect your information.</p>
            <br>
            <h4 class="pp-section-title">Types of Information We Collect and Sources</h4>
            <br>
            <p>The following are examples of the types of information we collect and the sources:</p>
            <ul>
                <li>Your IP address, the type of operating system and browser you use, the identity of any website you came from, and the site you visit next. We may use cookies, web beacons or other technologies to collect and store this information.</li>
            </ul>
            <br>
            <h4 class="pp-section-title">Use of Information</h4>
            <br>
            <p>We use the information discussed above in a number of ways, such as:</p>
            <ul>
                <li>Operating, evaluating and improving our business (including developing new products and services; improving existing products and services; performing data analytics; and performing accounting, auditing and other internal functions); and Responding to your requests and communicating with you.</li>
            </ul>
            <br>
            <h4 class="pp-section-title">Disclosure of Information</h4>
            <br>
            <p>We may share the information we collect from and about you:</p>
            <ul>
                <li>With WebBank's third-party service providers;</li>
                <li>With third parties or affiliates in connection with a corporate transaction, such as a sale, consolidation or merger of WebBank businesses;</li>
                <li>With other third parties to comply with legal requirements such as the demands of applicable subpoenas and court orders; to verify or enforce our terms of use, our other rights, or other applicable policies; to address fraud, security or technical issues; to respond to an emergency; or otherwise to protect the rights, property or security of our customers or third parties; and</li>
                <li>With third parties in an anonymous or aggregated manner to help deliver products, services, and content that are tailored to you and for other purposes.</li>
            </ul>
            <br>
            <h4 class="pp-section-title">Understanding Cookies, Web Beacons and Other Tracking Technologies</h4>
            <br>
            <p>We, may deploy and use cookies, web beacons, local shared objects and other tracking technologies for various purposes, such as fraud prevention and monitoring our advertising and marketing campaign performance. Some of these tracking tools may detect characteristics or settings of the specific device you use to access our website.</p>
            <br>
            <p>“Cookies” are small amounts of data a website can send to a visitor's web browser. They are often stored on the device you are using to help track You areas of interest. Cookies may also enable us, our service providers, and other companies we work with to relate your use of our online services over time to customize your online experience. Most web browsers allow you to adjust your browser settings to decline or delete cookies but doing so may degrade your experience with our website.</p>
            <br>
            <p>Clear GIFs, pixel tags or web beacons—which are typically one-pixel, transparent images located on a webpage or in an email or other message—or similar technologies may be used on our sites and in some of our digital communications (such as email or other marketing messages). They may also be used when you are served advertisements. These are principally used to help recognize users, assess traffic patterns and measure site or campaign engagement.</p>
            <br>
            <p>Local Shared Objects, sometimes referred to as “flash cookies” may be stored on your hard drive using a media player or other software installed on your device. Local Shared Objects are similar to cookies in terms of their operation but may not be managed in your browser in the same way.</p>
            <br>
            <h4 class="pp-section-title">Do Not Track</h4>
            <br>
            <p>Many web browsers possess a do-not-track feature that lets your browser tell websites you visit that you do not want to have your online activities tracked. At this time, our website does not respond to browser do-not-track signals.</p>
            <br>
            <h4 class="pp-section-title">Linking to Third-Party Websites</h4>
            <br>
            <p>WebBank may provide links to websites that are owned or operated by others ("third-party websites"). When you use a link online to visit a third-party website, you will be subject to that website's privacy and security practices, which may differ from ours. You should familiarize yourself with the privacy notice/policy, terms of use and security practices of the linked third-party website before providing any information on that website.</p>
            <br>
            <h4 class="pp-section-title">Security</h4>
            <br>
            <p>To protect your personal information from unauthorized access and use, we use reasonable electronic and procedural safeguards to protect and limit access to personal information.</p>
            <br>
            <h4 class="pp-section-title">Minors</h4>
            <br>
            <p>Our website is not intended for persons under 18 years of age. We do not knowingly collect personal information from persons under 18 years of age.</p>
            <br>
            <h4 class="pp-section-title">Accessing Our Website from Outside the U.S</h4>
            <br>
            <p>Our website is intended for a U.S. audience. If you visit our website, your information will be transferred to or processed in the United States.</p>
            <br>
            <h4 class="pp-section-title">Questions</h4>
            <a name="ca-link" id="section-ca-privacy">&nbsp;</a>
            <p>If you have questions about our privacy practices, please contact us at 1-844-994 BANK (2265).</p>
            <br>
            <h4 class="pp-section-title">California Privacy Notice</h4>
            <br>
            <p>If you are a California resident who is a natural person, the California Consumer Privacy Act (“CCPA”) provides certain rights concerning your personal information, unless an exception applies. In addition to certain exceptions, the CCPA does not consider information to be personal information if it cannot be reasonably linked to any consumer or household.</p>
            <br>
            <p>If you are a consumer that visits our website, information collected by WebBank is not considered personal information under the CCPA as it cannot be linked, or could not be reasonably linked, to any consumer or household, and, therefore, the CCPA does not apply.</p>
            <br>
</div>
</template>
