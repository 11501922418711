<template>
<div>

    <div class="box partner-box" @click="toast_modal = true">
        <img src="https://assets.webbank.com/partners/toast.png" alt="Toast Logo">
    </div>

<div class="modal" :class="{'is-active': toast_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/toast.png" alt="Toast Logo">
          </div>
            <br>
            <p>In partnership with Toast Capital, LLC., WebBank is the lender of small business installment loans
                to eligible Toast customers. For more information about Toast Capital Loans or to apply for one, click
                <a href="#" title="More information at Toast" @click="$emit('link-click', 'https://pos.toasttab.com/products/capital?source=tpos_acq_paidxxx_cousa_semgb_wmql_exabrandxx&utm_medium=paid&utm_source=semgb&utm_campaign=tpos-b2bsmb-paid-80747933489-exabrandxx-acq-semgb-cousa_wmql&utm_term=toast%20capital&_bt=441822643842&_bm=e&_bn=g&gclid=Cj0KCQjwvYSEBhDjARIsAJMn0liNQEA6wtngcmVNiwFTH97irvyYiA7OpCUK9DAKRpvphsWoVAPLsTwaApmAEALw_wcB')">here</a>.
                If you have questions about your loan, please call 617-682-0225.</p>
            <br>
            <button class="button is-primary is-light" @click="toast_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="toast_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            toast_modal: false,
        }
    }
}
</script>
