<template>
<div>

    <div class="box partner-box" style="display: grid; place-items: center;" @click="partner_modal = true">
        <img src="https://assets.webbank.com/PEAC-logo.png" style="max-width: 75%;" alt="PEAC Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div style="display: grid; place-items: center;">
            <img src="https://assets.webbank.com/PEAC-logo.png" style="max-width: 200px;" alt="PEAC Logo">
          </div>
            <br>
            <p>In partnership with PEAC, WebBank is the lender of Working Capital Loans. If you are interested in applying,
                click <a href="#" title="PEAC Help" @click="$emit('link-click', 'https://apply.peacsolutions.com')">here</a>.
                To speak with someone about your Working Capital Loan, please call PEAC at <a href="tel:+18882362409">(888) 236-2409</a>,
                or you may contact PEAC via email at <a href="mailto:WCLCustomerservice@peacsolutions.com">WCLCustomerservice@peacsolutions.com</a>.</p>
            <br>
            <br>
            <p><a href="#" title="PEAC Privacy Policy" @click="$emit('link-click', 'https://peacsolutions.com/privacy-policy/')">PEAC Privacy Policy</a></p>
            <br>

            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
