<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr16-hero.png"
                            alt="Zip Card">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr16-small.png"
                            alt="Zip Card">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">Zip Accelerates BNPL Adoption with Arrival of Zip Card, Unlocking BNPL Everywhere Advantage for All of Life's Moments</h2>
            <br>
            <p><em>Ahead of the upcoming holiday season, BNPL pioneer Zip Co has announced the availability of Zip Card, issued by WebBank, the newest buy now, pay later product offered through its portfolio. As consumers look for BNPL options in brick-and-mortar retail, the Zip Card extends the 'Pay-in-4<sup>*</sup>' functionality offline in a convenient physical card format.</em></p>
            <br>
            <h4 class="news-date">October 25th, 2022</h4>
            <br>
            <p>NEW YORK -- (BUSINESS WIRE) -- Today BNPL pioneerZip Co (ASX: ZIP) announced the availability of Zip Card, issued by WebBank, the newest buy now, pay later product offered through its portfolio. As consumers look for BNPL options in brick-and-mortarretail, the Zip Card extends the 'Pay-in-4<sup>*</sup>' functionality offline in a convenient physical card format. In the Zip card pilot program<sup>1</sup>, an overwhelming majority (90%) of participants said the card makes in-store shopping easier. With a quarter million waitlisted customers, the highly anticipated card is now available in the U.S., enabling consumers to 'Pay in 4<sup>*</sup>' nearly everywhere<sup>*</sup> Visa is accepted.</p>
            <br>
            <p>“The Zip app lets customers use the pay later service pretty much everywhere<sup>*</sup>... even on shopping sites where Zip is not at the checkout option,” said Zip's U.S. Chief Marketing Officer, Jinal Shah. “With the Zip Card, we are offering customers access to another fair, transparent payment method in a familiar format to use in-store, specifically at stores that do not accept contactless or mobile phone payments.”</p>
            <br>
            <p>Despite the introduction of mobile phone payments nearly eight years ago, only 6 percent of consumers in the U.S. opt to pay with their phone at checkout in-store, relying on traditional plastic instead, according to a recent PYMNTS survey. Whether it's food delivery, a birthday vacation, the latest sneaker drop, music festival, hair appointment, holiday shopping, or even paying a phone bill, Zip provides a single platform that consumers can rely on for their desired purchases and to expand their spending power. Additionally, the Zip Card enables consumers to enjoy the BNPL everywhere advantage at several big box retailers, local shops, and service providers that do not offer contactless payment options.</p>
            <br>
            <p>Key benefits and features of the Zip Card include:</p>
            <ul>
                <li>A physical card that can be used to 'Pay-in-Four<sup>*</sup>' in offline settings, even where mobile payments are not accepted</li>
                <li>Pay in four<sup>*</sup> installments at most brick-and-mortar retail locations, wherever Visa is accepted</li>
                <li>Unlock Zip's BNPL everywhere advantage at big box retailers like Walmart, Home Depot, local shops, restaurants, and more</li>
                <li>Seamless sign-up process with no impact on credit score</li>
                <li>Automatic payments made from your linked debit or credit card</li>
                <li>Friendly reminders and notifications ensure you never miss a payment</li>
                <li>Easily change payment dates</li>
                <li>Customer service is available 24/7 in the Zip app</li>
            </ul>
            <br>
            <p>The arrival of the Zip Card complements Zip's other industry-first innovations. Zip was the first to offer access to a virtual card for BNPL payments via the app. Zip (previously Quadpay) was also the first to introduce a <a href="#" @click="clickOutWarning('https://cts.businesswire.com/ct/CT?id=smartlink&url=https%3A%2F%2Fzip.co%2Fus%2Fchrome&esheet=52951853&newsitemid=20221025005372&lan=en-US&anchor=Google+Chrome+extension&index=4&md5=43185401670a3bd0201cbbed674ea509')">Google Chrome extension</a> to bring the BNPL everywhere Advantage to the desktop.</p>
            <br>
            <p>To learn more about Zip or to download the app, visit: <a href="#" @click="clickOutWarning('https://www.zip.co/us')">www.zip.co/us</a>.</p>
            <br>

            <p class="news-section-title">About Zip</p>
            <p>Zip Co Limited (ZIP: ASX) or (“Zip”) is a leading global financial services company, offering innovative, people-centered products that bring customers and merchants together. On a mission to be the first payment choice everywhere and every day, Zip offers point-of-sale credit and digital payment services around the world, including through strategic investments, connecting millions of customers with its global network of tens of thousands of merchants.</p>
            <br>
            <p>Zip provides fair, flexible, and transparent payment options to individual consumers and SMBs, helping them to take control of their financial future and helping merchants to grow their businesses. Zip is committed to responsible lending and is managed by a team with over 100 years of experience in retail finance and payments. Zip is also a licensed and regulated credit provider and was founded in Australia in 2013. For more information, visit <a href="#" @click="clickOutWarning('https://www.zip.co/us')">www.zip.co/us</a>.</p>
            <br>
            <p class="news-section-title">Editor's Note:</p>
            <p><sup>1</sup>Zip Research conducted during a pilot program surveyed approximately 1500 Zip customers in the United States on August 4-10, 2022.</p>
            <br>
            <br>
            <p class="news-section-title">Terms and Conditions</p>
            <p>Eligibility criteria apply. See payment terms: <a href="#" @click="clickOutWarning('https://zip.co/us/quadpay-terms-of-service')">https://zip.co/us/quadpay-terms-of-service</a>. Loans to California residents, other than loans through the Zip App and Zip Card, are made or arranged pursuant to California Lenders Law license.</p>
            <br>
            <p><sup>*</sup>Zip can only be used for US purchases. Certain merchant, product, goods, and service restrictions apply. For a $200 purchase, you'd make four $51 payments every two weeks starting today for a 34.7% annual percentage rate and a total of payments of $204. A $4 installment fee is charged at commencement - you pay $1.00 of this fee as a prepaid finance charge when you make your initial payment today. The remaining $3.00 is included in your future payments. Minimum purchase of $35 required. Estimation of installment payment and annual percentage rate excludes potential tax and shipping costs. Zip Pay everywhere financing through Zip issued by WebBank, except for Zip-originated loans in CO, NV and MA. All loans subject to credit approval.</p>
                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "Zip Card - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
