<template>

<br>
<a name="california-individuals-policy" id="california-individuals-policy">&nbsp;</a>


<div style="margin-top: 60px;">
        <p class="is-pulled-right mt-10"><router-link :to="{ name: 'PrivacyPage', hash: '#top' }"><i class="fa-solid fa-arrow-up"></i> Back to Top</router-link></p>
        <h4 class="has-text-primary">WebBank California Individuals at Other Businesses Privacy Policy</h4>
<br>
<p>This WebBank California Individuals at Other Businesses Privacy Policy governs WebBank’s collection and use of personal information about individuals for whom it collects personal information who are California residents and who are associated with third-party businesses with which WebBank does business, including owners, directors, officers, and employees at current, former, and prospective Brand Partners (<strong>“Brand Partner California Individuals”</strong>).</p>

<p>We provide this California Individuals at Other Businesses Privacy Policy as required by the California Consumer Privacy Act (the <strong>“CCPA”</strong>). The CCPA provides California residents with rights to receive certain disclosures regarding the collection, use, sale, sharing, and disclosure of their personal information, as well as rights to access, correct, delete, and restrict the sale, use, sharing, and disclosure of certain personal information we collect about them, unless exceptions apply. California residents also have the right not to receive discriminatory treatment by us for the exercise of privacy rights under the CCPA. If you are, or maybe, a Brand Partner California Individual, you may submit a request to exercise these rights by calling us at 1-884-994-BANK (2265) or emailing us at <a href="mailto:Privacy@WebBank.com">Privacy@WebBank.com</a>.</p>

<p>For the purposes of this WebBank California Individuals at Other Businesses Privacy Policy, except where a different definition is noted, “personal information” means information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular California resident or household. Personal information does not include publicly available information; lawfully obtained, truthful information that is a matter of public concern; information that has been de-identified or aggregated; or other types of information pursuant to an applicable exception, as consistent with state law.</p>

<p><strong>1. Personal Information We Collect</strong></p>
<p>WebBank has collected the following categories of personal information over the past twelve (12) months concerning Brand Partner California Individuals:</p>
<ol type="a">
<li><strong>Identifiers</strong>, which includes first and last name; social security number; and e-mail address.</li>
<li><strong>Professional or employment-related information</strong>, which includes current employment; employment history; company; position; position type; length of employment; salary; and manager.</li>
<li><strong>Education information</strong>, which includes degrees earned and from what institution or school.</li>
<li><strong>Other Personal information described in California Civil Code § 1798.80(e)</strong>, which includes date of birth; telephone number; zip code; criminal history; and watchlist status.</li>
<li><strong>Inferences</strong> drawn from the personal information listed above.</li>
</ol>

<p>Please note that because of the overlapping nature of certain of the categories of personal information identified above, which are required by state law, some of the personal information we collect may be reasonably classified under multiple categories.</p>
<p><strong>Sensitive personal information</strong>.  We do collect sensitive personal information about Brand Partner California Individuals as we collect social security numbers, but we do not use or disclose in a way that requires us to provide a Notice of Right to Limit under the CCPA.</p>
<br>
<p><strong>2. Sources of Personal Information</strong></p>
<p>We have collected personal information about Brand Partner California Individuals from the following categories of sources over the past twelve (12) months:</p>
<ul>
<li><strong>From Brand Partners</strong>, including current, former, and prospective Brand Partners.</li>
<li><strong>From our service providers</strong>, including providers of background screening services.</li>
</ul>
<br>
<p><strong>3. How We Use Personal Information</strong></p>
<p>We have used the personal information we have collected about Brand Partner California Individuals for the following purposes over the past twelve (12) months:</p>
<ul>
<li>To evaluate and manage our commercial relationships with our Brand Partners; and</li>
<li>To manage our credit and deposit programs we originate through the platforms of our Brand Partners.</li>
</ul>
<br>
<p>We will retain personal information for as long as needed or permitted in light of the purpose(s) for which it was obtained. The criteria used to determine our retention periods include: (i) the length of time we have an ongoing relationship with the Brand Partner; (ii) whether there is a legal obligation to which we are subject; (iii) whether there is a privacy right for which the personal information has been exercised (such as a request to delete); and (iv) whether retention is advisable in light of our legal position (such as in regard to applicable statutes of limitations, litigation or regulatory investigations).</p>
<br>

<p><strong>4. Who We Disclose Personal Information To</strong></p>
<p>We do not sell or share the personal information of Brand Partner California Individuals as those terms are defined under California law, including individuals under 16 years of age.   We have not disclosed the personal information of Brand Partner California Individuals to third parties over the past twelve (12) months.</p>
<p>In the future, we may disclose the personal information of Brand Partner California Individuals to:</p>
<ul>
<li><strong>Our affiliates.</strong>  We may disclose your personal information with our affiliates.</li>
<li><strong>Service providers.</strong>  We may disclose your personal information with our service providers.</li>
<li><strong>Business transfer.</strong>  If WebBank is involved in a merger, acquisition or asset sale, your personal information may be transferred.</li>
<li><strong>Legal compliance and harm prevention.</strong> We may disclose your personal information as we believe necessary (i) to comply with applicable law, rules and regulations; (ii) to enforce our contractual rights; (iii) to investigate possible wrongdoing in connection with our company; (iv) to protect and defend the rights, privacy, safety and property of WebBank, you or others; and (v) to respond to requests from courts, law enforcement agencies, regulatory agencies, and other public and government authorities.</li>
</ul>
<br>
<p><strong>5. CCPA Privacy Rights</strong></p>
<p>If you are a Brand Partner California Individual, you have the right to make the following requests under applicable California law in relation to your personal information, subject to certain exceptions:</p>
<ul>
<li><strong>Right to Know.</strong> You have the right, up to twice in a 12-month period, to request the categories of personal information we have collected, the categories of sources from which such personal information was collected, the business or commercial purpose for collecting, selling, or sharing personal information, the categories of third parties to whom personal information was disclosed, and the specific pieces of personal information we collected about you.</li>
<li><strong>Right to Delete.</strong> You have the right to request, under certain circumstances, the deletion of your personal information that we collect.</li>
<li><strong>Right to Opt-Out of Sale or Sharing.</strong> You have the right to opt-out of the sale or sharing of your personal information as those terms are defined under California law. Please note that WebBank does not currently sell or share personal information that is subject to this WebBank California Individuals at Other Businesses Privacy Policy.</li>
<li><strong>Right to Non-Discrimination.</strong> You have the right not to receive discriminatory treatment for the exercise of the privacy rights described above.</li>
<li><strong>Right to Limit Use and Disclosure.</strong> You have the right to limit the use or disclosure of your sensitive personal information in certain circumstances. Please note that WebBank does not use or disclose sensitive personal information in a manner that provides a right to limit the use of disclosure of such information.</li>
<li><strong>Right to Correct.</strong> You have the right to request the correction of your inaccurate personal information.</li>
</ul>

<p>How to submit a request. A Brand Partner California Individual may exercise any of the rights described in this section by emailing us at <a href="mailto:Privacy@WebBank.com">Privacy@WebBank.com</a>, or by calling 1-884-994-BANK (2265).</p>
<p>Any request you submit to us is subject to an identification and residency verification process (<strong>“Verifiable Consumer Request”</strong>) as permitted by the CCPA. We will not fulfill your request unless you have provided sufficient information that enables us to reasonably verify that you are the consumer about whom we collected the personal information on. To verify you, you must provide us with first name, last name, and relevant Brand Partner. These rights are also subject to various exclusions and exceptions under applicable laws.</p>
<p>You may also designate an authorized agent, in writing or through a power of attorney, to request to exercise the above rights on your behalf. If you should use an authorized agent to exercise your various rights under the CCPA, we may require that you provide your authorized agent with written permission to exercise your various rights and to verify your own identity with us. If your authorized agent does not submit proof that they have been authorized by you to submit verified requests for disclosure and deletion, we reserve the right to deny such a request that we have received and will explain to your authorized agent why we have denied such request. The authorized agent may submit a request to exercise these rights by emailing the letter of authorization or power of attorney to <a href="mailto:Privacy@WebBank.com">Privacy@WebBank.com</a>. We currently do not collect household data in connection with any Brand Partner California Individual.</p>
<p>We will respond to your request within forty-five (45) days after receipt of a Verifiable Consumer Request for a period covering twelve (12) months and for no more than twice in a twelve-month period. We reserve the right to extend the response time by an additional forty-five (45) days when reasonably necessary and provided consumer notification of the extension is made within the first forty-five (45) days.</p>
<br>
<p><strong>6. Contact Us</strong></p>
<p>If you have any questions about this WebBank California Individuals at Other Businesses Privacy Policy, please contact us using the following information:</p>
<br>
<p><strong>WebBank</strong></p>
<table class="table is-fullwidth is-striped">
    <tr>
        <th>Email:</th>
        <td><a href="mailto:Privacy@WebBank.com">Privacy@WebBank.com</a></td>
    </tr>
    <tr>
        <th>Phone:</th>
        <td>1-884-994-BANK (2265)</td>
    </tr>
</table>
<br>
<br>
<br>
</div>
</template>
