<template>
    <section class="section">
        <div class="container">

            <div class="columns">
                <div class="column is-8 is-offset-2">
                    <div class="content">

            <h1 class="is-size-1 section-title has-text-primary">UIGEA</h1>
            <br>

            <p class="is-size-5"> The Unlawful Internet Gambling Enforcement Act (“UIGEA”) prohibits any person engaged in the business of betting or wagering from knowingly accepting payments in connection with the participation of another person in unlawful Internet gambling. Such transactions are referred to as “restricted transactions.”</p>
                <br>
            <p class="is-size-5">WebBank prohibits internet gambling transactions from being processed through your account or banking relationship. WebBank will not open accounts for customers engaged in any internet gambling business, including legal internet gambling businesses.</p>
            <br>
            <p class="is-size-5">WebBank may terminate an account relationship if the customer engages in unlawful Internet gambling or other illegal activities. </p>
            <br>
            <br>
            <br>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "UIGEA - WebBank";
    },

}
</script>

<style scoped>
.patriot-hero {
    background: rgba(31, 55, 107, 0.5) url(https://assets.webbank.com/uigea-banner.png);
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, .4);
    background-position: center;
    background-size: cover;
    min-height: 400px;
}
</style>
