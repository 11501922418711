<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr8-hero.png" alt="WebBank and Capital On Tap">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr8-small.png" alt="WebBank and Capital On Tap">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">Atlanta's Capital on Tap secures $200 million funding facility to fuel continued growth</h2>
            <h4 class="news-page-title">Capital on Tap has secured a $200m funding facility with HSBC and Värde Partners to support their continued US growth.</h4>
            <br>
            <h4 class="news-date">April 13th, 2022</h4>
            <br>
            <p>Capital on Tap launched in 2012 and has provided access to over $5 billion of funding for more than 125,000 small
                and medium-sized businesses across the U.K. and U.S. Recognized by Forbes as one of the U.K.'s fastest growing
                businesses, this new investment offers Capital on Tap the opportunity to bolster its impressive expansion in the
                U.S. The Capital on Tap
                <a href="#" @click="clickOutWarning('https://www.capitalontap.com/en/business-credit-cards/')">Business Credit Card</a>, issued by the
                lender WebBank offers leading employee spending controls, reporting, and rewards.</p>
            <br>
            <p>Capital on Tap made Atlanta its American home when it launched the Capital on Tap Business Credit Card in March, 2021,
                helping small and medium-sized businesses recover following the pandemic's harsh economic downturn. With 50 employees
                currently sitting in the Atlanta office and actively recruiting more than a dozen more, Capital on Tap is poised to
                provide leadership and opportunity for Atlanta's elite tech community.</p>
            <br>
            <p>Alan Hart, CFO, Capital on Tap, commented: "With the closing of this facility we are looking forward to expanding our
                ability to provide access to essential funding for small businesses across the United States. We are thrilled to be
                joined in this mission with Värde Partners as well as extending our already international relationship with HSBC."</p>
            <br>
            <p>Aneek Mamik, Partner and Global Co-Head of Financial Services, Värde Partners said: "We are pleased to establish a
                partnership with Capital on Tap as they continue successfully expanding by making available faster and simpler working
                capital and payments tools to empower business owners. We see this as a good example of an emerging substantial investable
                opportunity set for us, providing capital solutions to digitally oriented non-bank companies that are providing access
                to financing the needs of businesses investing in their products and customers."</p>
            <br>
            <p class="news-section-title">About Capital on Tap</p>
            <p>Capital on Tap is a privately held, financial technology company that provides access to funding to small and medium-sized
                businesses in the U.K and U.S. Founded in 2012, Capital on Tap has core offices in London, Atlanta, and Cardiff and has
                provided access to over $5 billion of funding to businesses. For more information visit
                <a href="#" @click="clickOutWarning('https://www.capitalontap.com/')">www.capitalontap.com</a>.</p>
            <br>
            <p class="news-section-title">About Värde Partners</p>
            <p>Värde Partners is a leading global alternative investment firm with roots in credit and distressed. Founded in 1993,
                the firm has invested $85 billion since inception and manages approximately $14 billion on behalf of a global investor
                base. The firm's investments span corporate and traded credit, real estate and mortgages, private equity and direct
                lending. Värde has offices in Minneapolis, New York, London, Singapore and other cities in Asia and Europe. For more
                information, please visit <a href="#" @click="clickOutWarning('https://www.varde.com')">www.varde.com</a>.</p>
            <br>
            <p class="news-section-title">About HSBC</p>
            <p>HSBC serves customers worldwide from offices in 64 countries and territories in its geographical regions: Europe, Asia,
                North America, Latin America, and Middle East and North Africa. With assets of US $2.958 billion at 31 December 2021,
                HSBC is one of the world's largest banking and financial services organizations.</p>
            <br>
            <p class="news-section-title">Press/Media Contact</p>
            <p>Kiera Wiatrak<br>
                404.216.8767<br>
                kiera.wiatrak@capitalontap.com</p>
            <br>
                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "Capital on Tap - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
