<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr12-hero.png" alt="National Partners Acquires Security Premium Finance">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr12-small.png" alt="National Partners Acquires Security Premium Finance">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">News Article</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">National Partners PFco Acquires Security Premium Finance Company, LLC</h2>
            <h4 class="news-page-title">Acquisition Positions National Partners PFco as a Significant Presence in the Commercial Premium Finance Marketplace</h4>
            <br>
            <h4 class="news-date">August 4th, 2022</h4>
            <br>
            <p>National Partners PFco (“National Partners”), a wholly owned subsidiary of WebBank, announced today it has acquired Security Premium Finance Company, LLC (“Security Premium Finance”). Security Premium Finance is a privately held premium finance company founded in 1995 by Bert Irigoyen and based in Coral Gables, Florida. Security Premium Finance provides insurance premium financing services for commercial and consumer clients to purchase property and casualty insurance products in Florida. This acquisition provides National Partners with diversification, geographic market opportunities and a competitive advantage in the industry. As part of the acquisition all Security Premium Finance employees are now employees of National Partners.</p>
            <br>
            <p>“We have been long impressed with the expertise and success of Security Premium, demonstrated by strong customer loyalty and earned through delivery of unparalleled customer service,” said Bruce Lundy, President of National Partners. “Security Premium's partnership with National Partners will allow us to mutually expand and grow throughout Florida and beyond.”</p>
            <br>
            <p>“We believe National Partners is a strong strategic fit for Security Premium Finance that will allow us to grow exponentially in the Florida market and expand into other states,” said Bert Irigoyen, President of Security Premium Finance. “The Security Premium Finance team is excited to join forces with the National Partners team.”</p>
            <br>
            <p class="news-section-title">About National Partners</p>
            <p>Dedicated to providing the highest standards in the premium finance industry, National Partners, a wholly owned subsidiary of WebBank, works with clients of all sizes through its commercial insurance agent and broker relationships. Responsive service, ease of use and creative solutions are its hallmarks. It also offers funding programs to third-party premium finance companies, often those affiliated with agents, brokers, and MGAs, so that these smaller premium finance companies can access capital to grow and expand.</p>
            <br>
            <p class="news-section-title">About WebBank</p>
            <p>WebBank is a Utah chartered Industrial Bank headquartered in Salt Lake City, Utah. Since its inception in 1997, WebBank has originated and funded over $155 billion in consumer and commercial credit products. As “The Bank Behind the Brand®”, WebBank is a national issuer of consumer and small business credit products through Strategic Partner (Brand) platforms, which include retailers, manufacturers, finance companies, software as a service (SaaS) and financial technology (FinTech) companies. The Bank is a leading player in the digital lending space, driving innovation in financial products through embedded finance with Strategic Partner platforms. WebBank engages in a full range of banking activities including consumer and commercial loan products, revolving lines of credit, credit cards, private-label card issuance, auto-refinancing and more. The Bank provides capital in the form of asset-backed lending and other credit facilities to Strategic Partner platforms, credit funds, and other lenders with a targeted focus on specialty finance assets. The Bank is also a leading provider of commercial insurance premium finance products through its wholly owned subsidiary National Partners and Security Premium Finance, a wholly owned subsidiary of National Partners.</p>
            <br>
                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "Security Premium Finance - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
