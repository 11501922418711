<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr29_hero.png"
        alt="PROG Holdings Announces Build">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr29_small.png"
        alt="PROG Holdings Announces Build">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">PROG Holdings Announces Build, an
                                    Innovative Credit Building Financial
                                    Management Tool</h2>
                <br>
                <h4 class="news-page-subtitle">Build users can see credit score increases in as little as 60 days</h4>
            <br>
            <p><strong>SALT LAKE CITY, May 23, 2023</strong> &mdash; <a href="#" @click="clickOutWarning('https://www.progholdings.com')">PROG Holdings, Inc.</a> (NYSE:PRG), the fintech holding
    company for Progressive Leasing, Vive Financial, and Four Technologies, announces <a href="#" @click="clickOutWarning('https://getbuild.com')">Build</a>,
    a financial technology company that believes everyone should have the opportunity to
    improve their financial health through credit building products alongside educational
    financial tools and information.</p>
    <br>
    <p>The Build Credit Builder Account, issued by WebBank, Member FDIC, combines the
    benefits of an installment loan and a secured savings account to help consumers build
    both positive credit history and personal savings.
    Initially launched in December 2021 to a select group of Progressive Leasing customers,
    Build is currently available in 16 states, and the Company expects to be able to offer
    access to consumers in all 50 states and the District of Columbia by the end of 2023.</p>
    <br>
    <h3 class="is-size-4 has-bold-text">Building a Solid Credit Foundation</h3>
    <p>The Build Credit Builder Account is designed to help people utilize regular monthly
    payments to establish positive credit history. According to the Fair Isaac Corporation,
    <a href="#" @click="clickOutWarning('https://www.myfico.com/credit-education/whats-in-your-credit-score')">payment history accounts for 35% of an individual's FICO score</a>.</p>
    <br>
    <p>When a customer opens a Build Credit Builder Account, WebBank creates an installment
    loan and deposits the proceeds into a secured savings deposit account. Customers can
    increase their positive credit history by making on-time regular monthly payments on the
    installment loan. Those payments are reported to all three credit bureaus, Equifax,
    Experian, and Transunion, helping customers that complete scheduled payments on time
    achieve credit score increases in as little as 60 days.</p>
    <br>
    <p>After the final payment is made on the installment loan, the loan is reported to the credit
    bureaus as paid in-full and the associated loan funds, less interest and fees, are provided
    to the customer. If the account is closed prior to the completion of the loan, the associated
    loan funds accumulated to that point, less interest and fees, will be provided to the
    customer at that time.</p>
    <br>
    <p>Furthermore, with a number of Build Credit Builder Account options and repayment
    periods, consumers can choose the solution that best fits their individual credit building
    needs.</p>
    <br>
    <p>“Build represents PROG Holdings' continuing commitment to develop empowering
    products that fill the gaps in traditional personal finance, credit, and payment options. We
    understand the needs of today's consumers and create thoughtfully designed, forward-
    thinking products to support millions of households in their financial goals,” said Steve
    Michaels, President and Chief Executive Officer of PROG Holdings. “Build is a potential
    next step in the financial journey for millions of consumers, including Progressive Leasing
    customers and applicants, looking to improve their credit scores.”</p>
    <br>
    <h3 class="is-size-4 has-bold-text">Unlocking the Possibilities of Tomorrow</h3>
    <p>Build's parent company, <a href="#" @click="clickOutWarning('https://www.progholdings.com')">PROG Holdings, Inc.</a>, helps consumers of all financial
    backgrounds create a better today and unlock the possibilities of tomorrow through
    financial empowerment using inclusive and easy-to-use financial technologies and
    products.</p>
    <br>
    <p>The Company's <a href="#" @click="clickOutWarning('https://progleasing.com')">Progressive Leasing</a> segment helped pioneer the Virtual Lease to Own
    industry over twenty years ago, and since that time it has helped millions of customers
    achieve ownership of the goods and products they need through flexible and transparent
    payment options.</p>
    <br>
    <p>In addition to Build and Progressive Leasing, PROG Holdings' companies include
        <a href="#" @click="clickOutWarning('https://vivecard.com')">Vive Financial</a>,
        an omnichannel provider of second-look revolving credit products, and
    <a href="#" @click="clickOutWarning('https://paywithfour.com')">Four Technologies</a>,
    provider of Buy Now, Pay Later payment options.</p>
    <br>
    <p>"As a leader in building flexible financial and payment solutions that empower consumers,
    PROG Holdings is thrilled to introduce Build, a credit building tool that can help people
    across the country reach their personal financial goals,” said Michaels. “When our
    customers told us that their number one financial goal is to build credit, we saw a
    significant opportunity to offer access to an inclusive and transparent financial credit
    builder option within our suite of fintech products."</p>
    <br>
    <p>For more information about Build, visit <a href="#" @click="clickOutWarning('https://getbuild.com')">https://getbuild.com</a>.</p>
    <br>
    <p><strong><em>About Build:</em></strong></p>
    <p><em>Build is a financial technology company that believes everyone should have the opportunity to improve
    their financial health through credit building products alongside educational financial tools and
    information. Issued by WebBank, Member FDIC, the Build Credit Builder Account allows consumers to
    add payment history to their credit report through regular and timely installment loan payments that are
    reported to all three major credit bureaus. Build is owned by PROG Holdings, Inc. (NYSE:PRG), a fintech
    holding company based in Salt Lake City, Utah. More information on Build can be found on <a href="#" @click="clickOutWarning('https://getbuild.com')">getbuild.com</a>.</em></p>
    <br>
    <p><strong><em>About PROG Holdings, Inc.</em></strong></p>
    <p><em>PROG Holdings, Inc. (NYSE:PRG) is a fintech holding company headquartered in Salt Lake City, UT, that
    provides transparent and competitive payment options and inclusive consumer financial products. The
    Company owns <a href="#" @click="clickOutWarning('https://progleasing.com')">Progressive Leasing</a>, a leading provider of e-commerce, app-based, and in-store point-of-
    sale lease-to-own solutions, <a href="#" @click="clickOutWarning('https://vivecard.com')">Vive Financial</a>, an omnichannel provider of second-look revolving credit
    products, <a href="#" @click="clickOutWarning('https://paywithfour.com')">Four Technologies</a>, provider of Buy Now, Pay Later payment options through its platform Four,
    and <a href="#" @click="clickOutWarning('https://getbuild.com')">Build</a>, provider of personal credit building products. More information on PROG Holdings' companies
    can be found at <a href="#" @click="clickOutWarning('https://www.progholdings.com')">https://www.progholdings.com</a>.</em></p>

    <br>
    <p><strong>Investor Contact</strong><br>
    John A. Baugh, CFA<br>
    VP, Investor Relations<br>
    <a href="mailto:john.baugh@progleasing.com">john.baugh@progleasing.com</a></p>
    <br>
    <p><strong>Media Contact</strong><br>
    Mark Delcorps<br>
    Director, Corporate Communications<br>
    <a href="mailto:media@progholdings.com">media@progholdings.com</a></p>
<br>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "PROG Holdings Announces Build - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
