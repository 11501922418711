<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr13-hero.png" alt="WebBank Collaborates with American Express">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr13-small.png" alt="WebBank Collaborates with American Express">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">WebBank Collaborates with American Express to Expand Credit Card Issuing</h2>
            <h4 class="news-page-title">Alliance will focus on offering payment products on the American Express network through fintechs</h4>
            <br>
            <h4 class="news-date">August 16th, 2022</h4>
            <br>
            <p>WebBank announced today an agreement with American Express to become a card issuer and participant in the American Express network. The collaboration allows WebBank to issue credit card and payment solutions that include American Express benefits. American Express selected WebBank as one of its key issuing banks focused on the fintech industry.</p>
            <br>
            <p>“WebBank is pleased to have a strategic alliance with American Express that broadens the product offerings that we're able to make available through our strategic partner platforms,” said Jason Lloyd, President &amp; CEO of WebBank. “It's a great example of two industry leading organizations creating value for and through the broader fintech community.”</p>
            <br>
            <p>“Integrating WebBank into the American Express ecosystem is another step toward empowering fintechs to offer payment innovations on the Amex network,” said William Stredwick, Senior Vice President and General Manager, Global Network Services North America, American Express. “We look forward to working closely with WebBank to continue expanding the reach of the brand and bringing new benefits to fintechs and consumers.”</p>
            <br>
            <p>WebBank plans to begin issuing Amex cards through fintechs later this year.</p>
            <br>
            <p class="news-section-title">About WebBank</p>
            <p>WebBank  is  a Utah  chartered  Industrial  Bank  headquartered  in  Salt  Lake  City,  Utah.  Since  its inception  in  1997,  WebBank  has  originated  and  funded  over  $155 billion  in  consumer  and commercial credit products.  As “The Bank Behind the Brand<sup>&reg;</sup>”, WebBank is a national issuer of consumer and small business credit products through Strategic Partner (Brand) platforms, which include  retailers,  manufacturers,  finance  companies,  software  as  a  service  (SaaS)  and  financial technology (FinTech) companies.  The Bank is a leading player in the digital lending space, driving innovation  in  financial  products  through  embedded  finance  with  Strategic  Partner  platforms.  WebBank engages in a full range of banking activities including consumer and commercial loan products, revolving lines of credit, credit cards, private-label card issuance, auto-refinancing and more. The Bank provides capital in the form of asset-backed lending and other credit facilities to Strategic  Partner  platforms,  credit  funds,  and  other  lenders  with  a  targeted  focus  on specialty finance  assets.  The  Bank  is  also  a  leading  provider  of  commercial  insurance  premium  finance products  through  its  wholly  owned  subsidiary  National  Partners.  For  more  information,  please visit <a href="https://www.webbank.com">www.webbank.com</a>.</p>
            <br>
            <p class="news-section-title">About American Express</p>
            <p>American Express is a globally integrated payments company, providing customers with access to products, insights and experiences that enrich lives and build business success.
                Learn more at
                <a href="#" @click="clickOutWarning('https://americanexpress.com')">americanexpress.com</a>
                and connect with us on <a href="#" @click="clickOutWarning('https://facebook.com/americanexpress')">facebook.com/americanexpress</a>,
                <a href="#" @click="clickOutWarning('https://instagram.com/americanexpress')">instagram.com/americanexpress</a>,
                <a href="#" @click="clickOutWarning('https://linkedin.com/company/american-express')">linkedin.com/company/american-express</a>,
                <a href="#" @click="clickOutWarning('https://youtube.com/americanexpress')">youtube.com/americanexpress</a>
                <a href="#" @click="clickOutWarning('https://twitter.com/americanexpress')">twitter.com/americanexpress</a>, and
                <a href="#" @click="clickOutWarning('https://youtube.com/americanexpress')">youtube.com/americanexpress</a>.</p>

            <p>Key links to products, services and corporate responsibility information:
                <a href="#" @click="clickOutWarning('https://www.americanexpress.com/us/credit-cards/')">personal cards</a>,
                <a href="#" @click="clickOutWarning('https://www.americanexpress.com/us/small-business/credit-cards/')">business cards</a>,
                <a href="#" @click="clickOutWarning('https://www.americanexpress.com/en-us/travel?extlink=us-tls-ps-ps-1020&ds_kid=43700044767697172&gclid=EAIaIQobChMIqPKmzs-S8wIVi2pvBB2vQQ9IEAAYASAAEgIvmvD_BwE&gclsrc=aw.ds')">travel services</a>,
                <a href="#" @click="clickOutWarning('http://www.americanexpress.com/gift-cards/?extlink=dw-us-pr-bp')">gift cards</a>,
                <a href="#" @click="clickOutWarning('https://www.americanexpress.com/us/prepaid/american-express-prepaid-card/home.html')">prepaid cards</a>,
                <a href="#" @click="clickOutWarning('https://www.americanexpress.com/us/merchant/merchant-home.html')">merchant services</a>,
                <a href="#" @click="clickOutWarning('http://www.accertify.com/')">Accertify</a>,
                <a href="#" @click="clickOutWarning('https://www.kabbage.com/')">Kabbage</a>,
                <a href="#" @click="clickOutWarning('https://resy.com/')">Resy</a>,
                <a href="#" @click="clickOutWarning('http://business.americanexpress.com/us?extlink=dw-us-pr-bp')">corporate card</a>,
                <a href="#" @click="clickOutWarning('https://www.amexglobalbusinesstravel.com/')">business travel</a>,
                <a href="#" @click="clickOutWarning('https://about.americanexpress.com/home/default.aspx#diversity')">diversity and inclusion</a>,
                <a href="#" @click="clickOutWarning('http://about.americanexpress.com/csr/?inav=about_CorpResponsibility')">corporate responsibility</a> and
                <a href="#" @click="clickOutWarning('https://about.americanexpress.com/corporate-responsibility/reports/corporate-responsibility-reports/default.aspx')">Environmental, Social, and Governance reports</a>.</p>
                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "WebBank Collaborates with American Express - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
