<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr11-hero.png" alt="WebBank and ABF Journal">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr11-small.png" alt="WebBank and ABF Journal">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">News Article</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">WebBank Named One of 2022 Most Innovative Companies in Specialty Finance by ABF Journal</h2>
            <h4 class="news-page-title">HQ: Salt Lake City</h4>
            <br>
            <h4 class="news-date">July 21st, 2022</h4>
            <br>
            <p>ABF Journal selected WebBank as one of the most innovative companies in Specialty Finance in 2022. The Bank received the award for Reinvention in ABF Journal's list of innovator categories. The Reinvention award highlights WebBank as a company that is redefining its place in the industry and driving innovation in Fintech.</p>
            <br>
            <p>The following article about WebBank's Specialty Finance business appeared in the Q2 2022 ABF Journal digital magazine.</p>
            <br>
            <p><em>WebBank is a national issuer of consumer and small business credit that differentiates itself through its flexibility, creativity, innovative underwriting and ability to work across varied and unique asset classes.</em></p>
            <br>
            <p>Over the past 10 years, WebBank has originated and funded more than $150 billion in consumer and commercial credit. As “The Bank Behind the Brand,” WebBank issues credit through strategic partner platforms, which include retailers, manufacturers, finance companies and financial technology companies. The bank also provides capital in the form of asset-based lending and other credit facilities to Strategic Partner platforms, credit funds and other lenders with a targeted focus on Specialty Finance assets.</p>
            <br>
            <p>WebBank differentiates itself in the Specialty Finance space through its dual superpowers of flexibility and creativity. In 2021, it closed committed asset-based credit facilities totaling more than $500 million across a variety of partners, products and industries.</p>
            <br>
            <p>WebBank also differentiates itself from other banks by using innovative methods to underwrite a wide range of collateral and structures. WebBank has underwritten facilities backed by a variety of asset classes and cash flows, including: loans backed by ad revenue from YouTube content, mobile app developers' Apple and Google app store receivables and software-as-a-service and technology companies' recurring revenue, as well as more traditional facilities backed by small business receivables, merchant cash advances, solar leases to low-to-middle income households, student loans, auto loans, multi-family residential leases, middle-market loans and consumer assets. It is also a crypto-friendly bank and is working on structures to finance financial technology companies that offer loans backed by cryptocurrencies.</p>
            <br>
            <figure class="image">
                <router-link to="/">
                <img src="https://assets.webbank.com/webbank-logo.svg" alt="WebBank Logo" style="width: 300px;">
                </router-link>
            </figure>
            <br>
            <p>Additionally, WebBank offers innovative and flexible fund-level and deal-level structures for providing senior and back leverage to credit funds that are tailored to meet their specific needs, which allows them to improve their returns and provide lower pricing and additional committed volume on their asset-based warehouse lines to their borrowers.</p>
            <br>
            <p><em>“[WebBank is] empowering fintech strategic partners to drive innovation and financial inclusion.”</em></p>
            <br>
                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "ABF Journal - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
