<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr27_hero.png"
        alt="Petal Raises $35 Million">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr27_small.png"
        alt="Petal Raises $35 Million">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">Petal Raises $35 Million in New Funding,
                                Spins Off B2B Data Analytics Unit Prism Data</h2>
                <br>
                <h4 class="news-page-subtitle">Funding includes strategic investments from Synchrony and Samsung Next,
                        along with other new and existing investors</h4>
            <br>
            <p><strong>NEW YORK – MAY 10, 2023 –</strong> Credit card and financial technology company Petal announced
    today that it has raised $35 million in new funding and has spun off its B2B-focused data
    infrastructure and analytics subsidiary, Prism Data.</p>
    <br>
    <p>Petal pioneered the use of automated cash flow underwriting in consumer credit beginning in
    2016, and after years of developing, refining and advancing its proprietary CashScore™
    technology in-market, <a href="#" @click="clickOutWarning('https://medium.com/petal/from-raw-data-to-actionable-insights-introducing-prism-data-and-the-cashscore-bb7c97ff407e')">established</a> Prism Data to give other financial providers the same
    infrastructure and intelligence to improve underwriting decisions and expand access to credit.
    This spinoff will result in two independent, world-class financial technology companies that
    leverage cash flow underwriting and consumer-authorized financial information—sometimes
    referred to as “open banking data”—to provide products that expand consumer access to
    financial services. Instead of relying solely on traditional credit scores and reports, cash flow
    underwriting measures creditworthiness based on banking history—like consumer earning,
    saving, and spending trends. This advances financial inclusion by providing an effective
    measure of creditworthiness for the millions of consumers without accurate credit scores; it also
    provides a more precise assessment of creditworthiness overall for any consumer that is
    additive beyond a traditional score.</p>
    <br>
    <p>Petal uses this innovative approach to serve consumers directly, while Prism Data provides
    cash flow underwriting technology to financial institutions, fintechs, and other businesses,
    helping them leverage open banking to build better products and make better credit decisions.
    Valar Ventures led this new investment, joined by Story Ventures, Core Innovation Capital, and
    RiverPark Ventures, among others. The funding includes strategic investments from Synchrony
    and Samsung Next. This new investment will be split between Petal and Prism Data.</p>
    <br>
    <p>Petal was founded in 2016 to help people establish and build credit responsibly, by providing
    access to high-quality Visa® credit cards (issued by WebBank). People can qualify for Petal
    cards even if they’ve never used credit before or have little previous credit history. To date,
    nearly 400,000 consumers have been approved for a Petal credit card, including more than
    100,000 new cards approved in 2022. Petal generated more than $80 million in annualized
    revenue last year, putting the company on a path to reach profitability in 2024.</p>
    <br>
    <p>Prism Data’s open banking infrastructure and analytics platform helps clients improve
    underwriting capabilities, minimize first-party fraud, and better manage servicing of their
    portfolios. Prism has seen significant traction since its launch, signing more than a dozen clients
    and partners and completing over two dozen successful pilots, including with some of the
    country’s largest banks. To date, Prism’s CashScore has been used to inform nearly $2 billion in
    real-world credit originations for hundreds of thousands of consumers.</p>
    <br>
    <p>“The first wave of fintech was about inventing new approaches. We’ve seen this explosion in
    activity with companies testing out new models, technologies, and financial products,” said
    Jason Rosen, the CEO and co-founder of both Petal and Prism Data. “Not every new
    experiment works. But for breakthrough technologies that have proven effective, like the cash
    flow underwriting we’ve developed at Petal and Prism Data, the time has come to expand to the
    mainstream. This has profound implications for financial inclusion, and we couldn’t be more
    excited to partner with forward-thinking industry leaders like Synchrony and Samsung to drive
    true innovation at scale.”</p>
    <br>
    <p>“At Synchrony, we have a deep history of using data to make informed lending decisions and
    create faster, seamless personalized customer experiences,” said Trish Mosconi, Executive
    Vice President and Chief Strategy Corporate Development Officer at Synchrony. “The Prism
    Data platform is innovative in providing differentiated consumer insights, enabling financial
    institutions to make more data-driven decisions. We look forward to exploring partnerships with
    Petal and Prism Data to help improve access to credit.”</p>
    <br>
    <p>In December 2022, Prism announced CashScore v3, the first-ever consortium-based cash flow
    underwriting model, built using millions of anonymized, consumer-permissioned records from a
    variety of different credit providers, credit products and customer segments. Similar to traditional
    credit models like FICO or VantageScore, Prism’s CashScore v3 offers lenders a highly
    predictive tool they can use off-the-shelf to more precisely assess credit risk in any consumer
    context—from credit cards and personal loans to mortgages and auto loans, among others.
    The CashScore is part of Prism’s larger suite of innovative software products that enable
    lenders and other businesses to understand and use open banking data to build better
    consumer products and make smarter credit decisions. In addition to the CashScore, these
    products include Prism’s Insights, which distills categorized bank account transactions into
    thousands of trended attributes, including details on income stability and timing, balance
    trajectories, savings rates, rental payments, use of emerging forms of borrowing like Buy Now,
    Pay Later (BNPL) loans and much more.</p>
    <br>
    <p>“Prism Data was founded on the belief that open banking, and access to consumer-
    permissioned bank account transactional data, will change the way consumer finance works—in
    credit, payments, banking, insurance, real estate, financial advising, and more,” said Erin Allard,
    Prism’s General Manager. “With open banking’s arrival in the U.S., that is becoming a reality.
    And as an independent company, Prism is well-positioned to partner with financial providers and
    give them the tools and infrastructure they need to create next-generation products and
    capabilities.”</p>
    <br>
    <p>“Prism Data presents a sea-change opportunity in credit scoring and analytics,” said Arjan
    Schutte, Founder and Managing Partner of Core Innovation Capital. “While Prism is a new
    company, the core technology and approach have been honed in the market by Petal for many
    years. The potential for much-needed financial inclusion through a cash-flow-based score that is
    compliant with consumer regulation is massive.”</p>
    <br>
    <p>With the addition of this new funding, Petal has raised nearly $300 million in equity capital and
    more than $450 million in debt financing.</p>
    <br>
            <p class="news-section-title">About Petal</p>

            <p>Petal is a new kind of credit card company built to help people financially succeed. Petal has pioneered
    cash flow underwriting, as an alternative to traditional credit scores, to make credit more accessible, and
    offers a simple, modern digital experience that encourages members to build credit, avoid debt, and
    spend responsibly. Petal is based in New York, NY, and Richmond, VA. Petal credit cards are issued by
    WebBank.</p>
<br>
                <p class="news-section-title">About Prism Data</p>

                <p>Prism Data provides essential open banking infrastructure and data analytics powering the next
    generation of credit scoring. Prism’s market-leading suite of products – including its proprietary
    CashScore™ – enable financial institutions, fintechs and other companies to use advanced open banking
    analytics and cash flow underwriting to derive powerful new insights from consumer-permissioned deposit
    account transaction data. Prism clients can use those insights to accurately predict credit default risk,
    score missing- and thin-file customers, identify first-party fraud, and better manage their existing
    portfolios.</p>
    <br>
<br>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "Petal Raises $35 Million - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
