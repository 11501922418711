<template>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                    <div class="content">
                    <br class="is-hidden-mobile is-hidden-tablet">
                        <br class="is-hidden-mobile is-hidden-tablet">
                        <br class="is-hidden-mobile is-hidden-tablet">
                    <h3 class="is-size-1 has-text-primary section-title">Great Careers Perfect Balance</h3>
                        <p class="">Come see why WebBank has earned the reputation of being the best in the industry.
                            Explore career opportunities at WebBank and discover pathways to success that don't bypass
                            the work-life balance that's as important to us as it is to you.</p>
                        <br class="is-hidden-mobile is-hidden-tablet">
                        <div class="columns">
                            <div class="column is-12">
                                <br>
                                <p>WebBank is proud to be named a Glassdoor Best Places to Work in 2024 and for being ranked second on the list of 50 small to medium sized businesses that received this distinction. We are also happy to be 19th on American Banker's 2023 list of the Best Places to Work in Fintech. These awards are based on the input of employees, who provide feedback about their job, work environment, and their experience working at WebBank. We credit these awards to our employees for fostering a best-in-class culture.</p>
                            </div>
                        </div>
                        <div class="columns is-mobile">
                            <div class="column is-8-desktop is-12-mobile is-offset-2-desktop">
                                <img src="https://assets.webbank.com/ab_glassdoor_logo2.png" alt="American Banker and Glassdoor Awards" style="width: 90%;">
                            </div>
                        </div>
                    </div>

                </div>
                <div class="column">
                    <br class="is-hidden-mobile">
                    <br class="is-hidden-mobile">
                    <br class="is-hidden-mobile">
                    <br>
                    <img src="https://assets.webbank.com/careers1.png" class="body-image" alt="Spencer Rockin' the Ping Pong!" />
                </div>
            </div>

        </div>
    </section>

    <div class="blue-bg ">
        <section class="section">
            <div class="container">
                <div class="has-text-centered">
                    <h3 class="has-text-white hero-title is-hidden-mobile">Wide-Ranging Benefits</h3>
                    <h3 class="is-size-3 has-text-white section-title is-hidden-desktop">Wide-Ranging Benefits</h3>
                </div>
                <br>
                <br>
<div class="tile is-ancestor">
  <div class="tile is-vertical">
    <div class="tile">
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
            <br>
            <br>
            <span class="icon diff-icon"><i class="fa-light fa-house-heart fa-4x"></i></span>
            <br>
            <br>
            <h4 class="box-title">Flexible Work Options</h4>
            <p class="is-size-5">Flexible work-from-home opportunities and other hybrid workplace options are available. </p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
            <br>
            <br>
            <span class="icon diff-icon"><i class="fa-light fa-umbrella-beach fa-4x"></i></span>
            <br>
            <br>
            <h4 class="box-title">Paid Time Off (PTO)</h4>
            <p class="is-size-5">We offer generous paid time off plus paid parental leave. </p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
            <br>
            <br>
            <span class="icon diff-icon"><i class="fa-light fa-hand-holding-medical fa-4x"></i></span>
            <br>
            <br>
            <h4 class="box-title">Health Insurance</h4>
            <p class="is-size-5">Plans include an employer paid medical option, dental and vision coverage, plus we offer health savings accounts.</p>
        </article>
      </div>
    </div>
    <div class="tile">
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
            <br>
            <br>
            <span class="icon diff-icon"><i class="fa-light fa-piggy-bank fa-4x"></i></span>
            <br>
            <br>
            <h4 class="box-title">401(k)</h4>
            <p class="is-size-5">WebBank matches up to the first 6 percent of employee contributions and both the employee's contributions and WebBank's match are fully vested immediately. </p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
            <br>
            <br>
            <span class="icon diff-icon"><i class="fa-light fa-hands-holding-dollar fa-4x"></i></span>
            <br>
            <br>
            <h4 class="box-title">Life and Disability Insurance</h4>
            <p class="is-size-5">Life insurance and long and short-term disability insurance plans are 100% employer paid.</p>
        </article>
      </div>
      <div class="tile is-parent">
        <article class="tile is-child box has-text-centered">
            <br>
            <br>
            <span class="icon diff-icon"><i class="fa-light fa-user-graduate fa-4x"></i></span>
            <br>
            <br>
            <h4 class="box-title">Tuition Reimbursement</h4>
            <p class="is-size-5">WebBank provides reimbursement for classes needed to obtain certain degrees, up to $5,250 per academic year.</p>
        </article>
      </div>
    </div>
  </div>
</div>

            </div>
        </section>
        <br><br>
    </div>


    <section class="section">
        <div class="container has-text-centered">
            <a href="#" title="WebBank on Glassdoor" @click="clickOutWarning('https://www.glassdoor.com/Overview/Working-at-WebBank-EI_IE307070.11,18.htm')">
                <img alt="Find us on Glassdoor." src="https://www.glassdoor.com.au/api/widget/verticalStarRating.htm?e=307070">
            </a>
            <br>
            <br>
            <br>
            <br>
            <a href="#" title="Open Positions at WebBank" @click="clickOutWarning('https://boards.greenhouse.io/webbank')" class="button is-primary is-large">Open Positions</a>
            <br>
            <br>
            <br>
        </div>
    </section>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
</div>

      </div>
  </div>
</div>

<CareersHero parent="working" />

</template>

<script>
import CareersHero from '@/components/CareersHero.vue';
export default {
    components: {
        CareersHero
    },

    created() {
        document.title = "Careers - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },

        closeAllPartnerModals: function () {
            this.avant_modal = false;
        }
    }
}
</script>
