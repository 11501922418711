<template>
                        <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
                          <img src="https://assets.webbank.com/newsroom/pr5-hero.png" alt="WebBank and DigniFi">
                        </figure>
                        <figure class="image is-hidden-widescreen">
                          <img src="https://assets.webbank.com/newsroom/pr5-small.png" alt="WebBank and DigniFi">
                        </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                        <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                            <span class="icon"><i class="fas fa-arrow-left"></i></span>
                            <span>Back to Newsroom</span>
                        </router-link>
                            <p class="press-release">Press Release</p>
                    <br>
                        <div class="content">
            <h2 class="news-page-title">DigniFi Welcomes Pagaya to Its Inclusive Financing Platform, Expanding Lending Services to Small Businesses</h2>
            <h4 class="news-page-title">Pagaya, a financial technology company working to reshape the financial ecosystem, is helping DigniFi offer over 6,000 small businesses and theircustomers increased access to on-the-spot financing.</h4>
            <br>
            <h4 class="news-date">May 10th, 2022</h4>
            <br>
            <p>DigniFi, an inclusive financing platform whose credit branded products are issued by WebBank, announced that Pagaya
                has joined the platform as a credit analysis partner. By supplementing the standard underwriting for DigniFi-branded
                loans with Pagaya's unique credit analysis model, Pagaya supports DigniFi's efforts to fund up to $80 billion worth
                of repairs that might otherwise be deferred and supports up to 80 million hard-working people with inadequate
                access to the financial resources they need to stay safe while driving.</p>
            <br>
            <p>In 2012, DigniFi set-out to redefine vehicle financing by breaking down the barriers everyday people encounter when seeking
                access to funds. Today, DigniFi is a leader in the world of transportation, helping small businesses across the country
                grow revenue and delight their consumers through visionary, inclusive financing solutions.</p>
            <br>
            <p>Pagaya uses a proprietary AI to help partners, like DigniFi, better serve their current and prospective customers. This
                allows DigniFi to provide expanded services to more drivers and to assist in the approval of higher-cost repairs.</p>
            <br>
            <p>“We believe more people are qualified borrowers than the traditional methods would suggest,” said Ben Blatt, Chief Business
                Officer at Pagaya. “By joining DigniFi's innovative financing platform, we can help them connect with an underserved
                community of borrowers who need working cars to make a living and take care of their families.”</p>
            <br>
            <p class="news-section-title">About Pagaya</p>
            <p>Pagaya is a financial technology company working to reshape the financial ecosystem by using machine learning, big data
                analytics, and sophisticated AI-driven credit and analysis technology. Pagaya was built to provide a comprehensive
                solution to enable the credit industry to deliver their customers a positive experience while simultaneously enhancing
                the broader credit ecosystem. Its proprietary API seamlessly integrates into its next-gen infrastructure network of
                partners to deliver a premium customer user experience and greater access to credit. Pagaya and EJF Acquisition Corp.
                (NASDAQ: EJFAU, EJFA,EJFAW) announced in September that they have entered into a definitive business combination
                agreement, valued at an estimated enterprise value of approximately $8.5 billion at closing. For more information
                on Pagaya's technology, services, and careers, please visit <a href="#" @click="clickOutWarning('https://www.pagaya.com')">www.Pagaya.com</a>.</p>
            <br>
            <p class="news-section-title">About DigniFi</p>
            <p>DigniFi is a FinTech company and leader in the world of transportation. We help auto dealers and small businesses
                across the country grow their revenue and delight their customers through visionary, inclusive financing. Our technology
                simplifies the loan application process and enables small businesses to offer on-the-spot financing, for auto repairs,
                parts and accessory purchases, and maintenance packages. All credit products are originated by WebBank, Member FDIC.
                To date, DigniFi has helped over 6,600 small businesses across the nation secure over $240 million in incremental
                transactions. For more information, please visit
                <a href="#" @click="clickOutWarning('https://www.dignifi.com')">DigniFi.com</a>.</p>
            <br>
            <p class="news-section-title">Contacts</p>
            <p>For all Pagaya media inquiries, please reach out to Lisa Horton at ASTRSK PR at Pagaya@astrskpr.com</p>

                        </div>
                </div>
            </div>
        </div>

<div class="modal" :class="{'is-active': clickout_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box">
          <h2 class="box-title">You are leaving our site!</h2>
          <br>
          <p>You will be linking to another website not owned or operated by WebBank.</p>
          <br>
          <p>WebBank is not responsible for the availability or content of this website and does not represent either the linked website or you, should you enter into a transaction.</p>
          <br>
          <p>The inclusion of any hyperlink does not imply any endorsement, investigation, verification or monitoring by WebBank of any information in any hyperlinked site. We encourage you to review their privacy and security policies which may differ from WebBank.</p>
          <br>
          <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
          <br>
            <br><div class="field is-grouped">
            <div class="control">
                <button class="button is-link" @click="doClickout">Proceed</button>
            </div>
            <div class="control">
                <button class="button is-link is-light" @click="clickout_modal = false">Cancel</button>
            </div>
            </div>
      </div>
  </div>
</div>

    </section>

</template>

<script>
export default {

    created() {
        document.title = "DigniFi Welcomes Pagaya - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
