<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr40_hero.png" alt="New and improved Klarna Card">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr40_small.png" alt="New and improved Klarna Card">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">New and improved Klarna Card announced in the US, empowering smarter
                            spending and greater financial control</h2>
                        <h3 class="news-page-subtitle">Waitlist for Klarna Card is now open to US consumers, enabling
                            flexible payments everywhere</h3>
                        <br>
                        <p><em><small>April 17, 2024</small></em></p>
                        <p><em><small>New York, NY</small></em></p>
                        <br>
                        <p>New York, NY - April 17 2024 &mdash; Klarna, the AI powered global payments network and shopping
                            assistant, today announced it has opened its waitlist for the new Klarna Card to all US
                            consumers. The Klarna Card, issued by WebBank, offers flexible payment options in both
                            physical stores and online, empowering consumers to make purchases anywhere Visa is
                            accepted; and with no foreign transaction fees while abroad. On the heels of the Klarna
                            Card's success in Europe, the new offering follows Klarna's track record of providing sustainable and flexible payment
                            options everywhere, and is a welcome alternative to high-cost credit cards with hidden
                            fees.redit, enabling greater participation in established and emerging credit economies.
                        </p>
                        <br>
                        <p>"We're finally bringing the new Klarna Card to the US market, a product that gives
                            consumers more financial control to choose how they want to pay,” said Sebastian
                            Siemiatkowski, Co-Founder and CEO of Klarna. “Unlike many traditional credit cards, the
                            Klarna Card is fully transparent, designed to give consumers increased payment options and
                            flexibility so they have a smarter way to manage their finances."</p>
                        <br>
                        <p>In 2023 credit card debt hit a new record high of $1.13 trillion, according to the Federal
                            Reserve Bank of New York. The Klarna Card offers a healthier and more sustainable option,
                            with payment flexibility, including an option to pay a monthly statement in full at zero
                            interest, pay earlier than the statement due date at zero intest, or to pay over time move a
                            purchase due date with interest. This variety of choice grants consumers the power to budget and manage
                            credit according to their needs, promoting responsible spending without the pitfalls of traditional
                            credit cards.</p>
                        <br>
                        <p>The Klarna Card seamlessly integrates into Klarna's best-in-class shopping experience with
                            the Klarna app. Card users in the app can find the best deal and buy it in a few taps
                            without needing to add their card details, personal details or activating any other shopping
                            card at checkout. Once purchased, customers have the option to change how they want to repay
                            by updating their payment method and track their spending all in one place, giving them greater
                            financial control.</p>
                        <br>
                        <p>Key features of the new Klarna Card include:
                        <ul>
                            <li>No monthly or annual fee</li>
                            <li>Pay anywhere Visa is accepted, including in-store, online, and internationally</li>
                            <li>Zero foreign transaction fees when shopping abroad</li>
                            <li>Physical and virtual Visa card compatible with Google and Apple Pay</li>
                            <li>Flexible payment options with no revolving credit, including:
                                <ul>
                                    <li>Pay monthly statement in full with zero interest</li>
                                    <li>Pay for transactions early with zero interest</li>
                                    <li>Move a purchase due date to the next month's statement (interest applicable)</li>
                                    <li>Pay over time on eligible purchases in 3 or 6 monthly payments (a 33.99% APR
                                        applies to Pay Over Months plans)</li>
                                </ul>
                            </li>
                            <li>Full spending overview in the Klarna app for financial budgeting</li>
                            <li>Seamless integration with the Klarna app enabling consumers to find the best deals</li>
                        </ul>
                        </p>
                        <p>The Klarna Card is subject to eligibility based on the following criteria:
                        <ul>
                            <li>Users need to be at least 18 years of age</li>
                            <li>US resident</li>
                            <li>Have previously purchased with Klarna and repaid on time at least once</li>
                            <li>Pass the credit assessment, based on internal and external credit checks</li>
                        </ul>
                        </p>
                        <p>A recent survey by Klarna and Harris Poll reveals 41% of American credit card users are
                            revolving month-to-month, with approximately one quarter of credit card users (23%)
                            saying they have paid their credit card late and incurred a late fee, or had a credit card
                            payment go into debt collection over the last year. In comparison Klarna's data shows 31% of
                            Klarna BNPL users paid their bills early, 65% paid on time, and only 4% incurred late fees in 2023.
                            Additionally, Klarna's global default rate stands at less than 1%, significantly lower than the
                            delinquency rates associated with credit cards, and 99% of payments are repaid in full and
                            on time.</p>
                        <br>
                        <p>Consumers can sign up for the Klarna Card waitlist here, rolling out over the next few months.</p>
                        <br>
                        <p><strong>ABOUT KLARNA</strong></p>
                        <p>Since 2005 Klarna has been on a mission to accelerate commerce with consumer needs at
                            the heart of it. With over 150 million global active users and 2 million transactions per
                            day, Klarna's fair, sustainable and AI-powered payment and shopping solutions are revolutionizing
                            the way people shop and pay online and in-store, empowering consumers to shop smarter
                            with greater conidence and convenience. More than 500,000 global retailers integrate
                            Klarna's innovative technology and marketing solutions to drive growth and loyalty,
                            including H&amp;M, Saks, Sephora, Macy's, Ikea, Expedia Group, Nike and Airbnb. For more information,
                            visit <a @click="clickOutWarning('https://www.klarna.com');">Klarna.com</a>.</p>
                        <br>
                        <p><strong>Contact:</strong></p>
                        <p>Shira Rimini<br>
                            <a href="mailto:Press@klarna.com">Press@klarna.com</a><br>
                            <a href="tel:+16149624605">614-962-4605</a>
                        </p>
                    </div>

                    <div class=" modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website
                                    and does not represent either the linked website or you, should you enter into a
                                    transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked
                                    site. We encourage you to review their privacy and security policies which may differ
                                    from WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "New and improved Klarna Card";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
