<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-sanford.png" class="is-rounded" alt="Steve Stanford">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Steve Stanford</h2>
            <h3 class="people-title">EVP Chief Financial Officer</h3>
            <p class="people-bio">
Steve oversees the accounting and financial reporting at the Bank. He joined WebBank in 2007. Prior to joining the Bank, Steve was in public accounting, focusing on public company reporting and the financial services industry. He is a certified public accountant. Steve received a B.S. and M.P.A. from Weber State University. He is a Certified
    Public Accountant.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
