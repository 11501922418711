<template>
    <nav class="pagination is-centered is-large" role="navigation" aria-label="pagination">
      <ul class="pagination-list">
        <li><router-link to="/newsroom" class="pagination-link" :class="{'is-current': route.path === '/newsroom'}" aria-label="Goto page 1">Page 1</router-link></li>
        <li><router-link to="/newsroom2" class="pagination-link" :class="{ 'is-current': route.path === '/newsroom2' }" aria-label="Goto page 2">Page 2</router-link></li>
      </ul>
    </nav>
</template>

<script>
import { useRoute } from 'vue-router'
export default {
    name: 'NewsroomPagination',

    data() {
        return {
            route: useRoute(),
        }
    },

    mounted() {
      console.log('current route in pagination: ' + this.route.path)
    }
}
</script>
