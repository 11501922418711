<template>
    <figure class="image is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only" style="margin-top: 30px;">
        <img src="https://assets.webbank.com/newsroom/pr33-hero.png" alt="Primer Zip Partnership">
    </figure>
    <figure class="image is-hidden-widescreen">
        <img src="https://assets.webbank.com/newsroom/pr33-small.png" alt="Primer Zip Partnership">
    </figure>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-8 is-offset-2">

                    <router-link class="button is-primary is-small is-pulled-right" to="/newsroom">
                        <span class="icon"><i class="fas fa-arrow-left"></i></span>
                        <span>Back to Newsroom</span>
                    </router-link>
                    <p class="press-release">Press Release</p>
                    <br>
                    <div class="content">
                        <h2 class="news-page-title">Zip Partners with Global Payments Leader Primer to Accelerate U.S. Growth and Market Share</h2>
                        <br>
                            <p><em><small>September 12th, 2023</small></em></p>
                            <br>
                        <p>Leading digital finance innovator <a @click="clickOutWarning('https://www.zip.co/us');">Zip Co</a>  (ASX: ZIP) has announced a strategic partnership with <a @click="clickOutWarning('https://www.primer.io');">Primer</a>, the unified infrastructure for global payments and commerce, to expand and optimize its payments stack, as Zip further accelerates its growth opportunity in the US market.</p>
                        <br>
                        <p>The announcement follows Zip's recent year-end results, which revealed record transaction volumes (TTV) of $8.9b (up 7.0% YoY) and record group revenue of $693.2m (up 16.1% YoY). In the US, Zip exited FY23 with positive cash EBTDA on a sustainable basis.</p>
                        <br>
                        <p>Primer unifies payment and commerce services while delivering a powerful set of payment recovery, success and insights tools to provide businesses with unrivaled flexibility and visibility across their payments lifecycle. They will support Zip to access and connect new card acquirers and processors, fraud and communication tools to boost top line, while leveraging features such as fallbacks to reduce operational costs and build for contingencies.</p>
                        <br>
                        <p>The partnership with Primer will further drive distribution of Zip's BNPL offering, lender WebBank, with plans for Primer to also add Zip as a payment option, enabling merchants across retail, fashion, travel and mobility industries to add Zip to their payments stack with just a few clicks.</p>
                        <br>
                        <p><strong>Gabriel Le Roux, Co-Founder of Primer said:</strong> “We're thrilled to be the infrastructure of choice for Zip. While delivering localized payments expertise, optimization features and insights, we're supporting leading companies like Zip to optimize payment costs and capture untapped revenue opportunities. Similarly to Zip, the U.S. is a strategic market for Primer, where we have experienced substantial growth and we anticipate a remarkable 30-fold surge in payment volume in 2023. We can't wait to embark on this partnership and partner with Zip to support the company's growth goals in the North American market.”</p>
                        <br>
                        <p><strong>Zip Co-Founder and US CEO Larry Diamond added:</strong> With an $11 trillion total addressable market in the U.S., BNPL services account for a mere 2% of all payments. Our partnership with Primer not only accelerates Zip's growth trajectory but also positions us to seize this colossal market opportunity in a scalable and sustainable manner”</p>
                        <br>
                        <p><strong>About Zip</strong></p>
                        <p>ASX-listed Zip Co Limited (ASX: ZIP) is a leading global financial services company, offering innovative, people-centered products that bring customers and merchants together. On a mission to be the first payment choice everywhere and every day, Zip offers point-of-sale credit and digital payment services around the world, including through strategic investments, connecting millions of customers with its global network of tens of thousands of merchants. Zip provides fair, flexible, and transparent payment options to individual consumers, helping them take control of their financial future and helping merchants to grow their businesses. Zip is committed to responsible lending and is managed by a team with over 100 years of collective experience in retail finance and payments. Zip is also a licensed and regulated credit provider and was founded in Australia in 2013. For more information, visit: <a @click="clickOutWarning('https://www.zip.co/us');">www.zip.co</a></p>
                        <br>
                        <p><strong>About Primer</strong></p>
                        <p>Primer is the unified infrastructure for global payments and commerce. With unrivaled freedom and visibility across the payments and commerce ecosystem, Primer equips merchants with the tools to effortlessly optimize performance, build at pace, and capture untapped revenue to achieve unstoppable growth.<br>
                        <br>Operating in over 35 countries, Primer serves category leaders in retail & ecommerce, travel & mobility, ticketing, and fintech. It has raised over US$74M, backed by the likes of Silicon Valley heavyweights Iconiq, Accel, Balderton, and RTP Global.<br>
                        <a @click="clickOutWarning('https://www.primer.io');">www.primer.io</a></p>
                        <br>
                    <p><strong>Media Contacts:</strong></p>
                    <br>
                    <p>For Primer<br>
                    Shreya Varadarajan<br>
                    +65 9360 4470<br>
                    <a href="mailto:shreya.varadarajan@baldwinboyle.com">shreya.varadarajan@baldwinboyle.com</a>
                    </p>
                    <br>
                    <p>For Zip<br>
                        Angela Nibbs<br>
                        <a href="mailto:zip_us@maven-pr.com">zip_us@maven-pr.com</a></p>
                    </div>

                    <div class="modal" :class="{ 'is-active': clickout_modal }">
                        <div class="modal-background"></div>
                        <div class="modal-content">
                            <div class="box">
                                <h2 class="box-title">You are leaving our site!</h2>
                                <br>
                                <p>You will be linking to another website not owned or operated by WebBank.</p>
                                <br>
                                <p>WebBank is not responsible for the availability or content of this website and does not
                                    represent either the linked website or you, should you enter into a transaction.</p>
                                <br>
                                <p>The inclusion of any hyperlink does not imply any endorsement, investigation,
                                    verification or monitoring by WebBank of any information in any hyperlinked site. We
                                    encourage you to review their privacy and security policies which may differ from
                                    WebBank.</p>
                                <br>
                                <p>If you click &quot;Proceed&quot;, the link will open in a new window.</p>
                                <br>
                                <br>
                                <div class="field is-grouped">
                                    <div class="control">
                                        <button class="button is-link" @click="doClickout">Proceed</button>
                                    </div>
                                    <div class="control">
                                        <button class="button is-link is-light"
                                            @click="clickout_modal = false">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    created() {
        document.title = "Primer Zip Partnership - WebBank";
    },

    data: function () {
        return {
            clickout_modal: false,
            clickout_url: null,
        }
    },

    methods: {
        clickOutWarning: function (url) {
            this.clickout_url = url;
            this.clickout_modal = true;
        },


        doClickout: function () {
            this.clickout_modal = false;
            window.open(this.clickout_url, "_blank");
        },
    }
}
</script>
