<template>
<div>

    <div class="box partner-box" style="display: grid; place-items: center;" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/atlanticus.png" style="max-width: 75%;" alt="Atlanticus Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div style="display: grid; place-items: center;">
            <img src="https://assets.webbank.com/partners/atlanticus.png" style="max-width: 200px;" alt="Atlanticus Logo">
          </div>
            <br>
            <p>In partnership with Atlanticus, WebBank is the issuer and lender of the Imagine Card. If you have questions about your account, please contact Atlanticus at (888) 549-0040.
                If you are interested in applying for the Imagine Card, click <a href="#" title="The Imagine Card" @click="$emit('link-click', 'https://awsintwww.imaginecredit.com/')">here</a>.</p>

            <br>
            <p><a href="#" title="Imagine Cardholder Agreement" @click="$emit('link-click', 'https://awsintwww.imaginecredit.com/legal/#cardholder-agreement')">Imagine Cardholder Agreement</a></p>
            <br>
            <p><a href="#" title="Imagine Privacy Policy" @click="$emit('link-click', 'https://awsintwww.imaginecredit.com/legal/#website-privacy-security-policy')">Imagine Privacy Policy</a></p>
            <br>
            <p><a href="#" title="Imagine Privacy Notice" @click="$emit('link-click', 'https://awsintwww.imaginecredit.com/legal/#privacy-notice')">Imagine Privacy Notice</a></p>
            <br>

            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
