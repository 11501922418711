<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-pratt.png" class="is-rounded" alt="Spencer Pratt">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Spencer Pratt</h2>
            <h3 class="people-title">SVP Chief Technology Officer</h3>
            <p class="people-bio">
Spencer oversees technology and development. He has a background in software engineering, technology and payments having worked as a processor, issuer, and a network and program manager. Prior to joining the Bank in 2018, Spencer worked at Cardplatforms, Galileo and American Express. Spencer received a B.S. of Computer Science and M.B.A from the University of
    Utah.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
