<template>
<div>

    <div class="box partner-box" @click="partner_modal = true">
        <img src="https://assets.webbank.com/partners/lane-health.svg" alt="Lane Health Logo">
    </div>

<div class="modal" :class="{'is-active': partner_modal}">
  <div class="modal-background"></div>
  <div class="modal-content">
      <div class="box has-text-left">
          <h2 class="box-title">Our Brand Partner</h2>
          <br>
          <div class="has-text-centered">
            <img src="https://assets.webbank.com/partners/lane-health.svg" alt="Lane Health Logo">
          </div>
            <br>
            <p>In partnership with Lane Health, WebBank is the lender of the Health Savings Account (“HSA”) Advance feature of the
                Lane Health “Card with a Heart”. Offered through participating employer health plans, the Lane Health HSA includes an
                Advance feature that works like a line of credit through the HSA that employees can use to pay for qualified medical
                expenditures when there aren't enough funds in their account. For more information about the Lane Health Card with a
                Heart click <a href="#" title="More information at Lane Health" @click="$emit('link-click', 'https://lanehealth.com/')">here</a>.</p>
            <br>
            <p><a href="#" title="Lane Health Cardholder Agreement" @click="$emit('link-click', 'https://bewell.lanehealth.com/files/Terms%20Conditions%20and%20Disclosures%20HSA%20Advances%2004.2022.pdf')">Lane Health Cardholder Agreement</a></p>
            <br>
            <p><a href="#" title="Lane Health Privacy Policy" @click="$emit('link-click', 'https://lanehealth.com/resources/#nav-documents')">Lane Health Privacy Policy and Privacy Notice</a></p>
            <br>
            <br>
            <button class="button is-primary is-light" @click="partner_modal = false">Close</button>

      </div>
  </div>
  <button class="modal-close is-large" aria-label="close" @click="partner_modal = false"></button>
</div>

</div>
</template>

<script>
export default {
    data: function () {
        return {
            partner_modal: false,
        }
    }
}
</script>
